import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Visibility } from '@mui/icons-material';
import { IconButton, Tooltip } from '@digitalreality/ui';
import { Storage } from 'aws-amplify';

import { showModal } from '../../Pages/ReduxStore/Actions/ModalActions';

const PDFActionViewer = (props) => {
    const [ s3URL, setS3URL ] = useState('');
    useEffect(() => {
        const getS3URL = async () => {
            const pdfURL = await Storage.get(props.value);
            console.log("********pdf",pdfURL);
            setS3URL(pdfURL);
        }

        getS3URL();
    }, [])
    return (
        <Tooltip title="View report">
            <IconButton onClick={ () => props.showModal({ value: true, pdfUrl: s3URL }) }>
                <Visibility className="text-black" />
            </IconButton>
        </Tooltip>
    );
};

export default connect(null, { showModal })(PDFActionViewer);
