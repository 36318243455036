import UserForm from "./UserForm";
import UsersList from "./UsersList";

const Users = () => {
    return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<UserForm />
			<UsersList />
		</div>
    );
};

export default Users;
