import React, { useEffect, useState } from 'react';
import Routers from '../../Routers';
import { Amplify, Auth } from 'aws-amplify';
import amplifyconfig from '../../../amplifyconfiguration.json';
import SignIn from '../../../pagesNew/SignIn';
import { connect } from 'react-redux';
import LandingPage from '../LandingPage/LandingPage';
import { switchCurrentLoginSection } from '../../ReduxStore/Actions/LoginPageActions';

Amplify.configure(amplifyconfig);

const Root = ({
	loggedUser,
	landingPage,
	switchCurrentLoginSection: switchCurrentLoginSectionAction,
}) => {
	const [authChecked, setAuthChecked] = useState(false);
	const [isAutheticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		const getAuthStatus = async () => {
			const queryParams = new URLSearchParams(window.location.search);
			const signup = queryParams?.get('signup');
			const email = queryParams?.get('email');
			try {
				await Auth.currentAuthenticatedUser();
				setIsAuthenticated(true);
			} catch (e) {
				if (signup) {
					switchCurrentLoginSectionAction({
						page: 'SIGNUP_FORM',
						email,
					});
				}
				setIsAuthenticated(false);
			} finally {
				setAuthChecked(true);
				if (signup) {
					const url = new URL(window.location);
					url.search = ''; // Clear query parameters
					window.history.replaceState({}, '', url);
				}
			}
		};
		getAuthStatus();
	}, [loggedUser, switchCurrentLoginSectionAction]);

	if (!landingPage) return <LandingPage />;

	if (!authChecked) return null;

	if (isAutheticated) return <Routers />;

	return <SignIn />;
};

const mapStateToProps = (state) => {
	return {
		loggedUser: state.loginedInUserData,
		landingPage: state.landingPage,
	};
};

export default connect(mapStateToProps, { switchCurrentLoginSection })(Root);
