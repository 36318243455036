import { CircularProgress } from '@mui/material';
import MaxWidthDialog from '../../../components/base/Dialog';

const CheckoutMain = (props) => {
	const {
		cancelButtonText,
		submitButtonText,
		onCancel,
		onSubmit,
		isLoading,
	} = props;

	const { value, item } = isLoading || {};

	return (
		<MaxWidthDialog
			open
			removePadding
			hideHeader
			hideFooter>
			<div className="flex flex-col bg-white overflow-hidden w-full items-center gap-2 text-black rounded-lg flex-1">
				<div
					style={{ borderRadius: '0 0 50% 50% / 0 0 80px 80px' }}
					className="shrink-0 w-full bg-gradient-to-r from-[#43c82c] via-[#e7e845] to-[#cbcc92] h-[120px] flex items-center justify-center">
					<span className="text-4xl font-bold">{props.title}</span>
				</div>
				<div className="flex flex-col items-center w-full p-6 gap-8">
					{props.children}
					<div className="flex gap-5 justify-end items-center w-full">
						{onCancel && (
							<button
								onClick={onCancel}
								className="flex justify-center items-center bg-[#E5E9EF] h-10 w-48 rounded-[20px]">
								<span className="uppercase text-sm font-normal text-[#888888]">
									{cancelButtonText}
								</span>
							</button>
						)}
						{onSubmit && (
							<button
								onClick={onSubmit}
								disabled={!!value}
								className={`flex justify-center items-center border-2 border-solid box-border h-10 w-48 border-[#888] rounded-[20px] ${!!value && item === 'purchase' ? 'opacity-60' : ''}`}>
								<span className="uppercase text-sm font-normal flex items-center gap-x-1">
									<span>{submitButtonText}</span>
									{!!value && item === 'purchase' && (
										<CircularProgress
											thickness={4}
											style={{
												color: 'inherit',
												marginLeft: '8px',
											}}
											size={20}
										/>
									)}
								</span>
							</button>
						)}
					</div>
				</div>
			</div>
		</MaxWidthDialog>
	);
};

export default CheckoutMain;
