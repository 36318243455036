import React, { useState } from 'react';
import { ThreeDot } from 'react-loading-indicators';

// Define the ImageLoader component
const ImageLoader = ({ src, alt }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	// Handle image load event
	const handleImageLoad = () => {
		setLoading(false);
	};

	// Handle image error event
	const handleImageError = () => {
		setLoading(false);
		setError(true);
	};

	return (
		<div
			style={{
				position: 'relative',
				width: '100%',
				height: '100%',
				backgroundColor: '#fff',
			}}>
			{loading && !error && (
				<div className="flex justify-center items-center mt-40 bg-[#fff]">
					<ThreeDot
						color={'#32cd32'}
						size="medium"
					/>
				</div>
			)}
			{error && (
				<div style={styles.error}>
					<span>Failed to load image</span>
				</div>
			)}
			<img
				src={src}
				alt={alt}
				style={{
					display: loading ? 'none' : 'block',
					width: '100%',
					height: '100%',
					objectFit: 'cover',
				}}
				onLoad={handleImageLoad}
				onError={handleImageError}
			/>
		</div>
	);
};

// Styles for the loader and error message
const styles = {
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		background: 'rgba(255, 255, 255, 0.8)',
		padding: '10px',
		borderRadius: '5px',
		textAlign: 'center',
	},
	error: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		background: 'rgba(255, 0, 0, 0.8)',
		color: 'white',
		padding: '10px',
		borderRadius: '5px',
		textAlign: 'center',
	},
};

export default ImageLoader;
