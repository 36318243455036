import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { useFormState } from '../../../components/hooks/useFormState';
import { formValidation } from '../../../utils/formValidation';
import FormModal from '../../../components/base/FormModal';
import { setClientAndRoles } from '../../ReduxStore/Actions/CommonActions';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const validateFirstName = (value) => {
	const specialCharPattern = /[^a-zA-Z0-9]/;
	if (specialCharPattern.test(value)) {
		return `First Name contain special characters ${value}`;
	}
};

const getEmailList = (emailString) => {
	if (!emailString) return [];
	return emailString.split(',').map((email) => email.trim().toLowerCase());
};

const validateEmail = (email) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

const validateEmails = (emails, isMultiUser) => {
	const emailList = getEmailList(emails);
	if (isMultiUser) {
		const invalidEmails = emailList.filter(
			(email) => !validateEmail(email)
		);
		if (invalidEmails.length) {
			return `Invalid emails found: ${invalidEmails.join(', ')}`;
		}
		const emailSet = new Set();
		const duplicateEmails = new Set();
		emailList.forEach((email) => {
			if (emailSet.has(email)) {
				duplicateEmails.add(email);
			} else {
				emailSet.add(email);
			}
		});

		if (duplicateEmails.size > 0) {
			return `Duplicate entries found: ${Array.from(duplicateEmails).join(', ')}`;
		}
		return '';
	} else {
		if (emailList.length > 1) return `Multiple emails are not allowed`;
		return validateEmail(emailList) ? '' : 'Invalid email';
	}
};

const InvitationForm = (props) => {
	const { listofinvitationClients, listofinvitationRoles, id } =
		props.loginedInUserData?.data?.getLoggedInUserData || {};
	const { selectedItem } = props.modalStatus || {};
	const {
		formData,
		error,
		setFormData,
		setError,
		reset,
		onChange,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	} = useFormState({}, selectedItem);

	const formFields = [
		{
			id: 'multiUser',
			label: 'Allow Multiple User Invitations',
			type: 'checkbox',
			skipValidation: true,
			onChange: useCallback(
				(e) =>
					onChange('multiUser', e.target.checked, [
						'firstName',
						'emailId',
					]),
				[onChange]
			),
		},
		{
			id: 'firstName',
			label: 'First Name',
			type: 'string',
			hide: !!formData?.multiUser?.value,
			customValidator: validateFirstName,
			onChange: useCallback(
				(e) => onChange('firstName', e.target.value),
				[onChange]
			),
		},
		{
			id: 'emailId',
			label: 'Email',
			type: 'string',
			customValidator: (emails) =>
				validateEmails(emails, formData?.multiUser?.value),
			multiline: formData?.multiUser?.value,
			helperText: formData?.multiUser?.value
				? 'Enter multiple emails, separated by commas'
				: '',
			placeholder: formData?.multiUser?.value
				? 'user1@example.com,user2@example.com'
				: '',
			onChange: useCallback(
				(e) => onChange('emailId', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clientName',
			label: 'Client Name',
			type: 'dropdown',
			items: listofinvitationClients,
			onChange: useCallback(
				(e) => onChange('clientName', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clientRole',
			label: 'Client Role',
			type: 'dropdown',
			items: listofinvitationRoles,
			onChange: useCallback(
				(e) => onChange('clientRole', e.target.value),
				[onChange]
			),
		},
	];

	useEffect(() => {
		const refreshUserObject = async () => {
			const { assignedRole, clientName } =
				props.loginedInUserData?.data?.getLoggedInUserData;
			try {
				const responseObject = await makeGraphQLRequest({
					query: queries.refreshUserData,
					variables: {
						userRole: assignedRole,
						clientName: clientName,
					},
				});
				const { listofinvitationClients, listofinvitationRoles } =
					responseObject?.data?.refreshUserData || {};
				props.setClientAndRoles({
					listofinvitationClients,
					listofinvitationRoles,
				});
			} catch (error) {}
		};
		if (props.modalStatus.value) {
			refreshUserObject();
		}
	}, [props.modalStatus.value]);

	const handleCloseModal = () => {
		reset();
		props.showModal({ value: false });
	};

	const handleInvitation = async () => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;

		let emailList;
		if (formData.multiUser) {
			emailList = reducedData.emailId
				.split(',')
				.map((item) => item.trim().toLowerCase());
		} else {
			emailList = [
				`${reducedData.emailId.split(',')[0].trim().toLowerCase()}`,
			];
		}
		const { emailId, clientId } =
			props.loginedInUserData?.data?.getLoggedInUserData;
		setIsLoading(true);
		setError('');
		try {
			const responseObject = await makeGraphQLRequest({
				query: mutations.validateInviteEmails,
				variables: {
					emails: reducedData.multiUser
						? emailList
						: [reducedData.emailId.split(',')[0].trim()],
					clientID: reducedData.clientName,
					clientName: reducedData.clientName,
					invitationRole: reducedData.clientRole,
				},
			});
			const responseResult = responseObject.data.validateInviteEmails;

			if (responseResult) {
				for (let i = 0; i < emailList.length; i++) {
					const item = emailList[i];
					const clientObject = {
						emailId: item,
						firstName: reducedData?.firstName?.toLowerCase() || '',
						clientId: reducedData.clientName,
						clientName: reducedData.clientName,
						clientRole: reducedData.clientRole,
						invitedBy: id,
						invitationStatus: true,
						invitationCurrentStatus: 'New',
						invited_from_domain: window.location.origin,
						audit_client_msg: `${clientId}@@${emailId}`,
					};
					await makeGraphQLRequest({
						query: mutations.createInvitationTable,
						variables: { input: clientObject },
					});
				}
				setFormStatus(true);
			}
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading(false);
		}
	};

	if (!props.modalStatus.value) return null;

	return (
		<FormModal
			onCancel={handleCloseModal}
			onSubmit={handleInvitation}
			isLoading={isLoading}
			title="Invite Client"
			error={error}
			formStatus={formStatus}
			isEdit={!!selectedItem}
			formFields={formFields}
			formData={formData}
		/>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
	modalStatus: state.modalStatus,
});

export default connect(mapStateToProps, { showModal, setClientAndRoles })(
	InvitationForm
);
