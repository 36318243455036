import React, { useEffect, useState } from 'react';
import {
	MapContainer,
	TileLayer,
	GeoJSON,
	Marker,
	Popup,
	useMap,
	Rectangle,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { ThreeDot } from 'react-loading-indicators';
import L from 'leaflet';
import markerIcon from '../../../../src/Images/locationIcon.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	iconRetinaUrl: markerIcon,
	iconUrl: markerIcon,
	shadowUrl:
		'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// name: 'My POI', id: 'my-poi-id', lat: 8.713967, lon: -83.1836391,

const GeoJsonMap = ({ country, currentLocation = [8.713967, -83.1836391] }) => {
	const [mapData, setmapData] = useState({
		isloading: true,
		geoData: null,
		bounds: null,
		center: [0, 0],
		markers: [],
		zoomlevel: 2,
	});

	const color_config = {
		loadingIndicatorColor: '#32cd32',
		mapBorderColor: '#FFFF00',
		mapbackgroundColor: '#FFFF00', // This is actually not visible so - no need to set
		mapFillColor: '#FFFF00',
		mapfillOpacity: 1,
		showMap: false,
		showboundingBox: false,
		boundingBoxBorderColor: '#FFFF00',
		boundingBoxFillColor: '#FFFF00', // This is actually not visible so - no need to set
		boundingBoxfillOpacity: 0.1,
	};

	const getBoundingBoxSize = (bounds) => {
		const [[lat1, lng1], [lat2, lng2]] = bounds;

		// Calculate width and height of the bounding box
		const width = Math.abs(lng2 - lng1);
		const height = Math.abs(lat2 - lat1);

		// Define your thresholds for small, medium, and large bounding boxes
		const smallThreshold = 5.0; // Tighter threshold for small bounding box
		const mediumThreshold = 10.0; // Tighter threshold for medium bounding box

		let sizeCategory;

		if (width < smallThreshold || height < smallThreshold) {
			sizeCategory = 'small';
		} else if (width > mediumThreshold || height > mediumThreshold) {
			sizeCategory = 'medium';
		} else {
			sizeCategory = 'large';
		}

		return sizeCategory; // Return the size category
	};
	const updateMapState = (updates) => {
		setmapData((prevState) => ({
			...prevState,
			...updates,
		}));
	};

	useEffect(() => {
		const fetchGeoJSON = async () => {
			try {
				const response = await fetch(
					`https://nominatim.openstreetmap.org/search?country=${country}&format=json&polygon_geojson=1`
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const data = await response.json();
				const geojson = data[0]?.geojson;
				const boundingBox = data[0]?.boundingbox;
				let mapObject = {};
				mapObject['geoData'] = geojson;
				console.log('geo json>>>>>>>>>>', geojson);
				mapObject['isloading'] = false;
				if (boundingBox) {
					const bounds = [
						[boundingBox[0], boundingBox[2]],
						[boundingBox[1], boundingBox[3]],
					];
					mapObject['bounds'] = bounds;

					const center = [
						(parseFloat(boundingBox[0]) +
							parseFloat(boundingBox[1])) /
							2,
						(parseFloat(boundingBox[2]) +
							parseFloat(boundingBox[3])) /
							2,
					];
					mapObject['center'] = center;
					mapObject['markers'] = [{ position: center }];
					const boundarySize = getBoundingBoxSize(bounds);
					if (boundarySize == 'small') {
						mapObject['zoomlevel'] = 5.3;
					} else if (boundarySize == 'medium') {
						mapObject['zoomlevel'] = 3;
					} else {
						mapObject['zoomlevel'] = 2;
					}
				}
				updateMapState(mapObject);
			} catch (error) {
				console.error('Error fetching GeoJSON:', error);
			}
		};
		fetchGeoJSON();
	}, [country]);

	const {
		loadingIndicatorColor,
		mapBorderColor,
		mapbackgroundColor,
		mapFillColor,
		mapfillOpacity,
		showMap,
		showboundingBox,
		boundingBoxBorderColor,
		boundingBoxFillColor,
		boundingBoxfillOpacity,
	} = color_config;
	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			{mapData.isloading && (
				<div>
					<ThreeDot
						color={loadingIndicatorColor}
						size="medium"
					/>
				</div>
			)}
			{!mapData.isloading && (
				<MapContainer
					center={mapData.center}
					attributionControl={false}
					zoom={mapData.zoomlevel}
					style={{
						display: 'flex',
						width: '100%',
						height: '100%',
						backgroundColor: '#fff',
					}}
					zoomControl={false}
					dragging={false}
					touchZoom={false}
					scrollWheelZoom={false}
					doubleClickZoom={false}
					boxZoom={false}
					keyboard={false}>
					{showMap && (
						<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					)}
					{mapData.markers.map((marker, index) => (
						<Marker
							key={index}
							position={marker.position}
						/>
					))}
					<UpdateMapCenter
						center={mapData.center}
						zoom={mapData.zoomlevel}
					/>
					{showboundingBox && (
						<Rectangle
							bounds={mapData.bounds}
							pathOptions={{
								color: boundingBoxBorderColor,
								weight: 2,
								fillColor: boundingBoxFillColor,
								fillOpacity: boundingBoxfillOpacity,
							}}
						/>
					)}

					{mapData.geoData && (
						<GeoJSON
							data={mapData.geoData}
							style={{
								color: mapBorderColor,
								opacity: 1,
								weight: 2,
								backgroundColor: mapbackgroundColor,
								fillColor: mapFillColor,
								fillOpacity: mapfillOpacity,
							}}
							zoomControl={false}
							interactive={false}
						/>
					)}
				</MapContainer>
			)}
		</div>
	);
};

const UpdateMapCenter = ({ center, zoom }) => {
	const map = useMap();
	useEffect(() => {
		if (map) {
			map.setView(center, zoom);
		}
	}, [center, zoom, map]);
	return null;
};

export default GeoJsonMap;
