import { IconButton } from "@digitalreality/ui";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import ReplayIcon from '@mui/icons-material/Replay';
import { Slider } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const formatDuration = (value) => {
    const minute = Math.floor(value/60);
    const seconds = Math.round(value % 60) ;
    return `${minute}:${seconds}`;
}

const MyVRVideoPlayer = ({ duration, onPlay, onStop }) => {
    const [ isPlaying, setIsPlaying ] = useState(false);
    const [ position, setPosition ] = useState(0);
    const intervalRef = useRef(null);

    const handlePlayClick = () => {
        if (isPlaying) {
            onStop();
        }
        else {
            onPlay();
        }
        console.log(position);
        if (Math.ceil(position) === duration && !isPlaying) {
            setPosition(0);
        }
        setIsPlaying((currentPlay) => !currentPlay);
    };

    useEffect(() => {
        if (isPlaying) {
            intervalRef.current = setInterval(() => {
                setPosition((prevPosition) => {
                    const nextPosition = prevPosition + 0.1;
                    if (nextPosition <= duration) {
                        return nextPosition;
                    }
                    setIsPlaying(false);
                    onStop();
                    return 0;
                });
            }, 100);
        } else {
            clearInterval(intervalRef.current);
        }

        return () => clearInterval(intervalRef.current);
    }, [ isPlaying, duration ]);

    const PlayIcon = isPlaying ? PauseOutlinedIcon : PlayArrowIcon;

    return (
        <div className="bg-gray-200 w-full h-[140px] flex flex-col justify-center items-center gap-2">
            <div className="flex flex-col items-center w-4/5 justify-center">
                <Slider
                    step={ 1 }
                    max={ duration }
                    value={ position }
                    onChange={ (_, value) => setPosition(value) }
                    sx={ { color: '#000000' } } />
                <div className="flex justify-between items-center text-black w-full">
                    <span>{ formatDuration(position) }</span>
                    <span>{ formatDuration(duration - position) }</span>
                </div>
            </div>
            <div className="flex justify-between w-5/6 pb-2">
                <IconButton>
                    <FavoriteBorderOutlinedIcon sx={ { color: '#000000', fontSize: '28px' } } />
                </IconButton>
                <div className="flex flex-row gap-2">
                    <IconButton onClick={ () => setPosition(prevPosition => prevPosition - 10 < 0 ? 0 : prevPosition - 10) }>
                        <FastRewindRounded sx={ { color: '#000000', fontSize: '36px' } } />
                    </IconButton>
                    <IconButton onClick={ handlePlayClick } sx={ { border: '1px solid #000000', borderRadius: '50%' } } className="border border-black border-solid">
                        <PlayIcon sx={ { color: '#000000', fontSize: '40px' } } />
                    </IconButton>
                    <IconButton onClick={ () => setPosition(prevPosition => prevPosition + 10 > duration ? duration : prevPosition + 10) }>
                        <FastForwardRounded sx={ { color: '#000000', fontSize: '36px' } } />
                    </IconButton>
                </div>
                <IconButton
                    onClick={ () => {
                        setPosition(0);
                        setIsPlaying(true);
                        onPlay();
                    } }
                    disabled = { Math.ceil(position) !== duration }>
                    <ReplayIcon
                        sx={ {
                            color: Math.ceil(position) !== duration ? '#999999' : '#000000',
                            fontSize: '28px',
                        } } />
                </IconButton>
            </div>
        </div>
    );
}

export default MyVRVideoPlayer;
