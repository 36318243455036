import { useMemo } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, FileUploadOutlined } from '@mui/icons-material';

import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import Grid from '../../CommonComponents/AGGrid/Grid';
import { useGridState } from '../../../components/hooks/useGridState';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as queries from '../../../graphql/queries';
import PDFActionRenderer from './PDFActionRenderer';
import PDFActionViewer from '../../../components/grid/PDFActionViewer';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const ReportList = (props) => {
	const { assignedRole, clientId } =
		props.loginedInUserData?.data?.getLoggedInUserData;
	const getPDFRecords = async () => {
		try {
			let filter_variables = {};
			if (assignedRole === 'super-admin') {
				filter_variables = {};
			} else if (assignedRole === 'client-admin') {
				filter_variables = {
					filter: { clientID: { eq: clientId } },
				};
			} else {
				filter_variables = { filter: { clientID: { eq: 'NA' } } };
			}

			const responseObject = await makeGraphQLRequest({
				query: queries.listPDFReports,
				variables: filter_variables,
			});
			const items_list =
				responseObject?.data?.listPDFReports?.items || [];
			onRecords(items_list);
		} catch (error) {
			onError(error);
		}
	};

	const {
		error,
		rowData,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		fetchRecords: getPDFRecords,
		onCreateTable: 'onCreatePDFReports',
		onUpdateTable: 'onUpdatePDFReports',
	});

	const actions = [
		{
			id: 'upload_report',
			namedSizeScaleame: 'Upload Report',
			onClick: () => props.showModal({ value: true }),
			icon: <FileUploadOutlined />,
			primary: true,
		},
		{
			id: 'edit_report',
			name: 'Edit Report',
			onClick: () =>
				props.showModal({ value: true, selectedItem: selectedRows[0] }),
			icon: <EditOutlined />,
			disabled: selectedRows.length !== 1,
		},
	];

	const colDefs = useMemo(
		() => [
			{
				field: 'clientID',
				headerName: 'Client Name',
				width: 150,
				checkboxSelection: true,
				headerCheckboxSelection: true,
				pinned: 'left',
				filter: true,
			},
			{
				field: 'pdfName',
				headerName: 'PDF Name',
				flex: 1,
			},
			{
				field: 'pdfStorageURL',
				headerName: 'PDF File',
				cellRenderer: PDFActionViewer,
				width: 150,
			},
			{
				field: 'status',
				hide: assignedRole !== 'super-admin',
				cellRenderer: PDFActionRenderer,
				cellRendererParams: {
					onError,
				},
				width: 100,
			},
			{
				field: 'createdAt',
				width: 300,
			},
			{
				field: 'createdBy',
				flex: 1,
			},
		],
		[assignedRole, onError]
	);

	return (
		<GridLayout
			error={error}
			actions={assignedRole === 'super-admin' ? actions : []}
			title="Reports">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				suppressRowClickSelection
				onGridReady={onGridReady}
				onSelectionChanged={onSelectionChanged}
				rowSelection="single"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(ReportList);
