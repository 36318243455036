import { useMemo } from 'react';
import { connect } from 'react-redux';
import { PersonAddAlt1Outlined } from '@mui/icons-material';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as queries from '../../../graphql/queries';
import Grid from '../../CommonComponents/AGGrid/Grid';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import { useGridState } from '../../../components/hooks/useGridState';
import InvitationActionRenderer from './InvitationActionRenderer';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const EmailFormatter = ({ value }) => {
	if (value?.includes('@@@@')) {
		return value.split('@@@@')[1].trim();
	}
	return value;
};

const InvitationsList = (props) => {
	const getUserRecords = async () => {
		try {
			const { assignedRole, clientId } =
				props.loginedInUserData?.data?.getLoggedInUserData;
			let filter_variables = {};
			if (assignedRole === 'super-admin') {
				filter_variables = {};
			} else if (assignedRole === 'client-admin') {
				filter_variables = {
					filter: { clientName: { eq: clientId } },
				};
			} else {
				filter_variables = { filter: { clientName: { eq: 'NA' } } };
			}

			const responseObject = await makeGraphQLRequest({
				query: queries.listInvitationTables,
				variables: filter_variables,
			});
			const items_list =
				responseObject?.data?.listInvitationTables?.items || [];
			onRecords(items_list);
		} catch (error) {
			onError(error);
		}
	};

	const {
		error,
		rowData,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreateInvitationTable',
		onUpdateTable: 'onUpdateInvitationTable',
		fetchRecords: getUserRecords,
	});

	const actions = [
		{
			id: 'invite',
			name: 'Invite',
			onClick: () => props.showModal({ value: true }),
			icon: <PersonAddAlt1Outlined />,
			primary: true,
		},
	];

	const colDefs = useMemo(
		() => [
			{
				field: 'clientName',
				pinned: 'left',
				headerName: 'Client',
			},
			{
				field: 'emailId',
				headerName: 'User Email',
				width: 300,
				cellRenderer: EmailFormatter,
			},
			{
				field: 'clientRole',
				headerName: 'Role',
			},
			{
				field: 'invitationCurrentStatus',
				headerName: 'Invitation Status',
				cellRenderer: InvitationActionRenderer,
				cellRendererParams: {
					onError,
				},
			},
			{
				field: 'invitedBy',
				flex: 1,
			},
			{
				field: 'createdAt',
			},
			{
				field: 'updatedAt',
			},
		],
		[]
	);

	return (
		<GridLayout
			error={error}
			actions={actions}
			title="Invitation">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				onGridReady={onGridReady}
				suppressRowClickSelection
				onSelectionChanged={onSelectionChanged}
				rowSelection="none"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(InvitationsList);
