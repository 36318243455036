import { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';

import * as mutations from '../../../graphql/mutations';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import { useFormState } from '../../../components/hooks/useFormState';
import { formValidation } from '../../../utils/formValidation';
import FormModal from '../../../components/base/FormModal';
import * as queries from '../../../graphql/queries';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

function MediaForm(props) {
	const { selectedItem } = props.modalStatus || {};
	const [forestData, setForestData] = useState({
		forestMainNames: [],
		forestMainIds: [],
		siteNames: [],
		siteIds: [],
	});

	useEffect(() => {
		const refreshUserObject = async () => {
			const { assignedRole, clientName } =
				props.loginedInUserData?.data?.getLoggedInUserData;
			try {
				const responseObject = await makeGraphQLRequest({
					query: queries.refreshUserData,
					variables: {
						userRole: assignedRole,
						clientName: clientName,
					},
				});
				const {
					listofForestNameMain,
					listofForestIDsMain,
					listofForestName,
					listofForestIDs,
				} = responseObject?.data?.refreshUserData || {};

				setForestData({
					forestMainNames: listofForestNameMain,
					forestMainIds: listofForestIDsMain,
					siteNames: listofForestName,
					siteIds: listofForestIDs,
				});
			} catch (error) {}
		};
		if (props.modalStatus.value) {
			refreshUserObject();
		}
	}, [props.modalStatus.value]);

	const {
		formData,
		error,
		setFormData,
		setError,
		reset,
		onChange,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	} = useFormState({}, selectedItem);

	const formFields = [
		{
			id: 'ForestName',
			label: 'Forest Name',
			type: 'dropdown',
			items: forestData.forestMainNames,
			onChange: useCallback(
				(e) => onChange('ForestName', e.target.value),
				[onChange]
			),
		},
		{
			id: 'SiteName',
			label: 'Site Name',
			type: 'dropdown',
			items: forestData.siteNames,
			onChange: useCallback(
				(e) => onChange('SiteName', e.target.value),
				[onChange]
			),
		},
		{
			id: 'MediaType',
			label: 'Captured From',
			type: 'dropdown',
			items: ['General', 'Drone', 'Traps', 'Video360'],
			onChange: useCallback(
				(e) => onChange('MediaType', e.target.value),
				[onChange]
			),
		},
		{
			id: 'MediaPath',
			label: 'Media File',
			type: 'file',
			skipValidation: true,
			mimeType:
				'image/jpg,image/jpeg,image/png,video/mp4,video/avi,video/mov',
			onChange: useCallback(
				(e) => onChange('MediaPath', e.target.files[0]),
				[onChange]
			),
		},
	];

	const handleSubmit = async (isEdit) => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;
		setIsLoading(true);
		setError('');
		const { emailId, clientId } =
			props.loginedInUserData?.data?.getLoggedInUserData;
		try {
			const file = formData.MediaPath?.value;
			const data = {
				...reducedData,
			};

			if (!isEdit) {
				data.createdBy = emailId;
				let index = forestData.forestMainNames.indexOf(data.ForestName);
				let forestID = '';
				if (index !== -1) {
					forestID = forestData.forestMainIds[index];
				}

				data.ForestID = forestID;

				let index_site = forestData.siteNames.indexOf(data.SiteName);
				let siteID = '';
				if (index_site !== -1) {
					siteID = forestData.siteIds[index];
				}
				data.SiteID = siteID;
			} else {
				data.id = newState.id.value;
			}

			let path_of_save = '';

			let capture_from = data.CaptureFrom;

			if (file?.name) {
				const forest_Name = isEdit
					? selectedItem?.ForestName
					: data.ForestName;
				path_of_save = `${forest_Name}/${capture_from}/${file.name}`;
				data.MediaPath = path_of_save;
				data.MediaType = file.type;
			}

			// This is only field in the front end, we can;t insert that to the backend because we dont have that field in the database table
			delete data.CaptureFrom;

			data.audit_client_msg = `${clientId}@@${emailId}`;
			const result = await makeGraphQLRequest({
				query: isEdit ? mutations.updateMedia : mutations.createMedia,
				variables: { input: data },
			});

			if (result && data?.MediaPath) {
				const myurl = await Storage.put(path_of_save, file, {
					contentType: file.type,
				});
			}

			setFormStatus(true);
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCloseModal = () => {
		reset();
		props.showModal({ value: false });
	};

	if (!props.modalStatus.value) return null;

	return (
		<FormModal
			onCancel={handleCloseModal}
			onSubmit={() => handleSubmit(!!selectedItem)}
			isLoading={isLoading}
			title={`${selectedItem ? 'Edit' : 'Add'} Media`}
			error={error}
			formStatus={formStatus}
			formFields={formFields}
			formData={formData}
			hideFooter={false}
			hideFields={false}
			isEdit={!!selectedItem}></FormModal>
	);
}

const mapStateToProps = (state) => ({
	modalStatus: state.modalStatus,
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(MediaForm);
