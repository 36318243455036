import { useState } from 'react';
import {
	CameraAltOutlined,
	InfoOutlined,
	TravelExplore,
} from '@mui/icons-material';

import GreenCubeMiniLogo from '../../../Images/GreenCubeMiniLogo.png';
import { formatNumber } from '../../../utils/utils';

const SponsorForestSite = (props) => {
	const { selectedForest, callbackHandler, selectedPolygon } = props;
	const [selectedItem, setSelectedItem] = useState(null);

	const siteOptions = [
		{
			id: 'Explore',
			onClick: () => callbackHandler('showExperience'),
			icon: <TravelExplore style={{ fontSize: '16px' }} />,
		},
		{
			id: 'Sponsor',
			onClick: () => callbackHandler('showSponsor'),
			icon: <CameraAltOutlined style={{ fontSize: '14px' }} />,
		},
		{
			id: 'Baseline Report',
			onClick: () => callbackHandler('showForestBaseline'),
			icon: <InfoOutlined style={{ fontSize: '14px' }} />,
		},
	];

	const miniActions = [
		{
			id: 'camera 1',
			onClick: () =>
				callbackHandler('showForestMedia', { filterFolder: 'Drone' }),

			icon: `
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 51" fill="none">
                    <circle cx="25" cy="26" r="25" fill="#9BE938"/>
                    <path d="M28.2432 27.2223C28.2432 29.1378 26.685 30.696 24.7695 30.696C22.854 30.696 21.2964 29.1378 21.2964 27.2223C21.2964 25.3068 22.854 23.7486 24.7695 23.7486C26.685 23.7486 28.2432 25.3074 28.2432 27.2223ZM35.539 22.2898V32.156C35.539 33.4713 34.4726 34.5377 33.1573 34.5377H16.3817C15.0664 34.5377 14 33.4713 14 32.156V22.2898C14 20.9745 15.0664 19.9081 16.3817 19.9081H19.3112V19.084C19.3112 17.933 20.2437 17 21.3952 17H28.1438C29.2953 17 30.2278 17.933 30.2278 19.084V19.9075H33.1573C34.4726 19.9081 35.539 20.9745 35.539 22.2898ZM30.0295 27.2223C30.0295 24.322 27.6698 21.9623 24.7695 21.9623C21.8698 21.9623 19.5101 24.322 19.5101 27.2223C19.5101 30.1226 21.8698 32.4823 24.7695 32.4823C27.6698 32.4823 30.0295 30.1226 30.0295 27.2223Z" fill="white"/>
                    <rect x="22" y="5" width="6" height="10" rx="1" fill="white"/>
                    <rect x="23" y="36" width="4" height="10" rx="1" fill="white"/>
                </svg>
            `,
		},
		{
			id: 'camera 2',
			onClick: () =>
				callbackHandler('showForestMedia', { filterFolder: 'Traps' }),
			icon: `
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50" fill="none">
                    <g clip-path="url(#clip0_4972_3550)">
                        <circle cx="25" cy="25" r="25" fill="#9BE938"/>
                        <path d="M24.4995 32.2016C25.0234 32.2016 25.4481 31.7768 25.4481 31.2529C25.4481 30.7289 25.0234 30.3042 24.4995 30.3042C23.9755 30.3042 23.5508 30.7289 23.5508 31.2529C23.5508 31.7768 23.9755 32.2016 24.4995 32.2016Z" fill="white"/>
                        <path d="M37 16.4648V15H30.2757V16.4648H32.9054V18.8121H31.6742V20.0432H27.4959L26.1811 17.4135H22.8189L21.504 20.0432H17.3257V18.8121H16.0946V16.4648H18.7243V15H12V16.4648H14.6297V18.8121H13.3986V20.7756C13.3986 21.8601 14.2777 22.7392 15.3622 22.7392C16.1876 22.7392 16.8937 22.2296 17.184 21.5081H19.823L20.7716 23.4054H28.2284L29.177 21.5081H31.816C32.1063 22.2296 32.8124 22.7392 33.6378 22.7392C34.7223 22.7392 35.6014 21.8601 35.6014 20.7756V18.8121H34.3702V16.4648H37Z" fill="white"/>
                        <path d="M28.7037 27.0502H26.3818V24.8701H22.8199V27.0502H20.2983C19.3699 27.0502 18.6172 27.8029 18.6172 28.7313V33.7746C18.6172 34.703 19.3698 35.4557 20.2983 35.4557H28.7037C29.6321 35.4557 30.3848 34.703 30.3848 33.7746V28.7313C30.3848 27.8029 29.6321 27.0502 28.7037 27.0502ZM24.501 33.6665C23.1702 33.6665 22.0875 32.5837 22.0875 31.2529C22.0875 29.9221 23.1702 28.8394 24.501 28.8394C25.8318 28.8394 26.9145 29.9221 26.9145 31.2529C26.9145 32.5837 25.8318 33.6665 24.501 33.6665Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_4972_3550">
                        <rect width="50" height="50" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            `,
		},
		{
			id: 'camera 3',
			onClick: () =>
				callbackHandler('showForestMedia', {
					filterFolder: 'Video360',
				}),
			icon: `
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50" fill="none">
                    <g clip-path="url(#clip0_4972_3538)">
                        <circle cx="25" cy="25" r="25" fill="#9BE938"/>
                        <path d="M28.2432 25.2223C28.2432 27.1378 26.685 28.696 24.7695 28.696C22.854 28.696 21.2964 27.1378 21.2964 25.2223C21.2964 23.3068 22.854 21.7486 24.7695 21.7486C26.685 21.7486 28.2432 23.3074 28.2432 25.2223ZM35.539 20.2898V30.156C35.539 31.4713 34.4726 32.5377 33.1573 32.5377H16.3817C15.0664 32.5377 14 31.4713 14 30.156V20.2898C14 18.9745 15.0664 17.9081 16.3817 17.9081H19.3112V17.084C19.3112 15.933 20.2437 15 21.3952 15H28.1438C29.2953 15 30.2278 15.933 30.2278 17.084V17.9075H33.1573C34.4726 17.9081 35.539 18.9745 35.539 20.2898ZM30.0295 25.2223C30.0295 22.322 27.6698 19.9623 24.7695 19.9623C21.8698 19.9623 19.5101 22.322 19.5101 25.2223C19.5101 28.1226 21.8698 30.4823 24.7695 30.4823C27.6698 30.4823 30.0295 28.1226 30.0295 25.2223Z" fill="white"/>
                        <path d="M45.8149 19.7461C45.248 18.117 44.423 16.5967 43.3627 15.2272C43.1646 14.9714 42.7966 14.9245 42.5407 15.1226C42.2849 15.3208 42.238 15.6888 42.4361 15.9447C43.4186 17.2136 44.183 18.6222 44.7081 20.1313C45.2514 21.6924 45.5268 23.3305 45.5268 25C45.5268 26.6695 45.2514 28.3075 44.7081 29.8688C44.183 31.3779 43.4186 32.7864 42.4361 34.0553C42.238 34.3112 42.2848 34.6793 42.5407 34.8774C42.7945 35.0744 43.1636 35.03 43.3627 34.7728C44.423 33.4034 45.248 31.883 45.8149 30.2539C46.4013 28.5686 46.6987 26.8009 46.6987 25C46.6987 23.1991 46.4013 21.4315 45.8149 19.7461Z" fill="white"/>
                        <path d="M41.2065 17.5705C41.0083 17.3147 40.6403 17.2679 40.3844 17.466C40.1286 17.6642 40.0818 18.0322 40.2799 18.2881C41.7787 20.2233 42.5709 22.5441 42.5709 24.9994C42.5709 27.4547 41.7787 29.7755 40.2799 31.7107C40.0818 31.9666 40.1286 32.3346 40.3844 32.5328C40.6382 32.7298 41.0073 32.6855 41.2065 32.4283C42.8657 30.2858 43.7428 27.7169 43.7428 24.9994C43.7428 22.2818 42.8657 19.713 41.2065 17.5705Z" fill="white"/>
                        <path d="M38.2269 19.81C37.9712 20.0083 37.9246 20.3764 38.1229 20.6321C39.0984 21.8902 39.614 23.4003 39.614 24.9992C39.614 26.5981 39.0984 28.1082 38.1229 29.3664C37.9246 29.6221 37.9712 29.9902 38.2269 30.1884C38.4806 30.3856 38.8497 30.3415 39.049 30.0844C40.1853 28.6189 40.7859 26.8605 40.7859 24.9992C40.7859 23.1379 40.1853 21.3794 39.049 19.9139C38.8507 19.6583 38.4827 19.6117 38.2269 19.81Z" fill="white"/>
                        <path d="M3.88434 30.2539C4.45122 31.883 5.27626 33.4033 6.33649 34.7728C6.53457 35.0286 6.90258 35.0755 7.15848 34.8774C7.41434 34.6792 7.46118 34.3112 7.26309 34.0553C6.28063 32.7864 5.51622 31.3778 4.9911 29.8687C4.44786 28.3076 4.17243 26.6695 4.17243 25C4.17243 23.3305 4.44786 21.6925 4.9911 20.1312C5.51622 18.6221 6.28063 17.2136 7.26309 15.9447C7.46118 15.6888 7.41438 15.3207 7.15848 15.1226C6.90469 14.9256 6.53559 14.97 6.33649 15.2272C5.27622 16.5966 4.45122 18.117 3.88434 19.7461C3.2979 21.4314 3.00055 23.1991 3.00055 25C3.00055 26.8009 3.2979 28.5685 3.88434 30.2539Z" fill="white"/>
                        <path d="M8.49277 32.4295C8.69093 32.6853 9.05894 32.7321 9.3148 32.534C9.57066 32.3358 9.61742 31.9678 9.41929 31.7119C7.9205 29.7767 7.12832 27.4559 7.12832 25.0006C7.12832 22.5453 7.9205 20.2245 9.41929 18.2893C9.61742 18.0334 9.57066 17.6654 9.3148 17.4672C9.06105 17.2702 8.69195 17.3145 8.49277 17.5717C6.83347 19.7142 5.95644 22.2831 5.95644 25.0006C5.95644 27.7182 6.83351 30.287 8.49277 32.4295Z" fill="white"/>
                        <path d="M11.4723 30.19C11.728 29.9917 11.7746 29.6236 11.5763 29.3679C10.6008 28.1098 10.0852 26.5997 10.0852 25.0008C10.0852 23.4019 10.6008 21.8918 11.5763 20.6336C11.7746 20.3779 11.728 20.0098 11.4723 19.8116C11.2187 19.6144 10.8495 19.6585 10.6502 19.9156C9.51397 21.3811 8.91334 23.1395 8.91334 25.0008C8.91334 26.8621 9.51393 28.6206 10.6502 30.0861C10.8485 30.3417 11.2165 30.3883 11.4723 30.19Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_4972_3538">
                        <rect width="50" height="50" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            `,
		},
	];

	return (
		<div className="flex flex-col items-center flex-1">
			<div className="flex flex-col justify-center items-center w-full gap-2">
				<span className="text-4xl text-center font-light">
					{selectedPolygon?.polygon_name}
				</span>
				<div className="flex items-center justify-center gap-2">
					<img
						src={GreenCubeMiniLogo}
						alt=""
						className="w-5 h-5 object-contain"
					/>
					<span className="text-xl font-medium">
						{formatNumber(selectedPolygon?.NoOfCubes)}
					</span>
				</div>
				<div className="flex items-center gap-12 w-full justify-center mt-3">
					{miniActions.map((action) => (
						<span
							onClick={action.onClick}
							key={action.id}
							dangerouslySetInnerHTML={{ __html: action.icon }}
							className="cursor-pointer hover:opacity-75"
						/>
					))}
				</div>
			</div>
			<div className="flex flex-col items-center w-full py-8">
				{siteOptions.map((option) => {
					return (
						<span
							key={option.id}
							className={`w-full hover:bg-[#9aeb2f] hover:opacity-70 border-b-2 hover:text-black p-[10px] cursor-pointer flex gap-4 items-center rounded ${
								selectedItem === option.id ? 'bg-[#9aeb2f]' : ''
							}`}
							onClick={() => {
								setSelectedItem(option.id);
								option.onClick();
							}}>
							{option.id}
						</span>
					);
				})}
			</div>
		</div>
	);
};

export default SponsorForestSite;
