import { connect } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import { useFormState } from '../../../components/hooks/useFormState';
import FormModal from '../../../components/base/FormModal';
import { formValidation } from '../../../utils/formValidation';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { setClientAndRoles } from '../../ReduxStore/Actions/CommonActions';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const UserForm = (props) => {
	const { selectedItem } = props.modalStatus || {};
	const { listofinvitationRoles, id } =
		props.loginedInUserData?.data?.getLoggedInUserData || {};
	const {
		formData,
		error,
		setFormData,
		setError,
		reset,
		onChange,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	} = useFormState({}, selectedItem);

	useEffect(() => {
		const refreshUserObject = async () => {
			const { assignedRole, clientName } =
				props.loginedInUserData?.data?.getLoggedInUserData;
			try {
				const responseObject = await makeGraphQLRequest({
					query: queries.refreshUserData,
					variables: {
						userRole: assignedRole,
						clientName: clientName,
					},
				});
				const { listofinvitationClients, listofinvitationRoles } =
					responseObject?.data?.refreshUserData || {};
				props.setClientAndRoles({
					listofinvitationClients,
					listofinvitationRoles,
				});
			} catch (error) {}
		};
		if (props.modalStatus.value) {
			refreshUserObject();
		}
	}, [props.modalStatus.value]);

	const formFields = [
		{
			id: 'emailId',
			label: 'Email ID',
			type: 'string',
			skipValidation: true,
			editable: false,
			onChange: useCallback(
				(e) => onChange('emailId', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clientName',
			label: 'Client Name',
			type: 'string',
			skipValidation: true,
			editable: false,
			onChange: useCallback(
				(e) => onChange('clientName', e.target.value),
				[onChange]
			),
		},
		{
			id: 'firstName',
			label: 'First Name',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('firstName', e.target.value),
				[onChange]
			),
		},
		{
			id: 'assignedRole',
			label: 'Role',
			type: 'dropdown',
			items: listofinvitationRoles,
			onChange: useCallback(
				(e) => onChange('assignedRole', e.target.value),
				[onChange]
			),
		},
		{
			id: 'profilePicURL',
			label: 'Profile Picture',
			type: 'file',
			skipValidation: true,
			onChange: useCallback(
				(e) => onChange('profilePicURL', e.target.files[0]),
				[onChange]
			),
		},
	];

	const handleSubmit = async () => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;
		setIsLoading(true);
		setError('');
		const { emailId, clientId } =
			props.loginedInUserData?.data?.getLoggedInUserData;
		try {
			let fileName = '';
			const file = formData.profilePicURL?.value;

			if (file) {
				fileName = `profile-pictures/${file.name}`;
			}
			const data = {
				...reducedData,
			};
			data.id = newState.id.value;

			if (file?.name) {
				data.profilePicURL = fileName;
			}

			data.audit_client_msg = `${clientId}@@${emailId}`;

			const result = await makeGraphQLRequest({
				query: mutations.updateUserData,
				variables: { input: data },
			});

			if (result && data?.profilePicURL) {
				await Storage.put(fileName, file, {
					contentType: file.type,
				});
			}

			setFormStatus(true);
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCloseModal = () => {
		reset();
		props.showModal({ value: false });
	};

	if (!props.modalStatus.value) return null;

	return (
		<FormModal
			onCancel={handleCloseModal}
			onSubmit={handleSubmit}
			isLoading={isLoading}
			title={'Edit User '}
			error={error}
			formStatus={formStatus}
			formFields={formFields}
			formData={formData}
			isEdit={!!selectedItem}></FormModal>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
	modalStatus: state.modalStatus,
});

export default connect(mapStateToProps, { showModal, setClientAndRoles })(
	UserForm
);
