const formStateGenerator = (record) => {
    const state = Object.keys(record || {}).reduce((acc, key) => {
        acc[key] = {
            initialValue: record[key],
            value: record[key],
            error: '',
        };
        return acc;
    }, {});

    return state;
}

export { formStateGenerator };
