import { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as mutations from '../../../graphql/mutations';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import { useFormState } from '../../../components/hooks/useFormState';
import { formValidation } from '../../../utils/formValidation';
import FormModal from '../../../components/base/FormModal';
import { AddLocationAltOutlined } from '@mui/icons-material';
import MapComponent from '../../../components/base/Map';
import * as queries from '../../../graphql/queries';
import { setClientAndRoles } from '../../ReduxStore/Actions/CommonActions';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const validateNoOfCubes = (value) => {
	if (value <= 0) {
		return `NoOfCubes should be greter than ${value}`;
	}
};

function ForestAreaForm(props) {
	const { selectedItem } = props.modalStatus || {};
	const [showMap, setShowMap] = useState(false);
	const [forestData, setForestData] = useState([]);
	const {
		formData,
		error,
		setFormData,
		setError,
		reset,
		onChange,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	} = useFormState({}, selectedItem);

	useEffect(() => {
		const refreshUserObject = async () => {
			const { assignedRole, clientName } =
				props.loginedInUserData?.data?.getLoggedInUserData;
			try {
				const responseObject = await makeGraphQLRequest({
					query: queries.refreshUserData,
					variables: {
						userRole: assignedRole,
						clientName: clientName,
					},
				});
				const { list_forest_data_consolidated } =
					responseObject?.data?.refreshUserData || {};
				setForestData(list_forest_data_consolidated);
			} catch (error) {}
		};
		if (props.modalStatus.value) {
			refreshUserObject();
		}
	}, [props.modalStatus.value]);

	const formFields = [
		{
			id: 'ForestName',
			label: 'Forest Name',
			type: 'dropdown',
			items: forestData.map((forest) => forest.ForestName),
			onChange: useCallback(
				(e) => onChange('ForestName', e.target.value),
				[onChange]
			),
			editable: false,
		},
		{
			id: 'polygon_name',
			label: 'Site Name',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('polygon_name', e.target.value),
				[onChange]
			),
			editable: false,
			endAdornments: !selectedItem
				? [
						{
							icon: <AddLocationAltOutlined />,
							title: 'Choose location',
							onClick: () => setShowMap(true),
						},
					]
				: [],
		},
		{
			id: 'lat',
			label: 'Latitude',
			type: 'number',
			onChange: useCallback(
				(e) => onChange('lat', e.target.value),
				[onChange]
			),
			editable: false,
			endAdornments: !selectedItem
				? [
						{
							icon: <AddLocationAltOutlined />,
							title: 'Choose location',
							onClick: () => setShowMap(true),
						},
					]
				: [],
		},
		{
			id: 'lon',
			label: 'Longitude',
			type: 'number',
			onChange: useCallback(
				(e) => onChange('lon', e.target.value),
				[onChange]
			),
			editable: false,
			endAdornments: !selectedItem
				? [
						{
							icon: <AddLocationAltOutlined />,
							title: 'Choose location',
							onClick: () => setShowMap(true),
						},
					]
				: [],
		},
		{
			id: 'NoOfCubes',
			label: 'Site Greencubes',
			type: 'string',
			customValidator: validateNoOfCubes,
			onChange: useCallback(
				(e) => onChange('NoOfCubes', e.target.value),
				[onChange]
			),
		},
		{
			id: 'CubePrice',
			label: 'Site Cube Price',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('CubePrice', e.target.value),
				[onChange]
			),
		},
		{
			id: 'area_WEBGL_cubified_file_path',
			label: 'Site Cubified File(.glb)',
			type: 'file',
			mimeType: 'model/gltf-binary',
			onChange: useCallback(
				(e) =>
					onChange(
						'area_WEBGL_cubified_file_path',
						e.target.files[0]
					),
				[onChange]
			),
		},
		{
			id: 'area_MYVR_scene_file_path',
			label: 'Site Experience Scene Path(.json)',
			type: 'file',
			mimeType: 'application/json',
			onChange: useCallback(
				(e) => onChange('area_MYVR_scene_file_path', e.target.files[0]),
				[onChange]
			),
		},
		{
			id: 'baseline_report_path',
			label: 'Baseline Report',
			type: 'file',
			mimeType: 'application/pdf',
			onChange: useCallback(
				(e) => onChange('baseline_report_path', e.target.files[0]),
				[onChange]
			),
		},
	];

	const handleSubmit = async (isEdit) => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		const selectedForest = forestData?.find(
			(item) => item?.ForestName === reducedData?.ForestName
		);
		setFormData(newState);
		if (hasError) return;
		setIsLoading(true);
		setError('');
		const { emailId, clientId } =
			props.loginedInUserData?.data?.getLoggedInUserData;
		try {
			let fileWebglName = '';
			let fileMyVRSceneName = '';
			const file_webGL = formData.area_WEBGL_cubified_file_path?.value;
			const file_myVR_scene = formData.area_MYVR_scene_file_path?.value;

			if (file_webGL && file_webGL?.name) {
				fileWebglName = `polygonGLBSceneData/${file_webGL.name}`;
			}
			if (file_myVR_scene && file_myVR_scene?.name) {
				fileMyVRSceneName = `polygonjsonData/${file_myVR_scene.name}`;
			}

			let baseline_report_path = '';
			const baseline_file = formData.baseline_report_path?.value;
			if (baseline_file) {
				baseline_report_path = `project_baseLine_reports/${baseline_file.name}`;
			}

			const data = {
				...reducedData,
			};

			if (!isEdit) {
				data.Status = true;
				data.createdBy = emailId;
				data.ForestID = selectedForest?.forestID;
			} else {
				data.id = newState.id.value;
			}

			if (file_webGL?.name) {
				data.area_WEBGL_cubified_file_path = fileWebglName;
			}
			if (file_myVR_scene?.name) {
				data.area_MYVR_scene_file_path = fileMyVRSceneName;
			}

			if (baseline_file?.name) {
				data.baseline_report_path = baseline_report_path;
			}
			data.audit_client_msg = `${clientId}@@${emailId}`;
			if (!isEdit) {
				const validation_result = await makeGraphQLRequest({
					query: queries.duplicateCheck,
					variables: {
						tableName: 'ForestsData',
						columns: ['polygon_name'],
						enteredvalues: [data.polygon_name],
					},
				});
				if (!validation_result) return;
			}
			const result = await makeGraphQLRequest({
				query: isEdit
					? mutations.updateForestsData
					: mutations.createForestsData,
				variables: { input: data },
			});
			if (
				result &&
				(file_webGL?.name ||
					file_myVR_scene?.name ||
					baseline_file?.name)
			) {
				if (file_webGL?.name) {
					await Storage.put(fileWebglName, file_webGL, {
						contentType: file_webGL.type,
					});
				}
				if (file_myVR_scene?.name) {
					await Storage.put(fileMyVRSceneName, file_myVR_scene, {
						contentType: file_myVR_scene.type,
					});
				}
				if (baseline_file?.name) {
					await Storage.put(baseline_report_path, baseline_file, {
						contentType: baseline_file.type,
					});
				}
			}
			// create only if both files are selected
			setFormStatus(true);
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCloseModal = () => {
		reset();
		setShowMap(false);
		props.showModal({ value: false });
	};

	const handleChoosePlace = (location) => {
		onChange('lat', location.lat);
		onChange('lon', location.lng);
		onChange('ForestName', location?.country || '');
		onChange('polygon_name', location?.placeName || '');

		setShowMap(false);
	};

	if (!props.modalStatus.value) return null;

	return (
		<FormModal
			onCancel={handleCloseModal}
			onSubmit={() => handleSubmit(!!selectedItem)}
			isLoading={isLoading}
			title={`${selectedItem ? 'Edit' : 'Add'} Forest Plot`}
			error={error}
			formStatus={formStatus}
			formFields={formFields}
			formData={formData}
			hideFooter={showMap}
			hideFields={showMap}
			isEdit={!!selectedItem}>
			{showMap && (
				<MapComponent
					center={
						formData.lat?.value
							? {
									lat: formData.lat?.value,
									lng: formData.lon?.value,
								}
							: undefined
					}
					onLocationSelect={handleChoosePlace}
					onLocationCancel={() => setShowMap(false)}
				/>
			)}
		</FormModal>
	);
}

const mapStateToProps = (state) => ({
	modalStatus: state.modalStatus,
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal, setClientAndRoles })(
	ForestAreaForm
);
