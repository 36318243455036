import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { ReportOutlined } from "@mui/icons-material";
import { IconButton, Tooltip, CircularProgress } from "@digitalreality/ui";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

function MediaDownLoad(props) {
  const handleMediaDownload = async () => {
    try {
      const value = props.value;
      const url = await Storage.get(value, { download: false });
      const parts = value.split("/");
      const fileName = parts[parts.length - 1];

      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("Error downloading video:", error);
    }
  };
  return (
    <div className="flex justify-center w-full items-center h-full">
      <Tooltip title="Download Media">
        <IconButton onClick={handleMediaDownload}>
        <FileDownloadOutlinedIcon className="text-sky-500" />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default MediaDownLoad;
