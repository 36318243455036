import { useState } from 'react';
import CheckoutMain from '../MYVR/CheckoutMain';

const PurchaseCalculator = (props) => {
	const { data, onCancel, onSubmit, onDataChange, maxSizeInSqm } = props;
	const [errors, setErrors] = useState({});

	const formFields = [
		{
			id: 'sizeInSqm',
			label: 'Square meters of office space',
			type: 'string',
			maxValue: maxSizeInSqm,
		},
		{
			id: 'noOfEmployees',
			label: 'No of Employees',
			type: 'string',
			maxValue: 999999999999,
		},
	];

	const handleSubmit = () => {
		const newErrors = {};

		const mandatoryField = formFields.find(
			(field) => field.id === 'sizeInSqm'
		);
		if (!data[mandatoryField.id]) {
			newErrors[mandatoryField.id] = 'Required';
		}

		setErrors(newErrors);
		const hasError = Object.keys(newErrors).length > 0;

		if (!hasError) onSubmit();
	};

	return (
		<CheckoutMain
			title="Sponsor calculator"
			cancelButtonText="cancel"
			submitButtonText="calculate"
			onCancel={onCancel}
			onSubmit={handleSubmit}>
			<div className="flex flex-col gap-3 overflow-hidden py-6">
				{formFields.map((item, index) => (
					<div
						key={index}
						className="flex justify-between items-center gap-3">
						<span className="text-sm font-bold shrink-0 max-w-[140px]">
							{item.label}
						</span>
						<div className="flex flex-col">
							<input
								className={`outline-0 border border-[#888888] h-10 w-48 text-black text-center rounded-3xl px-2 ${errors[item.id] ? 'bg-red-200' : 'bg-white'}`}
								min={1}
								max={item.maxValue}
								value={data?.[item.id] || ''}
								onChange={(e) => {
									if (
										e.target.value <= item.maxValue &&
										e.target.value >= 0
									) {
										onDataChange(item.id, e.target.value);
										setErrors({
											...errors,
											[item.id]: undefined,
										});
									}
								}}
								type="number"
							/>
							{errors[item.id] && (
								<span className="text-red-400 text-[10px] italic">
									{errors[item.id]}
								</span>
							)}
						</div>
					</div>
				))}
			</div>
		</CheckoutMain>
	);
};

export default PurchaseCalculator;
