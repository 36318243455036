import { useMemo } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, PersonAddAltOutlined } from '@mui/icons-material';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as queries from '../../../graphql/queries';
import Grid from '../../CommonComponents/AGGrid/Grid';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import { useGridState } from '../../../components/hooks/useGridState';
import MediaDownLoad from '../../../components/grid/MediaDownLoad';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const MediaList = (props) => {
	const getMediaRecords = async () => {
		try {
			const { assignedRole } =
				props?.loginedInUserData?.data?.getLoggedInUserData;
			let filter_variables = {};

			if (assignedRole !== 'super-admin') {
				filter_variables = {};
			}

			const responseObject = await makeGraphQLRequest({
				query: queries.listMedia,
				variables: filter_variables,
			});
			const items_list = responseObject?.data?.listMedia?.items || [];
			onRecords(items_list);
		} catch (error) {
			onError(error);
		}
	};

	const {
		error,
		rowData,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreateMedia',
		onUpdateTable: 'onUpdateMedia',
		fetchRecords: getMediaRecords,
	});

	const actions = [
		{
			id: 'add_forest',
			namedSizeScaleame: 'Add Forest',
			onClick: () => props.showModal({ value: true }),
			icon: <PersonAddAltOutlined />,
			primary: true,
		},
	];

	const colDefs = useMemo(
		() => [
			{
				field: 'ForestName',
				width: 200,
				headerCheckboxSelection: true,
				pinned: 'left',
				filter: true,
				flex: 1,
			},
			{
				field: 'SiteName',
				flex: 1,
			},
			{
				field: 'MediaPath',
				headerName: 'File Name',
				flex: 1,
			},
			{
				field: 'MediaPath',
				headerName: 'Download',
				cellRenderer: MediaDownLoad,
				flex: 1,
			},
			{
				field: 'MediaType',
				flex: 1,
			},

			{
				field: 'createdBy',
				flex: 1,
			},
		],
		[]
	);

	return (
		<GridLayout
			error={error}
			actions={actions}
			title="Media">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				onGridReady={onGridReady}
				suppressRowClickSelection
				onSelectionChanged={onSelectionChanged}
				rowSelection="none"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(MediaList);
