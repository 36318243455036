import {
	AccessibilityOutlined,
	AdminPanelSettingsOutlined,
	CountertopsOutlined,
	DashboardOutlined,
	NatureOutlined,
	ForestOutlined,
	GroupWorkOutlined,
	InsertInvitationOutlined,
	KeyOutlined,
	ManageSearchOutlined,
	ReceiptOutlined,
	ReportOutlined,
	SaveAltOutlined,
	StoreOutlined,
	ContactSupportOutlined,
} from '@mui/icons-material';

import Forest from '../../Screens/Forest/Forest';
import ForestArea from '../../Screens/ForestArea/ForestArea';
import Purchase from '../../Screens/PurchasePage/Purchase';
import MyvrViewer from '../../Screens/MYVR/MyvrViewer';
import Report from '../../Screens/ReportPage/Report';
import Client from '../../Screens/ClientPages/Client';
import Roles from '../../Screens/Roles/Roles';
import Users from '../../Screens/Users/Users';
import Invitation from '../../Screens/InvitationsPage/Invitation';
// import GeoJSONMapper from '../../Screens/GeoJsonMapper/GeoJSONMapper';
import MyvrViewerWebgl from '../../Screens/MYVR/MyvrViewerWebgl';
import Audit from '../../Screens/Audits/Audit';
import Media from '../../Screens/MediaPages/Media';
import Invoice from '../../Screens/Invoice/Invoice';
import Billing from '../../Screens/BillingPages/Billing';
import SupportTicket from '../../Screens/SupportTicketPages/SupportTicket';

const permission_names = {
	ALLOW_DASHBOARD: 'allowDashboard',
	ALLOW_CLIENT: 'allowClient',
	ALLOW_USERS: 'allowUsers',
	ALLOW_INVITATIONS: 'allowInvitations',
	ALLOW_ROLES: 'allowRoles',
	ALLOW_GEOJSONMAPPER: 'allowGeoJsonMapper',
	ALLOW_AUDITS: 'allowAudits',
	ALLOW_BILLING: 'allowBilling',
	ALLOW_APIKEYS: 'allowAPIKeys',
	ALLOW_REPORTS: 'allowRepots',
	ALLOW_MEDIA_DOWNLOADS: 'allowMediaDownloads',
	ALLOW_FOREST: 'allowForest',
	ALLOW_PURCHASE: 'allowPurchase',
	ALLOW_INVOICE: 'allowInvoice',
	ALLOW_FORESTAREA: 'allowForestArea',
};

const routeList = [
	{
		id: 1,
		name: 'Dashboard',
		url: '/',
		element: <MyvrViewer />,
		allowedPermissions: permission_names.ALLOW_DASHBOARD,
		iconComponent: <DashboardOutlined />,
		group: 'Assets',
	},
	{
		id: 2,
		name: 'Forest',
		url: '/forest',
		element: <Forest />,
		allowedPermissions: permission_names.ALLOW_FOREST,
		iconComponent: <ForestOutlined />,
		group: 'Assets',
	},
	{
		id: 3,
		name: 'Purchase orders',
		url: '/purchase',
		allowedPermissions: permission_names.ALLOW_PURCHASE,
		element: <Purchase />,
		iconComponent: <StoreOutlined />,
		group: 'Accounts',
	},
	{
		id: 4,
		name: 'Invoice',
		url: '/invoice',
		allowedPermissions: permission_names.ALLOW_INVOICE,
		element: <Invoice />,
		iconComponent: <ReceiptOutlined />,
		group: 'Accounts',
	},
	{
		id: 5,
		name: 'Reports',
		url: '/reports',
		allowedPermissions: permission_names.ALLOW_REPORTS,
		element: <Report />,
		iconComponent: <ReportOutlined />,
		group: 'Downloads',
	},
	{
		id: 6,
		name: 'Media Downloads',
		url: '/Media',
		allowedPermissions: permission_names.ALLOW_MEDIA_DOWNLOADS,
		element: <Media />,
		iconComponent: <SaveAltOutlined />,
		group: 'Downloads',
	},
	{
		id: 7,
		name: 'Billing',
		path: '/billing',
		url: '/billing',
		allowedPermissions: permission_names.ALLOW_BILLING,
		element: <Billing />,
		iconComponent: <CountertopsOutlined />,
		group: 'Settings',
	},
	{
		id: 8,
		name: 'Support Tickets',
		path: '/tickets',
		url: '/tickets',
		allowedPermissions: permission_names.ALLOW_APIKEYS,
		element: <SupportTicket />,
		iconComponent: <ContactSupportOutlined />,
		group: 'Settings',
	},
	{
		id: 9,
		name: 'Audits',
		url: '/audit',
		allowedPermissions: permission_names.ALLOW_AUDITS,
		element: <Audit />,
		iconComponent: <ManageSearchOutlined />,
		group: 'Audits',
	},
	{
		id: 10,
		name: 'User Managment',
		url: '/users',
		element: <Users />,
		allowedPermissions: permission_names.ALLOW_USERS,
		iconComponent: <AdminPanelSettingsOutlined />,
		group: 'Admin',
	},
	{
		id: 11,
		name: 'Roles',
		url: '/Roles',
		element: <Roles />,
		allowedPermissions: permission_names.ALLOW_ROLES,
		iconComponent: <AccessibilityOutlined />,
		group: 'Admin',
	},
	{
		id: 12,
		name: 'Clients',
		url: '/client',
		element: <Client />,
		allowedPermissions: permission_names.ALLOW_CLIENT,
		iconComponent: <GroupWorkOutlined />,
		group: 'Admin',
	},
	{
		id: 13,
		name: 'Invitations',
		url: '/invitation',
		element: <Invitation />,
		allowedPermissions: permission_names.ALLOW_INVITATIONS,
		iconComponent: <InsertInvitationOutlined />,
		group: 'Admin',
	},
	{
		id: 14,
		name: 'MyVR WebGL',
		url: '/cubified',
		element: <MyvrViewerWebgl />,
		allowedPermissions: permission_names.ALLOW_PURCHASE,
		hideDrawer: true,
	},
	{
		id: 16,
		name: 'Forest Plot',
		url: '/forestarea',
		element: <ForestArea />,
		allowedPermissions: permission_names.ALLOW_FORESTAREA,
		iconComponent: <NatureOutlined />,
		group: 'Assets',
	},
];

const getDrawerListByPermissions = (permissions) => {
	const items = [];
	routeList.forEach((ele) => {
		if (permissions?.includes(ele.allowedPermissions) && !ele.hideDrawer) {
			const item = items.find((item) => item.title === ele.group);
			if (item) {
				item.items.push({
					name: ele.name,
					url: ele.url,
					iconComponent: ele.iconComponent,
				});
			} else {
				const drawerItem = {
					title: ele.group,
					items: [
						{
							name: ele.name,
							url: ele.url,
							iconComponent: ele.iconComponent,
						},
					],
				};

				items.push(drawerItem);
			}
		}
	});

	return items;
};

const permissionsList = [
	{
		description: 'Dashboard Access',
		value: permission_names.ALLOW_DASHBOARD,
	},
	{
		description: 'Client Access',
		value: permission_names.ALLOW_CLIENT,
	},
	{
		description: 'Users Access',
		value: permission_names.ALLOW_USERS,
	},
	{
		description: 'Invitations Access',
		value: permission_names.ALLOW_INVITATIONS,
	},
	{
		description: 'Roles Access',
		value: permission_names.ALLOW_ROLES,
	},
	{
		description: 'GEOJSON Mapper Access',
		value: permission_names.ALLOW_GEOJSONMAPPER,
	},
	{
		description: 'Audits Access',
		value: permission_names.ALLOW_AUDITS,
	},
	{
		description: 'APIKeys Access',
		value: permission_names.ALLOW_APIKEYS,
	},
	{
		description: 'Reports Access',
		value: permission_names.ALLOW_REPORTS,
	},
	{
		description: 'Billing Access',
		value: permission_names.ALLOW_BILLING,
	},
	{
		description: 'Media Access',
		value: permission_names.ALLOW_MEDIA_DOWNLOADS,
	},
	{
		description: 'Forest Access',
		value: permission_names.ALLOW_FOREST,
	},
	{
		description: 'Forest Area Access',
		value: permission_names.ALLOW_FORESTAREA,
	},
	{
		description: 'Invoice Access',
		value: permission_names.ALLOW_INVOICE,
	},
	{
		description: 'Purchase Access',
		value: permission_names.ALLOW_PURCHASE,
	},
];

export { routeList, permissionsList, getDrawerListByPermissions };
