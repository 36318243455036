const generateGlobeData = (POIS, isvisible = true) => {
	const pointsOfInterest = {
		command: 'pointsOfInterest',
		POIs: [],
	};
	pointsOfInterest.POIs = POIS.map((poi) => ({
		ForestCountry: poi.ForestCountry,
		name: poi.ForestCountry,
		forestName: poi.ForestName,
		noOfHectars: poi.noOfHectars,
		status: poi.Status,
		id: poi.id,
		lat: poi.lat,
		lon: poi.lon,
		icon: poi.icon,
		about_project_pdf_url: poi.about_project_pdf_url,
		project_icon_path: poi.project_icon_path,
		project_map_icon: poi.project_map_icon,
		actions: [
			{ type: 'GoToLonLat' },
			{
				type: 'SetVisible',
				composite: 0,
				layer: 6,
				visible: isvisible,
			},
		],
	}));
	return pointsOfInterest;
};

function generateRandomString(length) {
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters[randomIndex];
	}
	return result;
}

const formatNumber = (number) => {
	return Number(number).toLocaleString('en-US');
};

export { generateGlobeData, generateRandomString, formatNumber };
