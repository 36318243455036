import React,{useEffect,useContext }  from 'react';
import { Button,Text } from '@digitalreality/ui';
import LandingPageVideo from '../LandingPage/LandingPageVideo.mp4';
import { connect } from 'react-redux';
import {StyleContext} from '../../ContextThemes/StyleContext';
import {
    errorTextAction,
    setLandingPageStatus,
  } from "../../ReduxStore/Actions/CommonActions";
  
const LandingPage = (props) => {
    const context_styles = useContext(StyleContext);
    console.log("context***********",context_styles);
    const handleLoginClick = () => {
        handleError('');
        props.setLandingPageStatus();
    }
    const handleError = (errorText) => {
        props.errorTextAction(errorText);
      };
    return (
        <div style={{display:'flex'}}>
            <div style={{ display: 'flex', flexGrow: 1, flexShrink: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: '100vh' }}>
                <video autoPlay muted loop style={{ width: '100vw', height: '100vh', objectFit: 'cover', position: 'fixed', left: 0, top: 0, zIndex: -1, opacity: 0.7 }}>
                    <source src={LandingPageVideo} type="video/mp4" />
                </video>
            </div >
            <div style={{justifyContent:'center', position: 'absolute', top: 40, marginTop: '20%',marginLeft:'30%', display: 'flex', flexDirection: 'column'}}>
                <Text variant="h1" style={{ fontSize: '25px', fontWeight: 'bold', margin: '2px',marginBottom: '5px' }}>Welcome,</Text>
                <Text variant="h2" style={{ fontSize: '18px', fontWeight: 'bold', margin: '2px' }}>What if enterprises could help to preserve biodiversity?</Text>
                <Text variant="h2" style={{ fontSize: '18px', margin: '2px', marginTop: '10px' }}>Now you can, by buying Green Cubes: A cubic meter of the most critical,</Text>
                <Text variant="h2" style={{ fontSize: '18px', margin: '2px' }}>  most vulnerable and most important forests.</Text>
                <Button variant="contained" style={{ height: '35px', width: '85px', fontSize: 14, marginTop: '15px' }} onClick={() => handleLoginClick()}>Lets Go</Button>
            </div>
        </div>
    )
}



{/* <div style={{ display: 'flex', flexGrow: 1, flexShrink: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: '100vh' }}>
                <video autoPlay muted loop style={{ width: '100vw', height: '80vh', objectFit: 'cover', position: 'fixed', left: 0, top: 0, zIndex: -1, opacity: 0.7 }}>
                    <source src={LandingPageVideo} type="video/mp4" />
                </video>
                <div style={{display:'flex', position: 'absolute', top: 0, left: 0,backgroundColor:'#313131',width:'100%',justifyContent:'center',alignItems:'center',flexDirection: 'column' }}>
                    <Text style={{fontFamily:'Roboto',color:'#ffffff',padding:20,fontSize:20}}>Welcome</Text>
                </div>
            </div >
           
            <div style={{justifyContent:'center', position: 'absolute', top: 40, marginTop: '20%',marginLeft:'30%', display: 'flex', flexDirection: 'column'}}>
                <Text variant="h2" style={{ fontSize: '25px', fontWeight: 'bold', margin: '2px' }}>What if enterprises could help to preserve biodiversity?</Text>
                <Text variant="h2" style={{ fontSize: '18px', margin: '2px', marginTop: '10px' }}>Now you can, by buying Green Cubes: A cubic meter of the most critical,</Text>
                <Text variant="h2" style={{ fontSize: '18px', margin: '2px' }}>  most vulnerable and most important forests.</Text>
                <Button variant="contained" style={{ height: '35px', width: '85px', fontSize: 14, marginTop: '15px' }} onClick={() => handleLoginClick()}>Lets Go</Button>
            </div>
            <div style={{display:'flex', position:'absolute', bottom: 0, left: 0, height:200,  backgroundColor:'#313131',width:'100%',justifyContent:'center',alignItems:'center',flexDirection: 'column' }}>
  </div> */}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { errorTextAction, setLandingPageStatus })(LandingPage);
