const formValidation = (formFields = [], state) => {
    const newState = {
        ...state,
    };

    formFields.forEach((formField) => {
        const item = formField.id;
        if (!formField.skipValidation && !formField.hide) {
            if (formField?.customValidator) {
                const errorMsg = formField.customValidator(newState[item]?.value);
                if (errorMsg !== '') {
                    newState[item] = {
                        ...newState[item],
                        error: errorMsg || '',
                    }
                }
            }
            if (!newState[item] || !newState[item]?.value) {
                newState[item] = {
                    ...newState[item],
                    error: `${formField.label} is required`,
                }
            }
        }
        else {
            newState[item] = {
                ...newState[item],
                value: formField.type === 'checkbox' ? !!newState[item]?.value : newState[item]?.value,
                error: '',
            }
        }
    });

    const hasError = Object.keys(newState).some(field => !!newState[field].error);

    const reducedData = Object.keys(newState).reduce((acc, key) => {
        if (newState[key]?.value !== newState[key]?.initialValue && newState[key]?.value !== undefined && newState[key]?.value !== null) {
            acc[key] = newState[key]?.value;
        }
        return acc;
    }, {});

    return {
        newState,
        hasError,
        reducedData,
    }
}

export { formValidation };
