import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ChevronLeft, ChevronRight, LogoutOutlined } from '@mui/icons-material';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import { Auth, Storage } from 'aws-amplify';
import GreenCubesLogo from '../../../Images/Greencube_logo_green.png';
import GreenCubeMiniLogo from '../../../Images/GreenCubeMiniLogo.png';
import { loggedInUserData } from '../../ReduxStore/Actions/CommonActions';
import { routeList, getDrawerListByPermissions } from './routeList';
import { Button } from '@digitalreality/ui';

const DrawerLists = (props) => {
	const { firstName, profilePicURL, accessLevel } =
		props.loginedInUserData?.data?.getLoggedInUserData || {};
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [selectedText, setselectedText] = useState('');
	const [profilePic, setprofilePic] = useState('');

	const navigate = useNavigate();

	useEffect(() => {
		const syncLogout = (event) => {
			if (event.key === 'logout') {
				// If a logout event is detected, sign the user out of all browsers
				Auth.signOut();
				window.location.href = '/';
			}
		};
		window.addEventListener('storage', syncLogout);
		return () => {
			window.removeEventListener('storage', syncLogout);
		};
	}, []);

	useEffect(() => {
		const item = routeList.find(
			(ele) => ele.url === window.location.pathname
		);

		if (item) {
			setselectedText(item.name);
		}
	}, []);

	useEffect(() => {
		const resizeEvent = new Event('resize');
		setTimeout(() => {
			window.dispatchEvent(resizeEvent);
		}, 310);
	}, [isDrawerOpen]);

	useEffect(() => {
		const getProfilePic = async () => {
			try {
				const imageAccessURL = await Storage.get(profilePicURL);
				setprofilePic(imageAccessURL);
			} catch (error) {
				console.log('error signing out: ', error);
			}
		};

		getProfilePic();
	}, [profilePicURL]);

	const handleDrawer = () => {
		setIsDrawerOpen((open) => !open);
	};

	const handleLogout = async () => {
		try {
			await Auth.signOut();
			localStorage.setItem('logout', Date.now());
			navigate('/');
			props.loggedInUserData({});
		} catch (error) {
			console.log(error);
		}
	};

	const handleSelectedItem = (child) => {
		setselectedText(child.name);
		navigate(child.url);
	};

	const filteredDrawerList = useMemo(
		() => getDrawerListByPermissions(accessLevel?.permissions || []),
		[accessLevel?.permissions]
	);

	return (
		<div
			className="flex flex-col bg-[#313131] max-w-72 mr-0 relative"
			style={{
				transition: 'width 0.3s ease-in-out',
				width: isDrawerOpen ? '240px' : '80px',
			}}>
			<div
				className="absolute top-2/4 bg-gradient-to-b from-green-500 to-green-800 right-0 -mr-3 -mb-3 rounded-r-lg py-6 bg-[#313131] w-3 h-3 flex justify-center items-center cursor-pointer text-white px-1 z-50"
				onClick={handleDrawer}>
				{isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
			</div>
			<div className="bg-[#313131] p-4">
				<img
					src={isDrawerOpen ? GreenCubesLogo : GreenCubeMiniLogo}
					alt="GreenCubes"
					className={` object-contain ${isDrawerOpen ? 'w-36 h-[50px]' : 'w-36 h-[50px]'}`}
				/>
			</div>
			<div className="bg-gradient-to-r from-green-500 to-green-800 h-[0.5px]" />
			<div
				className={`flex flex-col ${isDrawerOpen ? 'gap-4' : 'gap-2'} h-screen overflow-auto bg-[#313131] overflow-y-auto pt-4`}>
				{filteredDrawerList.map((item) => (
					<div
						key={item.title}
						className={`flex flex-col gap-y-2 pl-4 pr-4`}>
						{isDrawerOpen && <span>{item.title}</span>}
						{item.items.map((child) => (
							<div
								key={child.name}
								className={`flex items-center gap-x-2 p-[10px] cursor-pointer rounded-md hover:bg-gray-700 hover:opacity-70 ${selectedText === child.name ? 'bg-gradient-to-r from-green-600 to-green-800 text-white hover:text-black' : ''}`}
								onClick={() => handleSelectedItem(child)}
								style={{
									justifyContent: isDrawerOpen
										? 'flex-start'
										: 'center',
								}}>
								<Tooltip
									placement="right"
									title={child.name}
									className="flex flex-col items-center w-full">
									{child.iconComponent}
								</Tooltip>
								{isDrawerOpen && (
									<span className="overflow-hidden text-ellipsis">
										{child.name}
									</span>
								)}
							</div>
						))}
					</div>
				))}
			</div>
			<div className="flex flex-col items-center justify-center mb-3 mt-3 gap-3">
				<div
					className={`flex items-center gap-2 mx-1 p-2 rounded-full ${isDrawerOpen ? 'w-11/12 bg-[#9BE938]' : ''}`}>
					<Avatar
						alt="Remy Sharp"
						src={profilePic}
						sx={{
							width: 24,
							height: 24,
							borderRadius: 24,
						}}
					/>
					{isDrawerOpen && (
						<span className="overflow-hidden text-ellipsis text-black">
							{firstName}
						</span>
					)}
				</div>
				{isDrawerOpen ? (
					<Button
						color="secondary"
						variant="outlined"
						sx={{
							borderRadius: 40,
							width: '91.7%',
						}}
						endIcon={<LogoutOutlined />}
						onClick={handleLogout}>
						<span>Logout</span>
					</Button>
				) : (
					<IconButton
						color="secondary"
						sx={{
							borderRadius: 40,
							width: 30,
							height: 30,
						}}
						onClick={handleLogout}>
						<LogoutOutlined />
					</IconButton>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { loggedInUserData })(DrawerLists);
