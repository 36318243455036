import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import Grid from '../../CommonComponents/AGGrid/Grid';
import { connect } from 'react-redux';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import { useGridState } from '../../../components/hooks/useGridState';
import * as queries from '../../../graphql/queries';
import { useMemo, useState } from 'react';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const InvoiceRenderer = ({ value }) => {
	return (
		<span
			style={{
				display: 'flex',
				height: '100%',
				width: '100%',
				alignItems: 'center',
			}}>
			{value == 'New' ? 'Pending Payment' : 'Paid'}
		</span>
	);
};

const BillingList = (props) => {
	const { assignedRole, clientId } =
		props.loginedInUserData?.data?.getLoggedInUserData;
	const [consolidatedData, setconsolidatedData] = useState({
		totalpurchasedcubes: '00',
		totalamountpaid: '00',
		pendingamount: '00',
	});
	const getPurchaseRecords = async () => {
		try {
			// const { assignedRole, clientId } = props.loginedInUserData?.data?.getLoggedInUserData;
			let filter_variables = {};
			if (assignedRole !== 'super-admin') {
				filter_variables = {
					filter: { purchasedClientName: { eq: clientId } },
				};
			}
			const responseObject = await makeGraphQLRequest({
				query: queries.listPurchaseOrders,
				variables: filter_variables,
			});
			const items_list =
				responseObject?.data?.listPurchaseOrders?.items || [];
			let cubes_count = 0;
			let paid_amount = 0;
			let pending_amount = 0;

			if (items_list.length > 0) {
				for (let i = 0; i < items_list.length; i++) {
					cubes_count =
						cubes_count +
						parseInt(items_list[i].purchasedNoOfCubes, 10);
					if (items_list[i].purchaseStatus === 'New') {
						console.log(
							'*****',
							items_list[i].purchasedInvoiceCost,
							items_list[i].purchasedNoOfCubes,
							'New'
						);
						pending_amount =
							pending_amount +
							parseFloat(items_list[i].purchasedInvoiceCost, 10);
					} else if (items_list[i].purchaseStatus === 'Approved') {
						console.log(
							'*****',
							items_list[i].purchasedInvoiceCost,
							items_list[i].purchasedNoOfCubes,
							'Approved'
						);
						paid_amount =
							paid_amount +
							parseFloat(items_list[i].purchasedInvoiceCost, 10);
					}
				}

				let updated_count_object = {
					totalpurchasedcubes: `${cubes_count}`,
					totalamountpaid: `${paid_amount.toFixed(2)}`,
					pendingamount: `${pending_amount.toFixed(2)}`,
				};
				setconsolidatedData(updated_count_object);
				console.log(
					'Cubes',
					cubes_count,
					'Pending',
					pending_amount,
					'paid amount',
					paid_amount
				);
			}
			onRecords(items_list);
		} catch (error) {
			onError(error);
		}
	};
	const {
		error,
		rowData,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreatePurchaseOrders',
		onUpdateTable: 'onUpdatePurchaseOrders',
		fetchRecords: getPurchaseRecords,
	});

	const actions = [];

	const colDefs = useMemo(
		() => [
			{
				field: 'purchasedClientName',
				pinned: 'left',
				headerName: 'Client',
				width: 150,
			},
			{
				field: 'purchasedForestName',
				headerName: 'Forest',
				width: 180,
			},
			{
				field: 'purchasedForestArea',
				headerName: 'Forest Area',
				width: 180,
			},
			{
				field: 'purchasedNoOfCubes',
				headerName: '# Cubes',
				width: 100,
			},
			{
				field: 'purchasedInvoiceCost',
				headerName: 'Invoice Amount',
				width: 150,
			},
			{
				field: 'purchaseStatus',
				headerName: 'Order Status',
				cellRenderer: InvoiceRenderer,
				width: 180,
			},
			{
				field: 'purchasedOn',
				width: 180,
			},
			{
				field: 'purchaseddBy',
				flex: 1,
			},
		],
		[assignedRole, onError]
	);

	return (
		<div className="flex w-full h-full flex-col">
			<div className="bg-red-500 p-4">
				<div className="flex justify-around text-white">
					<div className="text-center">
						<h3 className="font-bold text-2xl text-[#fff]">
							{'Total Purchased Cubes #'}
						</h3>
						<p className="font-bold text-3xl">
							{consolidatedData.totalpurchasedcubes}
						</p>
					</div>
					<div className="text-center">
						<h3 className="font-bold text-2xl text-[#fff]">
							{'Total Amount Paid($)'}
						</h3>
						<p className="font-bold text-3xl">
							{consolidatedData.totalamountpaid}
						</p>
					</div>
					<div className="text-center">
						<h3 className="font-bold text-2xl text-[#fff]">
							{'Pending Invoice Amount($)'}
						</h3>
						<p className="font-bold text-3xl">
							{consolidatedData.pendingamount}
						</p>
					</div>
				</div>
			</div>
			<GridLayout
				error={error}
				actions={actions}
				title="">
				<Grid
					refObject={gridRef}
					rowData={rowData}
					colDefs={colDefs}
					suppressRowClickSelection
					onGridReady={onGridReady}
					onSelectionChanged={onSelectionChanged}
					rowSelection="single"
				/>
			</GridLayout>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(BillingList);
