import SupportTicketForm from "./SupportTicketForm";
import SupportTicketList from "./SupportTicketList";

const Forest = () => {
    return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<SupportTicketForm />
			<SupportTicketList />
		</div>
    );
};

export default Forest;
