import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import * as myVRStudio from '@myvr/myvr-studio-react';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import { Storage } from 'aws-amplify';
import { generateGlobeData } from '../../../utils/utils';
import WebGLComponent from './WebGLComponent';
import PieChart from './PieChart';
import PolygonComponent from '../../CommonComponents/PolygonComponent';
import RequestAgent from './Checkout';
import PurchaseCalculatorConfirm from '../PurchasePage/PurchaseCalculatorConfirm';
import MyVRBottom from './MyVRBottom';
import PurchaseCalculator from '../PurchasePage/PurchaseCalculator';
import MYVRModal from '../../../components/base/MYVRModal';
import VideoPlayer from './MYVRPageModalContent/VideoPlayer/VideoPlayer';
import PDFViewer from '../../../components/base/PDFViewer';
import Checkout from './ShoppingCart';
import PurchaseConfirm from '../PurchasePage/PurchaseConfirm';
import { Riple } from 'react-loading-indicators';
import { generateRandomString } from '../../../utils/utils';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const MyvrViewerWebgl = (props) => {
	const { clientId, clientName, emailId } =
		props.loginedInUserData?.data?.getLoggedInUserData || {};
	const [isExplore, setIsExplore] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isPurchaseError, setIsPurchaseError] = useState({
		status: false,
		message: '',
	});
	const [commonState, setCommonState] = useState({
		isLoading: false,
		forests: {
			command: 'pointsOfInterest',
			POIs: [],
		},
		polygon_data: [],
		selectedForest: {},
		modal: {
			open: false,
			showMedia: false,
			showReports: false,
			selected_report_url: '',
		},
		selectedPolygon: {},
		isViewerReady: false,
		mykey: '0.25395060863060026layeroverlay',
		displayWeather: false,
		displayJoystick: false,
		forestLiveWeather: {},
		currentPage: 'Globe',
		pathCoordinates: [],
		video_duration: 30,
		displayScene:
			'scene=https://desolinator.s3.eu-north-1.amazonaws.com/greenCubes-globe_new_color.json',
		sceneLoading: false,
	});
	const [percentage_array, setpercentage_array] = useState([50, 25, 25]);
	const [sponsor, setSponsor] = useState({
		show: false,
		step: 0,
		data: {
			sizeInSqm: 1,
		},
	});
	const stateRef = useRef(commonState);

	useEffect(() => {
		stateRef.current = commonState;
	}, [commonState]);

	useEffect(() => {
		//Percentage load
		const percentages = generateRandomPercentages();
		console.log('sponsered data', sponsor);
		const mycommand = {
			command: 'updateOverlays',
			plugin: 1,
			areas: [0.25, 0.25, 0.5],
		};
		myVRStudio.executeCommand(mycommand);
	}, [sponsor]);

	useEffect(() => {
		const loadData = async () => {
			// Step 1: For visulization client we need to add filter to get main forest data
			let filter_variables = {};

			//Step2 : Get main list forest data as per the filter
			const forest_list = await makeGraphQLRequest({
				query: queries.listForestMainTables,
				variables: { filter: filter_variables },
			});
			const forest_items_list =
				forest_list?.data?.listForestMainTables?.items || [];

			// Step3 : get polygon list and we dont need filter here..so get all
			const polygon_list = await makeGraphQLRequest({
				query: queries.listForestsData,
				variables: { filter: {} },
			});
			const polygon_items_list =
				polygon_list?.data?.listForestsData?.items || [];

			// Step 4: get image url's from storage for both forest and polygons
			const forest_result = await get_image_storage_urls(
				forest_items_list,
				['icon', 'about_project_pdf_url']
			);
			const polygon_result = await get_image_storage_urls(
				polygon_items_list,
				['area_MYVR_scene_file_path', 'area_WEBGL_cubified_file_path']
			);

			//Step 5: Addtional step for main forest data to convert data in to Poi's required format
			const forest_globe_data = generateGlobeData(forest_result);

			updateCommonState({
				forests: forest_globe_data,
				polygon_data: polygon_result,
				isLoading: true,
				sceneLoading: true,
			});
		};
		loadData();
	}, []);

	useEffect(() => {
		const onViewerReady = (event) =>
			updateCommonState({
				isViewerReady: event.detail.isReady,
			});
		window.addEventListener('myVRStudioReady', onViewerReady);
		return () =>
			window.removeEventListener('myVRStudioReady', onViewerReady);
	}, []);

	useEffect(() => {
		// let timer;
		if (commonState.isViewerReady) {
			if (
				commonState.currentPage === 'Globe' ||
				commonState.currentPage === 'Poly'
			) {
				myVRStudio.registerRenderCallback(processCallback);
			}
		}
	}, [
		commonState.isViewerReady,
		commonState.forests,
		commonState.currentPage,
	]);

	useEffect(() => {
		if (
			commonState.selectedPolygon &&
			commonState.currentPage == 'experince'
		) {
			const content = {
				action: 'elementClicked',
				'hit-records': [
					{
						hits: [{ id: commonState.selectedPolygon.id }],
					},
				],
			};
			const data = { data: JSON.stringify(content) };
			processCallback(data);
		}
	}, [commonState.selectedPolygon, commonState.currentPage]);

	const addColorLayer = () => {
		const addColorLayerCommand = {
			command: 'createPlugin',
			pluginType: 'layerOverlayPlugin',
			srcLayer: 50,
			cmdId: commonState.selectedPolygon.id, // Pass selected polygoin ID
			area1: percentage_array[0] / 100,
			areaName1: 'Available',
			area2: percentage_array[1] / 100,
			areaName2: 'Sponsored',
			area3: percentage_array[2] / 100,
			areaName3: 'Unavailable',
		};
		myVRStudio.executeCommand(addColorLayerCommand);
	};
	const updateColorLayer = (pluginID) => {
		const updateColorLayerCommand = {
			command: 'updateOverlays',
			plugin: pluginID,
			areas: [
				percentage_array[0] / 100,
				percentage_array[1] / 100,
				percentage_array[2] / 100,
			],
		};
		myVRStudio.executeCommand(updateColorLayerCommand);
	};

	const updateCommonState = (updates) => {
		setCommonState((prevState) => ({
			...prevState,
			...updates,
		}));
	};
	function generateRandomPercentages() {
		let num1 = Math.random() * 100;
		let num2 = Math.random() * (100 - num1);
		let num3 = 100 - num1 - num2;
		return [num1, num2, num3];
		// setpercentage_array([num1, num2, num3]);
	}

	const get_image_storage_urls = async (listOfObjects, columns = []) => {
		const updated_storage_url_objects = await Promise.all(
			listOfObjects.map(async (obj) => {
				const updatedObj = { ...obj };
				try {
					for (const col of columns) {
						updatedObj[col] = await Storage.get(obj[col]);
					}
				} catch (error) {
					console.error('**********', error);
					for (const col of columns) {
						updatedObj[col] = undefined;
					}
				}
				return updatedObj;
			})
		);
		return updated_storage_url_objects;
	};

	const handle_experience_click = async (uniqueKey) => {
		const geo_json_forID =
			stateRef.current.selectedPolygon.area_WEBGL_cubified_file_path;

		console.log(
			'selected polygon could be undefined because polygon ID not mapped in geojson data yet if coming from globe directly',
			geo_json_forID
		);

		if (geo_json_forID) {
			updateCommonState({
				pathCoordinates: [],
				video_duration: 0,
				mykey: uniqueKey,
				displayJoystick: true,
				displayScene: `layer=${'https://desolinator.s3.eu-north-1.amazonaws.com/GreenCubes-Cubified/finca_recentered.glb'}`,
			});
		} else {
			//we need to remove this in future once we have polygonID mapped in geOJSON, BELOW is hard coded url
			updateCommonState({
				pathCoordinates: [],
				video_duration: 0,
				mykey: uniqueKey,
				displayJoystick: false,
				displayScene: `layer=${'https://desolinator.s3.eu-north-1.amazonaws.com/GreenCubes-Cubified/finca_recentered.glb'}`,
			});
		}
	};

	const processCallback = async (data) => {
		const info = JSON.parse(data.data);
		console.log('_____________statered', stateRef.current.currentPage);
		console.log('MY INFO', info);

		if (info?.action === 'sceneLoaded') {
			myVRStudio.executeCommand(commonState.forests);
			updateCommonState({
				sceneLoading: false,
			});
		} else if (info?.action === 'elementClicked') {
			const selected_id = info['hit-records']?.[0]?.hits?.[0]?.id;
			const uniqueKey = Math.random() + 'layeroverlay';
			const latest_state = stateRef.current;
			if (latest_state.currentPage === 'Globe') {
				if (
					latest_state.forests &&
					latest_state.forests['POIs']?.length > 0
				) {
					const forest_obj = latest_state.forests['POIs'].find(
						(item) => item['id'] == selected_id
					);
					if (forest_obj) {
						updateCommonState({
							selectedForest: forest_obj,
							currentPage: 'Poly',
						});
					}
				}
			} else if (stateRef.current.currentPage === 'Poly') {
				updateCommonState({
					currentPage: 'experince',
				});
			} else if (stateRef.current.currentPage === 'experince') {
				//This trigger will happen in useeffect in line 213
				handle_experience_click(uniqueKey);
			}
		}
	};
	const handleExplore = async () => {
		try {
			setIsExplore(true);
			const responseObject = await makeGraphQLRequest({
				query: queries.getPolygonCompletePurchaseData,
				variables: {
					poly_id: commonState.selectedPolygon?.id,
					user_client_id: clientId,
				},
			});
			console.log('Percentage api response', responseObject);
		} catch (error) {
			console.log(error);
		}
	};

	const handleCallback = (actionType) => {
		if (actionType === 'showExperience' && commonState.selectedPolygon) {
			handleExplore();
			updateCommonState({
				currentPage: 'experince',
			});
		} else if (actionType === 'showPOIs') {
			updateCommonState({
				selectedPolygon: {},
				currentPage: 'Globe',
				displayScene:
					'scene=https://desolinator.s3.eu-north-1.amazonaws.com/greenCubes-globe_new_color.json',
				displayJoystick: false,
			});

			setSponsor({});
			setIsExplore(false);
		} else if (actionType === 'showMedia') {
			setSponsor({});
			updateCommonState({
				modal: {
					open: true,
					showMedia: true,
					showReports: false,
					selected_report_url: '',
				},
			});
		} else if (actionType == 'showAbout') {
			setSponsor({});
			console.log('selected forest', commonState.selectedForest);
			updateCommonState({
				modal: {
					open: true,
					showMedia: false,
					showReports: true,
					selected_report_url:
						commonState.selectedForest.about_project_pdf_url,
				},
			});
			// const mycommand = {
			//   command: "action",
			//   type: "GoToLonLat",
			//   lat: 8.713967,
			//   lon: -83.1836391,
			//   animate: true,
			// };
			// myVRStudio.executeCommand(mycommand);
		}
	};

	const display_modal = () => {
		return (
			<MYVRModal
				open={commonState.modal.open}
				isloading={false}
				error={false}
				onCancel={() => {
					updateCommonState({
						modal: {
							open: false,
							showMedia: false,
							showReports: false,
							selected_report_url: '',
						},
					});
				}}
				title={commonState.modal.showMedia ? 'Media' : 'Reports'}
				removePadding
				maxWidth={commonState.modal.showMedia ? 'lg' : 'xl'}>
				{display_modal_content()}
			</MYVRModal>
		);
	};
	const display_modal_content = () => {
		if (commonState.modal.showMedia) {
			return (
				<VideoPlayer
					video_items={[]}
					selectedForest={commonState.selectedForest}
				/>
			);
		} else if (commonState.modal.showReports) {
			return <PDFViewer url={commonState.modal.selected_report_url} />;
		}
	};

	const handleSponsorCalSubmit = () => {
		setSponsor({
			...sponsor,
			step: 1,
		});
	};

	const handleRequestAgent = () => {
		setSponsor({
			...sponsor,
			step: 3,
		});
	};

	const createSupportTicketForAgent = async () => {
		try {
			setIsLoading(true);
			const { ForestName, ForestID, polygon_name, id, CubePrice } =
				commonState?.selectedPolygon;
			const data = {
				ticketCreatedBy: emailId,
				ticketCreatedOn: new Date().toLocaleDateString(),
				tickerCreatedClientName: clientName,
				ticketMainForestName: ForestID,
				ticketComments: '',
				ticketRefNumber: generateRandomString(8),
				ticketStatus: 'New',
				contactEmail: emailId,
				contactPhone: '+971127361 - Temp',
				meetingDateAndTime: '',
				audit_client_msg: `${clientName}@@${emailId}`,
			};
			const response = await makeGraphQLRequest({
				query: mutations.createSupportTicketsTable,
				variables: { input: data },
			});
			console.log('response>>>>>', response);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			let errorMsg = error.message;
			if (!errorMsg) {
				errorMsg = error?.errors?.[0]?.message;
			}
			setIsPurchaseError({
				status: true,
				message: errorMsg,
			});
		}
	};

	const handlePurchaseRequest = async (purchasedCubes, invoiceCost) => {
		try {
			setIsLoading(true);
			const purchaseResult = await makeGraphQLRequest({
				query: queries.validate_purchase_order,
				variables: {
					poly_id: commonState.selectedPolygon.id,
					user_client_id: clientId,
					currentOrderCubes: sponsor?.data?.sizeInSqm,
				},
			});

			if (purchaseResult) {
				const { ForestName, ForestID, polygon_name, id, CubePrice } =
					commonState?.selectedPolygon;
				const data = {
					purchasedClientName: clientName,
					purchasedClientID: clientId,
					purchaseddBy: emailId,
					purchasedForestName: ForestName,
					purchasedForestID: ForestID,
					purchasedForestArea: polygon_name,
					purchasedForestAreaID: id,
					purchasedNoOfCubes: purchasedCubes.toString(),
					purchasedCubePrice: CubePrice,
					purchasedInvoiceCost: invoiceCost,
					purchaseStatus: 'New',
					purchaseComments: '',
					purchasedInvoicePath: '',
					purchasedInvoiceNumber: '',
					purchasedOn: new Date().toLocaleDateString(),
					audit_client_msg: `${clientName}@@${emailId}`,
				};
				const response = await makeGraphQLRequest({
					query: mutations.createPurchaseOrders,
					variables: { input: data },
				});
				setSponsor({
					...sponsor,
					step: 4,
				});
			}
		} catch (error) {
			setIsLoading(false);
			let errorMsg = error.message;
			if (!errorMsg) {
				errorMsg = error?.errors?.[0]?.message;
			}
			setIsPurchaseError({
				status: true,
				message: errorMsg,
			});
		}
	};

	const handlePurchaseCancel = () => {
		setIsPurchaseError({});
		setSponsor({
			...sponsor,
			step: 0,
			show: false,
			data: {},
		});
	};

	const sponsorComponents = {
		0: (
			<PurchaseCalculator
				data={sponsor.data}
				onSponsorCalSubmit={handleSponsorCalSubmit}
				onPurchaseCancel={handlePurchaseCancel}
				maxSizeInSqm={Number(commonState.selectedPolygon?.NoOfCubes)}
				onDataChange={(property, value) => {
					setSponsor({
						...sponsor,
						data: {
							...sponsor.data,
							[property]: value,
						},
					});
				}}
			/>
		),
		1: (
			<PurchaseCalculatorConfirm
				data={sponsor.data}
				onPurchaseCancel={handlePurchaseCancel}
			/>
		),
		2: (
			<Checkout
				selectedPolygon={commonState.selectedPolygon}
				data={sponsor.data}
				isLoading={isLoading}
				onRequestAgent={handleRequestAgent}
				onPurchaseCancel={handlePurchaseCancel}
				onPurchaseSubmit={handlePurchaseRequest}
				isPurchaseError={isPurchaseError}
			/>
		),
		3: (
			<RequestAgent
				data={sponsor.data}
				onPurchaseCancel={handlePurchaseCancel}
				onRequestAgent={createSupportTicketForAgent}
			/>
		),
		4: (
			<PurchaseConfirm
				selectedForest={commonState.selectedForest}
				selectedPolygon={commonState.selectedPolygon}
				data={sponsor.data}
			/>
		),
	};

	return (
		<div className="flex h-screen overflow-hidden">
			{commonState.sceneLoading && (
				<div className="absolute flex items-center justify-center top-0 left-0 w-full h-full z-[999999] bg-[#262626]/[.6]">
					<Riple
						color="#0cc50c"
						size="large"
						text="Loading"
						textColor="#0deb30"
					/>
				</div>
			)}
			{commonState.isLoading && (
				<>
					<div className="flex shrink-0 grow-0 h-full w-1/4">
						<PolygonComponent
							forests={commonState.forests['POIs']}
							currentPage={commonState.currentPage}
							callbackHandler={handleCallback}
							polygon_list={commonState.polygon_data}
							selectedPolygon={commonState.selectedPolygon}
							onPolygonSelect={(polygon) =>
								updateCommonState({ selectedPolygon: polygon })
							}
							selectedForest={commonState.selectedForest}
							onForestSelect={(forest) =>
								updateCommonState({ selectedForest: forest })
							}
							isExplore={isExplore}
							onSponsorCal={() => {
								setSponsor({
									...sponsor,
									step: 0,
									show: true,
								});
								const resizeEvent = new Event('resize');
								setTimeout(() => {
									window.dispatchEvent(resizeEvent);
								}, 0);
							}}
						/>
					</div>
					<div className="flex flex-col overflow-hidden w-full">
						<div
							id="myvr-studio-viewer"
							className="animate-fadeIn w-full h-full max-h-full relative overflow-hidden">
							<myVRStudio.Viewer
								viewerContainerId={'myvr-studio-viewer'}
								options={commonState.displayScene}
								key={commonState.mykey}
							/>
							{sponsor.show && (
								<div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[9999] w-4/6 flex justify-center">
									{sponsorComponents[sponsor.step]}
								</div>
							)}
						</div>
						{(isExplore ||
							commonState.currentPage === 'Cubified') &&
							commonState.selectedPolygon && (
								<MyVRBottom
									onSubmit={() => {
										setSponsor({
											...sponsor,
											step: 2,
										});
									}}
									value={sponsor.data?.sizeInSqm}
									submitDisabled={sponsor.step !== 1}
									inputDisabled={sponsor.step > 1}
									maxValue={Number(
										commonState.selectedPolygon?.NoOfCubes
									)}
									onChange={(value) => {
										setSponsor({
											...sponsor,
											data: {
												...sponsor.data,
												sizeInSqm: value,
											},
										});
									}}
								/>
							)}
						{display_modal()}
					</div>
				</>
			)}
			{!commonState.isLoading && (
				<div className="flex flex-col overflow-hidden w-full">
					<div className="flex flex-col w-full h-full max-h-full items-center justify-center bg-[#262626]">
						<Riple
							color="#0cc50c"
							size="large"
							text="Loading"
							textColor="#0deb30"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	errorStatus: state.errorStatus,
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps)(MyvrViewerWebgl);
