import { useMemo } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, PersonAddAlt1Outlined } from '@mui/icons-material';

import TextToBool from '../../../components/grid/TextToBool';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import Grid from '../../CommonComponents/AGGrid/Grid';
import * as queries from '../../../graphql/queries';
import { useGridState } from '../../../components/hooks/useGridState';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import MultiSelectChip from '../../../components/grid/MultiSelectChip';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const RolesList = (props) => {
	const { assignedRole } = props.loginedInUserData?.data?.getLoggedInUserData;
	const getRoles = async () => {
		try {
			const responseObject = await makeGraphQLRequest({
				query: queries.listRolesTables,
			});
			const items_list =
				responseObject?.data?.listRolesTables?.items || [];
			onRecords(items_list);
		} catch (error) {
			onError(error);
		}
	};
	const {
		error,
		rowData,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreateRolesTable',
		onUpdateTable: 'onUpdateRolesTable',
		fetchRecords: getRoles,
	});

	const actions = [
		{
			id: 'add_role',
			name: 'Add Role',
			primary: true,
			onClick: () => props.showModal({ value: true }),
			icon: <PersonAddAlt1Outlined />,
		},
		{
			id: 'edit_role',
			name: 'Update Role',
			onClick: () =>
				props.showModal({ value: true, selectedItem: selectedRows[0] }),
			icon: <EditOutlined />,
			disabled: selectedRows.length !== 1,
		},
	];

	const colDefs = useMemo(
		() => [
			{
				field: 'id',
				width: 150,
				headerName: 'Name',
				checkboxSelection: true,
				headerCheckboxSelection: true,
				pinned: 'left',
			},
			{
				field: 'createdBy',
				width: 300,
			},
			{
				field: 'permissions',
				cellRenderer: MultiSelectChip,
				wrapText: true,
				autoHeight: true,
				flex: 1,
				autoHeight: true,
				cellStyle: { 'white-space': 'normal' },
			},
			{
				field: 'createdAt',
				width: 300,
			},
		],
		[]
	);

	return (
		<GridLayout
			error={error}
			actions={assignedRole === 'super-admin' ? actions : []}
			title="Roles">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				onGridReady={onGridReady}
				onSelectionChanged={onSelectionChanged}
				rowSelection="single"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(RolesList);
