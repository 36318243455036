import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import * as myVRStudio from '@myvr/myvr-studio-react';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import { Storage } from 'aws-amplify';
import { generateGlobeData, generateRandomString } from '../../../utils/utils';
import MyVRWidget from './MyVRWidget';
import PolygonComponent from '../../CommonComponents/PolygonComponent';
import MYVRModal from '../../../components/base/MYVRModal';
import VideoPlayer from './MYVRPageModalContent/VideoPlayer/VideoPlayer';
import PDFViewer from '../../../components/base/PDFViewer';
import MyVRVideoPlayer from './MyVRVideoPlayer';
import MYVRMap from './MYVRMap';
import { Joystick } from 'react-joystick-component';
import { Riple } from 'react-loading-indicators';
import MyVRBottom from './MyVRBottom';
import PurchaseCalculator from '../PurchasePage/PurchaseCalculator';
import PurchaseCalculatorConfirm from '../PurchasePage/PurchaseCalculatorConfirm';
import ShoppingCart from './ShoppingCart';
import Checkout from './Checkout';
import PurchaseConfirm from '../PurchasePage/PurchaseConfirm';
import AboutForestPage from '../PurchasePage/AboutForest';
import ContactRequest from './ContactRequest';
import PieChart from './PieChart';
import { setAppLoadingIndicator } from '../../ReduxStore/Actions/CommonActions';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

// Below URL is the main globe scene URL and internally it is refering to scene layer with URL
//https://desolinator.s3.eu-north-1.amazonaws.com/Greencubes_Public_Data/GreenCubesGlobePolygons.json
// we have to change /add/modify polygons in this url

const global_globe_scene =
	'scene=https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_Globe_Public_Scenes/greencubesGlobeScene.json';

const polygon_def_json =
	'https://greencubes-public-bucket.s3.eu-north-1.amazonaws.com/Greencubes_Globe_Public_Scenes/GreenCubesGlobePolygons.json';

const pages = {
	ForestList: 0,
	ForestDetail: 1,
	ForestExplore: 2,
	ForestSite: 3,
};

const initialSponsorState = {
	step: 0,
	show: false,
	data: {
		sizeInSqm: 1,
		numberOfCubes: 10000,
	},
};

const MyvrViewer = (props) => {
	//+++++++++++++++++++++++++++++State def++++++++++++++++++++++++++++++++++//
	const [isvideoPlaying, setisvideoPlaying] = useState(false);
	const [showAboutForest, setshowAboutForest] = useState(false);
	const [isPage3DataRetriving, setisPage3DataRetriving] = useState(false);
	const { appLoadingIndicator } = props;
	const [commonState, setCommonState] = useState({
		isLoading: false,
		displayScene: global_globe_scene,
		currentPage: 'Globe',
		page_current_action: '',
		page: pages.ForestList,
		selectedForest: {},
		selectedPolygon: {},
		sceneLoaded: false,
		forests: {
			command: 'pointsOfInterest',
			POIs: [],
		},
		polygon_data: [],
		modal: {
			open: false,
			showForestMedia: true,
			showBaseLineReports: false,
			showAboutPage: false,
			selected_report_url: '',
			folder: '',
		},
		isViewerReady: false,
		mykey: '0.25395060863060026layeroverlay',
		displayWeather: false,
		displayJoystick: false,
		forestLiveWeather: {},
		pathCoordinates: [],
		video_duration: 30,
		sponsor_data: {
			cubes_data_original: [],
			cubes_data_in_percentages: [],
			cubes_data_present: [],
			unique_identity: '',
		},
	});
	const [sponsor, setSponsor] = useState(initialSponsorState);
	const [isLoading, setIsLoading] = useState(false);
	const [isPurchaseLoader, setIsPurchaseLoader] = useState({
		value: false,
		item: '',
	});
	const [isPurchaseError, setIsPurchaseError] = useState({
		status: false,
		message: '',
	});
	const stateRef = useRef(commonState);
	const { clientType, listOfAccesibleForest, clientId, clientName, emailId } =
		props.loginedInUserData?.data?.getLoggedInUserData || {};
	const updateCommonState = (updates) => {
		setCommonState((prevState) => ({
			...prevState,
			...updates,
		}));
	};

	//+++++++++++++++++++++++++++++USEEFFECT def++++++++++++++++++++++++++++++++++//
	useEffect(() => {
		handle_update_sponsor_colors(sponsor.data?.numberOfCubes);
	}, [sponsor.data?.numberOfCubes]);

	useEffect(() => {
		const onViewerReady = (event) => {
			updateCommonState({
				isViewerReady: event.detail.isReady,
			});
		};
		window.addEventListener('myVRStudioReady', onViewerReady);
		return () =>
			window.removeEventListener('myVRStudioReady', onViewerReady);
	}, []);
	useEffect(() => {
		stateRef.current = commonState;
	}, [commonState]);
	useEffect(() => {
		let ignore = false;
		const loadData = async () => {
			// Step 1: For visulization client we need to add filter to get main forest data
			let filter_variables = {};
			if (clientType === 'Visualization') {
				const forestNameFilter = listOfAccesibleForest.map(
					(forestName) => ({
						ForestName: { eq: forestName },
					})
				);
				filter_variables = { or: forestNameFilter };
			}

			//Step2 : Get main list forest data as per the filter
			const forest_list = await makeGraphQLRequest({
				query: queries.listForestMainTables,
				variables: { filter: filter_variables },
			});
			const forest_items_list =
				forest_list?.data?.listForestMainTables?.items || [];

			// Step3 : get polygon list and we dont need filter here..so get all
			const polygon_list = await makeGraphQLRequest({
				query: queries.listForestsData,
				variables: { filter: {} },
			});
			const polygon_items_list =
				polygon_list?.data?.listForestsData?.items || [];

			// Step 4: get image url's from storage for both forest and polygons
			const forest_result = await get_image_storage_urls(
				forest_items_list,
				[
					'icon',
					'about_project_pdf_url',
					'project_icon_path',
					'project_map_icon',
				]
			);
			const polygon_result = await get_image_storage_urls(
				polygon_items_list,
				[
					'area_MYVR_scene_file_path',
					'area_WEBGL_cubified_file_path',
					'baseline_report_path',
				]
			);

			if (!ignore) {
				//Step 5: Addtional step for main forest data to convert data in to Poi's required format
				const forest_globe_data = generateGlobeData(forest_result);

				updateCommonState({
					forests: forest_globe_data,
					polygon_data: polygon_result,
					isLoading: true,
				});

				props.setAppLoadingIndicator(!commonState.sceneLoaded);
			}
		};

		loadData();

		return () => (ignore = true);
	}, []);
	useEffect(() => {
		if (commonState.isViewerReady) {
			myVRStudio.registerRenderCallback(processCallback);
		}
	}, [commonState.isViewerReady]);
	useEffect(() => {
		const { currentPage, page, sceneLoaded, selectedPolygon } = commonState;

		console.log('**************', currentPage, page);
		if (
			currentPage === 'Globe' &&
			(page === 0 || page === 1) &&
			!appLoadingIndicator &&
			sceneLoaded == true
		) {
			myVRStudio.executeCommand(commonState.forests);
		} else if (
			currentPage === 'Globe' &&
			page === 2 &&
			!appLoadingIndicator &&
			sceneLoaded == true
		) {
			myVRStudio.executeCommand(commonState.forests);
			const move_to_polygons = {
				command: 'action',
				type: 'GoToLonLat',
				lat: commonState.selectedForest.lat,
				lon: commonState.selectedForest.lon,
				animate: true,
			};
			myVRStudio.executeCommand(move_to_polygons);
		} else if (
			currentPage === 'sponsor' &&
			page === 3 &&
			!appLoadingIndicator &&
			sceneLoaded == true
		) {
			const addColorLayerCommand = {
				command: 'createPlugin',
				type: 'layerOverlayPlugin',
				srcLayer: 50,
				cmdId: commonState.selectedPolygon.id,
				areas: commonState.sponsor_data.cubes_data_present,
				names: [
					'Available Units',
					'Sponsored Units',
					'Unavailable Units',
				],
			};

			const sampletest = {
				command: 'createPlugin',
				type: 'LayerOverlayPlugin',
				srcLayer: 50,
				areas: [0.25, 0.25, 0.5],
				names: ['Available', 'Sponsored', 'Unavailable'],
			};

			myVRStudio.executeCommand(addColorLayerCommand);
		}
	}, [appLoadingIndicator, commonState.sceneLoaded]);

	useEffect(() => {
		handlePageActionsOnGlobe();
	}, [commonState.page]);

	useEffect(() => {
		if (!isEmptyObject(commonState.selectedForest)) {
			const { currentPage, page, selectedForest } = commonState;

			let move_to_country = {
				command: 'action',
				type: 'GoToLonLat',
				lat: commonState.selectedForest.lat,
				lon: commonState.selectedForest.lon,
				animate: true,
			};
			if (page === 0 || page === 1) {
				move_to_country['alt'] = 14425623.822234;
			}
			myVRStudio.executeCommand(move_to_country);
		}
	}, [commonState.selectedForest]);

	//+++++++++++++++++++++++++++++Actions def++++++++++++++++++++++++++++++++++//
	const handlecallbackActions = (
		actionType,
		actionObject = {},
		pageNumber = 0
	) => {
		console.log(
			'*********action>>>>>',
			actionType,
			commonState.page,
			pageNumber,
			commonState.currentPage
		);
		if (actionType === 'handleForestSelection') {
			updateCommonState({
				selectedForest: actionObject,
				page: pageNumber,
			});
		} else if (actionType === 'setPage') {
			if (
				((commonState.page === 2 && pageNumber === 3) ||
					(commonState.page === 3 && pageNumber === 2)) &&
				commonState.currentPage != 'Globe'
			) {
				updateCommonState({
					page: pageNumber,
					displayScene: global_globe_scene,
					displayJoystick: false,
					currentPage: 'Globe',
					mykey: Math.random() + 'layeroverlay',
				});
				props.setAppLoadingIndicator(true);
			} else {
				let update_state = {};
				update_state['page'] = pageNumber;
				if (pageNumber === 0) {
					update_state['selectedForest'] = {};
				}
				updateCommonState(update_state);
			}
			setshowAboutForest(false);
		} else if (actionType === 'setPolygonAndPage') {
			updateCommonState({
				pathCoordinates: actionObject['coordinates'],
				selectedPolygon: actionObject['polygon'],
				sponsor_data: actionObject,
				page: pageNumber,
			});
		} else if (actionType === 'handlePolygonSelection') {
			handle_polygon_selection_byglobeAndNavigation(
				actionObject,
				pageNumber
			);
		} else if (actionType === 'setPageAndExplore') {
			updateCommonState({
				page: pageNumber,
			});
			setshowAboutForest(false);
		} else if (actionType === 'setPageAndShowPOIs') {
			updateCommonState({
				page: 0,
				selectedPolygon: {},
				selectedForest: {},
				currentPage: 'Globe',
				mykey: Math.random() + 'layeroverlay',
				displayScene: global_globe_scene,
				displayJoystick: false,
			});
			props.setAppLoadingIndicator(true);
		} else if (actionType === 'showForestMedia') {
			updateCommonState({
				modal: {
					open: true,
					showForestMedia: true,
					showBaseLineReports: false,
					showAboutPage: false,
					selected_report_url: '',
					folder: actionObject.filterFolder
						? actionObject.filterFolder
						: '',
				},
			});
		} else if (actionType === 'showForestBaseline') {
			let selected_base_line_url = '';
			if (actionObject.baseline_report_path) {
				selected_base_line_url = actionObject.baseline_report_path;
			} else {
				selected_base_line_url =
					commonState.selectedPolygon.baseline_report_path;
			}
			updateCommonState({
				modal: {
					open: true,
					showForestMedia: false,
					showBaseLineReports: true,
					showAboutPage: false,
					selected_report_url: selected_base_line_url,
					folder: '',
				},
			});
		} else if (actionType === 'showAboutForest') {
			setshowAboutForest(true);
		} else if (actionType === 'showExperience') {
			handle_experience_click();
		} else if (actionType === 'showSponsor') {
			handle_sponsor_click();
		}
	};
	const handle_polygon_selection_byglobeAndNavigation = async (
		polygon,
		pageNumber
	) => {
		try {
			setisPage3DataRetriving(true);
			const { clientType, clientId } =
				props.loginedInUserData?.data?.getLoggedInUserData || {};

			const responseObject = await makeGraphQLRequest({
				query: queries.getPolygonCompletePurchaseData,
				variables: {
					poly_id: polygon?.id,
					user_client_id: clientId,
				},
			});
			const percentages_data =
				responseObject.data?.getPolygonCompletePurchaseData;
			if (percentages_data) {
				const {
					cubes_already_inpercentage,
					cubes_already_sold,
					cubes_available,
					cubes_available_inpercentage,
					cubes_notavailable,
					cubes_notavailable_inpercentage,
				} = percentages_data;
				//names" : [ "Available", "Sponsored", "Unavailable" ]
				const cubes_data = [
					cubes_available,
					cubes_already_sold,
					cubes_notavailable,
				];
				const cubes_data_percentages = [
					parseFloat(cubes_available_inpercentage) / 100,
					parseFloat(cubes_already_inpercentage) / 100,
					parseFloat(cubes_notavailable_inpercentage) / 100,
				];
				let unique_identity_number = generateRandomString(10);

				let sponsor_data_calculated = {
					cubes_data_original: cubes_data,
					cubes_data_in_percentages: cubes_data_percentages,
					cubes_data_present: cubes_data_percentages,
					unique_identity: unique_identity_number,
				};

				const polygon_data_response = await fetch(polygon_def_json);
				if (!polygon_data_response.ok) {
					throw new Error(
						`HTTP error! status: ${polygon_data_response.status}`
					);
				}
				const jsonData = await polygon_data_response.json();
				const polygon_border_coordinates = jsonData['features'].find(
					(polygonItem) => polygonItem?.id === polygon?.polygon_name
				);

				setisPage3DataRetriving(false);

				updateCommonState({
					pathCoordinates:
						polygon_border_coordinates?.geometry?.coordinates || [],
					selectedPolygon: polygon,
					sponsor_data: sponsor_data_calculated,
					page: pageNumber,
				});

				// handlecallbackActions(
				// 	'setPolygonAndPage',
				// 	{
				// 		...sponsor_data_calculated,
				// 		polygon,
				// 		coordinates:
				// 			polygon_border_coordinates?.geometry?.coordinates ||
				// 			[],
				// 	},
				// 	pageNumber
				// );
			}
		} catch (error) {
			setisPage3DataRetriving(false);
			console.log(error);
		}
	};
	const handlePageActionsOnGlobe = () => {
		const { currentPage, page, sceneLoaded, selectedForest } = commonState;
		if (
			currentPage === 'Globe' &&
			page === 0 &&
			!appLoadingIndicator &&
			sceneLoaded == true
		) {
			const show_pois = {
				command: 'action',
				type: 'GoTo',
				mode: 'globe',
				eye: [
					740493.8131714794, -20571348.187571786, 3008181.482028146,
				],
				target: [
					740493.7680708593, -20571347.192886803, 3008181.3894661637,
				],
				up: [
					-0.020463284803554416, 0.09171699360013008,
					0.9955748822540045,
				],
			};
			myVRStudio.executeCommand(show_pois);
		} else if (
			currentPage === 'Globe' &&
			(page === 1 || page === 2) &&
			!appLoadingIndicator &&
			sceneLoaded == true
		) {
			let show_forest_polygons = {
				command: 'action',
				type: 'GoToLonLat',
				lat: commonState.selectedForest.lat,
				lon: commonState.selectedForest.lon,
				animate: true,
			};
			if (page === 1) {
				show_forest_polygons['alt'] = 14425623.822234;
			}
			myVRStudio.executeCommand(show_forest_polygons);
		}
	};
	const handle_sponsor_click = async () => {
		try {
			const sponsor_data = commonState.sponsor_data;
			if (sponsor_data) {
				const geo_json_forID_glb =
					stateRef.current.selectedPolygon
						.area_WEBGL_cubified_file_path;
				console.log(
					'selected polygon could be undefined because polygon ID not mapped in geojson data yet if coming from globe directly',
					geo_json_forID_glb
				);
				if (geo_json_forID_glb) {
					// This check tell us if the url is already a https complete url or only just property values from dynamodb
					const regex = /^(https?:\/\/)/;
					let storage_url_glb = '';
					if (regex.test(geo_json_forID_glb)) {
						storage_url_glb = geo_json_forID_glb;
					} else {
						storage_url_glb = await Storage.get(
							geo_json_forID_glb,
							{
								download: false,
							}
						);
					}
					// below logic will be only there for sponsor because layer insertion not handled by MYVR TEAM just like as they did for sponsor
					//we have to remove this logic and directly pass storage_url_glb to the state
					const split_url = storage_url_glb.split('glb');
					const result_url = split_url[0] + 'glb';
					updateCommonState({
						currentPage: 'sponsor',
						mykey: Math.random() + 'layeroverlay',
						displayJoystick: false,
						displayScene: `layer=${result_url}`,
						sponsor_data: sponsor_data,
					});
				} else {
					updateCommonState({
						// pathCoordinates: [],
						currentPage: 'sponsor',
						mykey: Math.random() + 'layeroverlay',
						displayJoystick: false,
						displayScene: `layer=${'https://desolinator.s3.eu-north-1.amazonaws.com/GreenCubes-Cubified/finca_recentered.glb'}`,
						sponsor_data: sponsor_data,
					});
				}
				props.setAppLoadingIndicator(true);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handle_update_sponsor_colors = (value) => {
		let total_cubes = Number(commonState.selectedPolygon.NoOfCubes);
		let present_state = commonState.sponsor_data.cubes_data_original;
		let int_array = present_state.map(Number);
		const updated_array = [
			int_array[0] - Number(value),
			int_array[1] + Number(value),
			int_array[2],
		];

		let updated_percentage = [
			updated_array[0] / total_cubes,
			updated_array[1] / total_cubes,
			updated_array[2] / total_cubes,
		];
		console.log('updated ', int_array, updated_array);
		console.log(
			'old>>>>>>>',
			commonState.sponsor_data.cubes_data_in_percentages
		);
		console.log('update>>>>>>>>>>>>', updated_percentage);
		const mycommand = {
			command: 'updateOverlays',
			plugin: 1,
			areas: updated_percentage,
		};
		myVRStudio.executeCommand(mycommand);
	};
	const handle_experience_click = async () => {
		const unique_identity_number = generateRandomString(10);
		const geo_json_forID =
			stateRef.current.selectedPolygon.area_MYVR_scene_file_path;

		console.log(
			'selected polygon could be undefined because polygon ID not mapped in geojson data yet if coming from globe directly',
			geo_json_forID
		);

		if (geo_json_forID) {
			// This check tell us if the url is already a https complete url or only just property values from dynamodb
			const regex = /^(https?:\/\/)/;
			let storage_url = '';
			if (regex.test(geo_json_forID)) {
				storage_url = geo_json_forID;
			} else {
				storage_url = await Storage.get(geo_json_forID, {
					download: false,
				});
			}

			const parsed_json = await get_and_process_geoJson(storage_url);
			const camera_object = parsed_json.find(
				(item) => item['command'] === 'cameraState'
			);
			const video_duration =
				camera_object && camera_object['cameraAnimations']
					? camera_object['cameraAnimations'][0]['time']
					: 20000;

			updateCommonState({
				// 	pathCoordinates: [],
				video_duration: video_duration,
				mykey: unique_identity_number,
				currentPage: 'Visulization',
				displayJoystick: true,
				displayScene: `scene=${storage_url}`,
			});
			props.setAppLoadingIndicator(true);
		} else {
			//we need to remove this in future once we have polygonID mapped in geOJSON, BELOW is hard coded url
			updateCommonState({
				// pathCoordinates: [],
				video_duration: 30,
				mykey: unique_identity_number,
				currentPage: 'Visulization',
				displayJoystick: true,
				displayScene: `scene=${'https://desolinator.s3.eu-north-1.amazonaws.com/greenCubes-FincaAmable-latest.json'}`,
			});
		}
		setSponsor({
			...sponsor,
			...initialSponsorState,
		});
	};
	const handle_aboutProject_close_click = (value) => {
		// props.setAppLoadingIndicator(true);
		setshowAboutForest(value);
	};
	const displayStick = () => {
		if (commonState.displayJoystick) {
			return (
				<div className="animate-fadeIn rounded-lg flex flex-1 overflow-hidden flex-col absolute bottom-10 right-24 bg-[#262626] w-96 ">
					<div class="flex items-center justify-center h-32 bg-gray-200 rounded-t-lg pt-2">
						<Joystick
							size={100}
							stickSize={40}
							sticky={false}
							baseColor="#A7DB49"
							stickColor="#fff"
							move={handleJoyStick}
							stop={handleJoyStick}></Joystick>
					</div>
					<MyVRVideoPlayer
						duration={30}
						onPlay={handle_player_play}
						onStop={handle_player_stop}
					/>
					<div className="rounded-b-lg overflow-hidden">
						<MYVRMap
							pathCoordinates={commonState.pathCoordinates}
							selectedPolygon={commonState.selectedPolygon}
							selectedForest={commonState.selectedForest}
							isvideo_Playing={isvideoPlaying}
							animation_duration={30}
						/>
					</div>
				</div>
			);
		}
	};
	const handleJoyStick = (event) => {
		// "FORWARD" | "RIGHT" | "LEFT" | "BACKWARD"

		// W Key event
		var w_event = new KeyboardEvent('keydown', {
			key: 'w',
			code: 'KeyW',
			keyCode: 87,
			charCode: 0,
			bubbles: true,
			cancelable: true,
		});

		// A key event
		var a_event = new KeyboardEvent('keydown', {
			key: 'a',
			code: 'KeyA',
			keyCode: 65,
			charCode: 0,
			bubbles: true,
			cancelable: true,
		});

		// S key event
		var s_event = new KeyboardEvent('keydown', {
			key: 's',
			code: 'KeyS',
			keyCode: 83,
			charCode: 0,
			bubbles: true,
			cancelable: true,
		});

		// D key event
		var d_event = new KeyboardEvent('keydown', {
			key: 'd',
			code: 'KeyD',
			keyCode: 68,
			charCode: 0,
			bubbles: true,
			cancelable: true,
		});

		const element = document.getElementById('myvr-studio-viewer').children;
		if (event.type == 'move' && event.distance > 90 && event.distance < 100)
			console.log(event.type, event.direction, event.distance);
		if (event.direction == 'FORWARD') {
			element[0].dispatchEvent(w_event);
		} else if (event.direction == 'RIGHT') {
			element[0].dispatchEvent(d_event);
		} else if (event.direction == 'LEFT') {
			element[0].dispatchEvent(a_event);
		} else if (event.direction == 'BACKWARD') {
			element[0].dispatchEvent(s_event);
		}
	};
	const handle_player_play = () => {
		const action = {
			command: 'action',
			path: 'Finca-walk',
			pathAction: 'Play',
			type: 'CameraPath',
		};
		setisvideoPlaying(true);
		myVRStudio.executeCommand(action);
	};
	const handle_player_stop = () => {
		const action = {
			command: 'action',
			path: 'Finca-walk',
			pathAction: 'Stop',
			type: 'CameraPath',
		};
		setisvideoPlaying(false);
		myVRStudio.executeCommand(action);
	};
	const handleSponsorCalSubmit = () => {
		setSponsor({
			...sponsor,
			step: 1,
		});
	};
	// const handleRequestAgent = () => {
	// 	setSponsor({
	// 		...sponsor,
	// 		step: 3,
	// 	});
	// };
	const handlePurchaseRequest = async () => {
		try {
			setIsPurchaseLoader({
				value: true,
				item: 'purchase',
			});
			const purchaseResult = await makeGraphQLRequest({
				query: queries.validate_purchase_order,
				variables: {
					poly_id: commonState.selectedPolygon.id,
					user_client_id: clientId,
					currentOrderCubes: sponsor?.data?.numberOfCubes,
				},
			});

			if (purchaseResult) {
				const { ForestName, ForestID, polygon_name, id, CubePrice } =
					commonState?.selectedPolygon;
				const {
					data: { numberOfCubes },
				} = sponsor;
				const invoiceCost = Number(numberOfCubes) * Number(CubePrice);
				const data = {
					purchasedClientName: clientName,
					purchasedClientID: clientId,
					purchaseddBy: emailId,
					purchasedForestName: ForestName,
					purchasedForestID: ForestID,
					purchasedForestArea: polygon_name,
					purchasedForestAreaID: id,
					purchasedNoOfCubes: String(numberOfCubes),
					purchasedCubePrice: CubePrice,
					purchasedInvoiceCost: invoiceCost,
					purchaseStatus: 'New',
					purchaseComments: '',
					purchasedInvoicePath: '',
					purchasedInvoiceNumber: '',
					purchasedOn: new Date().toLocaleDateString(),
					audit_client_msg: `${clientName}@@${emailId}`,
				};
				const response = await makeGraphQLRequest({
					query: mutations.createPurchaseOrders,
					variables: { input: data },
				});
				setSponsor({
					...sponsor,
					step: 5,
				});
			}
		} catch (error) {
			let errorMsg = error.message;
			if (!errorMsg) {
				errorMsg = error?.errors?.[0]?.message;
			}
			setIsPurchaseError({
				status: true,
				message: errorMsg,
			});
		} finally {
			setIsPurchaseLoader({});
		}
	};
	const handlePurchaseCancel = () => {
		setIsPurchaseError({});
		setSponsor({
			...sponsor,
			...initialSponsorState,
		});
	};
	const createSupportTicketForAgent = async (payload) => {
		try {
			setIsPurchaseLoader({
				value: true,
				item: 'contact',
			});
			const { ForestID } = commonState?.selectedPolygon;
			const { emailId, phoneNumber } = payload;
			const data = {
				ticketCreatedBy: emailId,
				ticketCreatedOn: new Date().toLocaleDateString(),
				tickerCreatedClientName: clientName,
				ticketMainForestName: ForestID,
				ticketComments: '',
				ticketRefNumber: generateRandomString(8),
				ticketStatus: 'New',
				contactEmail: emailId,
				contactPhone: phoneNumber,
				meetingDateAndTime: '',
				audit_client_msg: `${clientName}@@${emailId}`,
			};

			const response = await makeGraphQLRequest({
				query: mutations.createSupportTicketsTable,
				variables: { input: data },
			});
			setSponsor({
				...sponsor,
				step: 4,
			});
		} catch (error) {
			let errorMsg = error.message;
			if (!errorMsg) {
				errorMsg = error?.errors?.[0]?.message;
			}
			setIsPurchaseError({
				status: true,
				message: errorMsg,
			});
		} finally {
			setIsPurchaseLoader({});
		}
	};

	//percentages, cubes_data
	const display_piechart = () => {
		return (
			<div className="animate-fadeIn flex w-full h-full">
				<div class="absolute top-20 right-0">
					<PieChart
						cubes_data={
							commonState.sponsor_data.cubes_data_original
						}
						numberOfCubes={sponsor.data.numberOfCubes}
					/>
				</div>
			</div>
		);
	};
	const sponsorComponents = {
		0: (
			<PurchaseCalculator
				data={sponsor.data}
				onSubmit={handleSponsorCalSubmit}
				onCancel={handlePurchaseCancel}
				maxSizeInSqm={Number(commonState.selectedPolygon?.NoOfCubes)}
				onDataChange={(property, value) => {
					setSponsor({
						...sponsor,
						data: {
							...sponsor.data,
							[property]: value,
						},
					});
				}}
			/>
		),
		1: (
			<PurchaseCalculatorConfirm
				data={sponsor.data}
				onCancel={handlePurchaseCancel}
				onSubmit={(numberOfCubes) => {
					setSponsor({
						...sponsor,
						data: {
							...sponsor.data,
							numberOfCubes,
						},
						show: false,
					});
				}}
			/>
		),
		2: (
			<ShoppingCart
				selectedPolygon={commonState.selectedPolygon}
				data={sponsor.data}
				isLoading={isLoading}
				onCancel={handlePurchaseCancel}
				onSubmit={() =>
					setSponsor({
						...sponsor,
						step: 3,
					})
				}
				isPurchaseError={isPurchaseError}
			/>
		),
		3: (
			<Checkout
				data={sponsor.data}
				onCancel={handlePurchaseCancel}
				onSubmit={handlePurchaseRequest}
				isLoading={isPurchaseLoader}
				onRequestAgent={createSupportTicketForAgent}
			/>
		),
		4: <ContactRequest onSubmit={handlePurchaseCancel} />,
		5: (
			<PurchaseConfirm
				selectedForest={commonState.selectedForest}
				selectedPolygon={commonState.selectedPolygon}
				onSubmit={handlePurchaseCancel}
				data={sponsor.data}
			/>
		),
	};

	//+++++++++++++++++++++++++++++MYVR Callback functions def++++++++++++++++++++++++++++++++++//
	const processCallback = (data) => {
		const info = JSON.parse(data.data);
		console.log('MY INFO', info);

		if (info?.action === 'sceneLoaded') {
			updateCommonState({
				sceneLoaded: true,
			});
			props.setAppLoadingIndicator(false);
		} else if (info?.action === 'elementClicked') {
			const selected_id = info['hit-records']?.[0]?.hits?.[0]?.id;
			const selected_id_Layer = info['hit-records']?.[0]?.layer;
			const uniqueKey = Math.random() + 'layeroverlay';
			if (selected_id_Layer === 6) {
				const forest_obj = stateRef.current.forests['POIs'].find(
					(item) => item['id'] == selected_id
				);
				if (forest_obj) {
					updateCommonState({
						selectedForest: forest_obj,
						page: 2,
					});
				}
			} else if (selected_id_Layer === 50) {
				const selected_poly = stateRef.current.polygon_data.find(
					(item) => item['polygon_name'] == selected_id
				);
				if (selected_poly) {
					console.log(
						'poly got &&&&&&&&&&&&&&&&&&&&&&&&&&&&&',
						selected_poly
					);
					handle_polygon_selection_byglobeAndNavigation(
						selected_poly,
						3
					);
				}
			}
		}
	};

	///=======================Helper functions Starts ===================================//
	function isEmptyObject(obj) {
		return Object.keys(obj).length === 0;
	}
	function generateRandomPercentages() {
		let num1 = Math.random() * 100;
		let num2 = Math.random() * (100 - num1);
		let num3 = 100 - num1 - num2;
		return [num1, num2, num3];
	}
	const get_and_process_geoJson = async (geo_json_url) => {
		try {
			const response = await fetch(geo_json_url);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const result = await response.json();
			return result;
		} catch (error) {
			console.log('error in fetching JSON', error);
		}
	};
	const get_image_storage_urls = async (listOfObjects, columns = []) => {
		const updated_storage_url_objects = await Promise.all(
			listOfObjects.map(async (obj) => {
				const updatedObj = { ...obj };
				try {
					for (const col of columns) {
						updatedObj[col] = await Storage.get(obj[col]);
					}
				} catch (error) {
					console.error('**********', error);
					for (const col of columns) {
						updatedObj[col] = undefined;
					}
				}
				return updatedObj;
			})
		);
		return updated_storage_url_objects;
	};
	const display_modal = () => {
		return (
			<MYVRModal
				open={commonState.modal.open}
				isloading={false}
				error={false}
				onCancel={() => {
					updateCommonState({
						modal: {
							open: false,
							showForestMedia: false,
							showBaseLineReports: false,
							showAboutPage: false,
							selected_report_url: '',
							folder: '',
						},
					});
				}}
				title={commonState.modal.showForestMedia ? 'Media' : 'Reports'}
				removePadding
				maxWidth={commonState.modal.showForestMedia ? 'lg' : 'xl'}>
				{display_modal_content()}
			</MYVRModal>
		);
	};
	const display_modal_content = () => {
		if (commonState.modal.showForestMedia) {
			return (
				<VideoPlayer
					video_items={[]}
					selectedForest={commonState.selectedForest}
					folderName={
						commonState.modal.folder ? commonState.modal.folder : ''
					}
				/>
			);
		} else if (commonState.modal.showBaseLineReports) {
			return <PDFViewer url={commonState.modal.selected_report_url} />;
		}
	};
	///=======================Helper functions ends ===================================//

	return (
		<div className="flex h-screen overflow-hidden relative">
			{commonState.isLoading && (
				<>
					<div className="animate-fadeIn flex shrink-0 grow-0 h-full w-96">
						<PolygonComponent
							forests={commonState.forests['POIs']}
							isPage3DataRetriving={isPage3DataRetriving}
							page={commonState.page}
							pages={pages}
							handlecallbackActions={handlecallbackActions}
							currentPage={commonState.currentPage}
							polygon_list={commonState.polygon_data}
							selectedPolygon={commonState.selectedPolygon}
							selectedForest={commonState.selectedForest}
							onSponsorCal={() => {
								setSponsor({
									...sponsor,
									step: 0,
									show: true,
								});
								const resizeEvent = new Event('resize');
								setTimeout(() => {
									window.dispatchEvent(resizeEvent);
								}, 0);
							}}
							onAboutClose={() => setshowAboutForest(false)}
						/>
					</div>
					<div className="flex flex-col overflow-hidden w-full">
						<div
							id="myvr-studio-viewer"
							className="animate-fadeIn w-full h-full max-h-full relative overflow-hidden">
							<myVRStudio.Viewer
								viewerContainerId={'myvr-studio-viewer'}
								options={commonState.displayScene}
								key={commonState.mykey}
							/>
							{commonState.currentPage === 'sponsor'
								? display_piechart()
								: null}
							<MyVRWidget
								selectedForest={commonState.selectedForest}
								displayWeather={commonState.displayWeather}
								displayJoystick={commonState.displayJoystick}
							/>
							{sponsor.show && sponsorComponents[sponsor.step]}
							{displayStick()}
							{display_modal()}
							{showAboutForest && (
								<div className="animate-fadeIn absolute top-0 left-0 flex w-full h-full">
									<button
										class="absolute top-5 right-5 text-black"
										onClick={() => {
											handle_aboutProject_close_click(
												false
											);
										}}>
										<span class="inline-block border border-black pr-10 pl-10 pt-1 pb-1 bg-[#fff] rounded-full text-black">
											Close
										</span>
									</button>
									<AboutForestPage
										htmlFilePath={
											commonState.selectedForest
												.about_project_pdf_url
										}
									/>
								</div>
							)}
						</div>
						{commonState.currentPage === 'sponsor' && (
							<MyVRBottom
								onSubmit={() => {
									setSponsor({
										...sponsor,
										show: true,
										step: 2,
									});
								}}
								value={sponsor.data?.numberOfCubes}
								submitDisabled={sponsor.step !== 1}
								inputDisabled={sponsor.step > 1}
								maxValue={Number(
									commonState.sponsor_data
										.cubes_data_original?.[0]
								)}
								onChange={(value) => {
									setSponsor({
										...sponsor,
										data: {
											...sponsor.data,
											numberOfCubes: value,
										},
									});
								}}
							/>
						)}
					</div>
				</>
			)}
			{!commonState.isLoading && (
				<div className="flex flex-col overflow-hidden w-full">
					<div className="flex flex-col w-full h-full max-h-full items-center justify-center bg-[#262626]">
						<Riple
							color="#0cc50c"
							size="large"
							text="Loading"
							textColor="#0deb30"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	errorStatus: state.errorStatus,
	loginedInUserData: state.loginedInUserData,
	appLoadingIndicator: state.appLoadingIndicator,
});

{
	/* <Commet color="#0cc50c" size="medium" text="Loading" textColor="#0deb30" />
<Mosaic color="#0cc50c" size="medium" text="Loading" textColor="#0deb30" />
<Atom color="#0cc50c" size="medium" text="Loading" textColor="#0deb30" />
<ThreeDot color="#0cc50c" size="medium" text="Loading" textColor="#0deb30" />
<BlinkBlur color="#0cc50c" size="medium" text="Loading" textColor="#0deb30" />
<FourSquare color="#0cc50c" size="medium" text="Loading" textColor="#0deb30" />
<LifeLine color="#0cc50c" size="medium" text="Loading" textColor="#0deb30" /> 
<Riple color="#0cc50c" size="large" text="Loading" textColor="#0deb30" />
 <Slab color="#0cc50c" size="medium" text="Loading" textColor="#0deb30" />
<TrophySpin color="#0cc50c" size="medium" text="Loading" textColor="#0deb30" /> */
}
{
	/* <div className={`flex flex-col overflow-hidden w-[78%]  ${commonState.isLoading ? 'block' : 'hidden'}`}> */
}

export default connect(mapStateToProps, {
	setAppLoadingIndicator,
})(MyvrViewer);
