const errorTextAction = (errorMsg) => {
	return {
		type: 'setError',
		payload: errorMsg,
	};
};

const loggedInUserData = (data) => {
	return {
		type: 'login',
		payload: data,
	};
};

const setClientAndRoles = (payload) => {
	return {
		type: 'SET_CLIENT_AND_ROLES',
		payload,
	};
};

const showLoadingIndicator = (flag) => {
	return {
		type: 'show_loading_indicator',
		payload: flag,
	};
};

const setLandingPageStatus = () => {
	return {
		type: 'LANDING_PAGE_VIEWED',
	};
};

const setAppLoadingIndicator = (payload) => {
	return {
		type: 'APP_LOADING_INDICATOR',
		payload,
	};
};

const setSessionExpired = (payload) => {
	return {
		type: 'SESSION_EXPIRED',
		payload,
	};
};

// const getData = () => {
//     return (dispatch,state) => {
//     const currentState = state();
//       fetch('https://x0aet44db9.execute-api.eu-north-1.amazonaws.com/Stage/greencubes',{
//         method: 'GET',
//       })
//         .then(response => {
//           console.log("******************** success");
//           console.log(response.json().then);
//           console.log("******************** done");
//         })
//         .catch(error => {
//             console.log("******************** error");
//           console.log(error.message);
//         });
//     };
//   };

function getData() {
	return (dispatch, state) => {
		const currentState = state();

		fetch(
			'https://x0aet44db9.execute-api.eu-north-1.amazonaws.com/Stage/greencubes',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		)
			.then(function (res) {
				res.json()
					.then(function (data) {
						console.log(data);
					})
					.catch(function (error) {
						console.log('Data failed', error);
					});
			})
			.catch(function (error) {
				console.log('request failed', error);
			});
	};
}

export {
	errorTextAction,
	loggedInUserData,
	getData,
	showLoadingIndicator,
	setLandingPageStatus,
	setClientAndRoles,
	setAppLoadingIndicator,
	setSessionExpired,
};
