import React, { useEffect, useState, useRef } from 'react';
import MYVRHorizontalDiv from './MYVRHorizontalDiv';
import ImageLoader from '../../../../src/components/base/ImageLoader';
import { ThreeDot } from 'react-loading-indicators';
import awsconfig from '../../../aws-exports';

const MyVRForestImages = ({ imagesList, imagesCount }) => {
	const [images_data, setImageData] = useState({
		isloading: true,
		images_list: [],
		images_count: 0,
	});
	useEffect(() => {
		getPublicImages();
	}, []);

	const getPublicImages = async () => {
		try {
			// desolinator public bucket is working as expected but for new public greencubes bucket, we have to add eu-north-1 in the url to get images else we are getting cors issue

			// const bucketName = 'desolinator';
			// const folderPath = 'Greencubes_Public_Data/GreenCubesPublicImages/'; // Full path to the specific folder

			const bucketName = 'greencubes-public-bucket';
			const folderPath = 'GreencCubes_Public_Images_folder/';

			// const url = `https://${bucketName}.s3.eu-north-1.amazonaws.com/?list-type=2&prefix=${folderPath}`;
			const region = awsconfig.aws_project_region;
			console.log('AWS Region:', region);
			const url = `https://${bucketName}.s3.${region}.amazonaws.com/?list-type=2&prefix=${folderPath}`;

			const response = await fetch(url, {
				method: 'GET',
				mode: 'cors',
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const text = await response.text();
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(text, 'application/xml');
			const contents = Array.from(
				xmlDoc.getElementsByTagName('Contents')
			);
			const files = contents
				.map((content) => {
					const key =
						content.getElementsByTagName('Key')[0].textContent;
					if (!key.endsWith('/')) {
						return {
							key,
							url: `https://${bucketName}.s3.amazonaws.com/${key}`,
						};
					}
					return null;
				})
				.filter((file) => file !== null); // Remove any null entries

			console.log('****************** files:', files);
			console.log('****************************', files.length);

			let images_array = [];
			let images_count = files.length;

			for (let i = 0; i < files.length; i++) {
				let image_item = files[i];
				images_array.push(
					<div
						className="w-full flex-none h-80 flex items-center justify-center bg-[#fff]"
						key={image_item.url}>
						<ImageLoader
							src={image_item.url}
							alt="Forest Capture"
						/>
					</div>
				);
			}

			console.log('Print>>>', images_array);

			const final_object = {
				isloading: false,
				images_list: images_array,
				images_count: images_count,
			};
			setImageData(final_object);
		} catch (error) {
			console.error(
				'Error listing files from GreenCubesPublicImages:',
				error
			);
		}
	};

	return (
		<div className="flex flex-col w-full h-80 group bg-[#fff]">
			{images_data.isloading && (
				<div className="flex justify-center items-center mt-40">
					<ThreeDot
						color={'#32cd32'}
						size="medium"
					/>
				</div>
			)}
			{!images_data.isloading && (
				<div className="flex text-white w-full h-full">
					<MYVRHorizontalDiv totalpages={images_data.images_count}>
						{images_data.images_list}
					</MYVRHorizontalDiv>
				</div>
			)}
		</div>
	);
};

export default MyVRForestImages;

{
	/* <div className="w-full flex-none h-80 flex items-center justify-center">
                        <img
                            src={ForestSceneImage}
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="w-full flex-none h-80 bg-green-300 flex items-center justify-center">
                        <img
                            src={Sample1}
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="w-full flex-none h-80 bg-blue-300 flex items-center justify-center">
                        <img
                            src={Sample2}
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="w-full flex-none h-80 bg-blue-300 flex items-center justify-center">
                        <img
                            src={Sample3}
                            className="w-full h-full object-cover"
                        />
                    </div> */
}
