import React, { useCallback } from 'react';
import { Link, Text } from '@digitalreality/ui';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

import {
	errorTextAction,
	loggedInUserData,
	showLoadingIndicator,
} from '../../Pages/ReduxStore/Actions/CommonActions';
import { switchCurrentLoginSection } from '../../Pages/ReduxStore/Actions/LoginPageActions';
import * as queries from '../../graphql/queries';
import SubmitButton from '../base/SubmitButton';
import { useFormState } from '../hooks/useFormState';
import { formValidation } from '../../utils/formValidation';
import { FieldList } from '../base/FieldList';
import { makeGraphQLRequest } from '../../utils/makeGraphQLRequest';

const LoginForm = (props) => {
	const {
		formData,
		setFormData,
		onChange,
		error,
		setError,
		isLoading,
		setIsLoading,
	} = useFormState();

	const formFields = [
		{
			id: 'username',
			label: 'Email',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('username', e.target.value),
				[onChange]
			),
			onKeyDown: (e) => e.key === 'Enter' && handleLogin(),
		},
		{
			id: 'password',
			label: 'Password',
			type: 'password',
			onChange: useCallback(
				(e) => onChange('password', e.target.value),
				[onChange]
			),
			onKeyDown: (e) => e.key === 'Enter' && handleLogin(),
		},
	];

	const getForestRecords = async () => {
		try {
			let filter_variables = {};
			// const kol = await Auth.currentAuthenticatedUser();
			// console.log(kol);

			const responseObject = await makeGraphQLRequest({
				query: queries.listUserData,
				variables: filter_variables,
				authMode: 'AWS_IAM',
			});
			const items_list = responseObject?.data?.listUserData?.items || [];
			console.log('>>>>>>>>>>>>>>>>>sucess', items_list);
		} catch (error) {
			console.log('ERROR>>>>>>>>>>>>>>>', error);
		}
	};

	const handleLogin = async () => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;

		try {
			setIsLoading(true);
			const user = await Auth.signIn(
				reducedData.username.toLowerCase(),
				reducedData.password
			);
			if (user) {
				let email_lowercase = reducedData.username.toLowerCase();
				const responseObject = await makeGraphQLRequest({
					query: queries.getLoggedInUserData,
					variables: { email: email_lowercase },
				});
				props.loggedInUserData({
					...user,
					...responseObject,
				});
				setError('');
			}
		} catch (error) {
			console.log('Login screen error>>>>>', error);
			let errorMsg = '';
			if (error.code === 'UserNotFoundException') {
				errorMsg = 'User does not exist. Please sign up.';
			} else if (error.code === 'NotAuthorizedException') {
				errorMsg = 'Incorrect username or password.';
			} else if (error.code === 'UserNotConfirmedException') {
				errorMsg =
					'User has not been confirmed. Please confirm your account.';
			} else {
				errorMsg =
					'Error signing in make sure to enter user name and password';
			}
			if (Auth.currentSession) {
				await Auth.signOut();
			}
			setError(errorMsg);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSignUp = () => {
		setError('');
		props.switchCurrentLoginSection({ page: 'SIGNUP_FORM' });
	};

	const handleForgotPassword = () => {
		setError('');
		props.switchCurrentLoginSection({ page: 'FORGOT_PASSWORD_FORM' });
	};

	const handleSignupConfirmation = () => {
		setError('');
		props.switchCurrentLoginSection({ page: 'SIGNUP_CONFIRMATION_FORM' });
	};

	return (
		<div className="animate-fadeIn flex flex-col w-3/5 h-full items-center justify-center gap-3">
			<div className="flex flex-col gap-2 w-full">
				<span className="text-3xl text-[#9BE938] text-start">
					Welcome
				</span>
				<span className="text-sm text-[#ffffff]">
					You have been added to the Green Cubes
				</span>
				<span className="text-sm text-[#ffffff] font-bold">SignIn</span>
			</div>
			<Text className="text-sm text-red-400 w-full">{error}</Text>
			<FieldList
				fields={formFields}
				data={formData}
			/>
			<Link
				className="w-full text-sm text-right"
				onClick={handleForgotPassword}>
				Forgot your password?
			</Link>
			<div className="flex flex-col w-full gap-4 mt-8">
				<SubmitButton
					isLoading={isLoading}
					onClick={handleLogin}
					text="Login"
				/>
				<SubmitButton
					onClick={handleSignUp}
					text="SignUp"
				/>
				<SubmitButton
					onClick={handleSignupConfirmation}
					text="Confirm Registration"
				/>
				{/* <SubmitButton onClick={ getForestRecords } text="get forest authetication check" /> */}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		errorStatus: state.errorStatus,
	};
};

export default connect(mapStateToProps, {
	errorTextAction,
	showLoadingIndicator,
	loggedInUserData,
	switchCurrentLoginSection,
})(LoginForm);
