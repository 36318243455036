import React, { useState, useEffect } from 'react';

const HorizontalPagination = ({ children, totalpages }) => {
	const [currentPage, setCurrentPage] = useState(0);
	const [isPaused, setIsPaused] = useState(false);
	const totalPages = totalpages;

	useEffect(() => {
		let interval;

		if (!isPaused) {
			interval = setInterval(() => {
				setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
			}, 3000);
		}

		return () => clearInterval(interval);
	}, [isPaused]);

	const handleMouseOver = () => {
		setIsPaused(true);
	};

	const handleMouseOut = () => {
		setIsPaused(false);
	};

	return (
		<div
			className="relative w-full overflow-hidden h-full flex"
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}>
			<div
				className="flex flex-row transition-transform duration-700 bg-[#fff] w-full"
				style={{ transform: `translateX(-${currentPage * 100}%)` }}>
				{children}
			</div>
		</div>
	);
};

export default HorizontalPagination;
