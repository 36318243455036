import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';

import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import { useFormState } from '../../../components/hooks/useFormState';
import { formValidation } from '../../../utils/formValidation';
import FormModal from '../../../components/base/FormModal';
import { AddLocationAltOutlined } from '@mui/icons-material';
import MapComponent from '../../../components/base/Map';
import Resizer from 'react-image-file-resizer';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const validateNoOfHectors = (value) => {
	if (value <= 0) {
		return `NoOfHectors should be greter than ${value}`;
	}
};

const getEmailList = (emailString) => {
	if (!emailString) return [];
	return emailString.map((email) => email.trim().toLowerCase());
};

const validateEmail = (email) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

const validateEmails = (emails) => {
	const emailList = getEmailList(emails);
	const invalidEmails = emailList.filter((email) => !validateEmail(email));
	if (invalidEmails.length) {
		return `Invalid emails found: ${invalidEmails.join(', ')}`;
	}
	const emailSet = new Set();
	const duplicateEmails = new Set();
	emailList.forEach((email) => {
		if (emailSet.has(email)) {
			duplicateEmails.add(email);
		} else {
			emailSet.add(email);
		}
	});

	if (duplicateEmails.size > 0) {
		return `Duplicate entries found: ${Array.from(duplicateEmails).join(', ')}`;
	}
	return '';
};

const formatSelectedItem = (selectedItem) => {
	if (!selectedItem) return selectedItem;
	const newSelectedItem = {
		...selectedItem,
		emailList: selectedItem?.emailList?.join(',') || '',
	};

	return newSelectedItem;
};

function ForestForm(props) {
	const { selectedItem } = props.modalStatus || {};
	const [showMap, setShowMap] = useState(false);
	const {
		formData,
		error,
		setFormData,
		setError,
		reset,
		onChange,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	} = useFormState({}, selectedItem);

	const formFields = [
		{
			id: 'ForestCountry',
			label: 'Forest Country',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('ForestCountry', e.target.value),
				[onChange]
			),
			editable: false,
			endAdornments: !selectedItem
				? [
						{
							icon: <AddLocationAltOutlined />,
							title: 'Choose location',
							onClick: () => setShowMap(true),
						},
					]
				: [],
		},
		{
			id: 'ForestName',
			label: 'Forest Name',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('ForestName', e.target.value),
				[onChange]
			),
			editable: false,
			endAdornments: !selectedItem
				? [
						{
							icon: <AddLocationAltOutlined />,
							title: 'Choose location',
							onClick: () => setShowMap(true),
						},
					]
				: [],
		},
		{
			id: 'lat',
			label: 'Latitude',
			type: 'number',
			onChange: useCallback(
				(e) => onChange('lat', e.target.value),
				[onChange]
			),
			editable: false,
			endAdornments: !selectedItem
				? [
						{
							icon: <AddLocationAltOutlined />,
							title: 'Choose location',
							onClick: () => setShowMap(true),
						},
					]
				: [],
		},
		{
			id: 'lon',
			label: 'Longitude',
			type: 'number',
			onChange: useCallback(
				(e) => onChange('lon', e.target.value),
				[onChange]
			),
			editable: false,
			endAdornments: !selectedItem
				? [
						{
							icon: <AddLocationAltOutlined />,
							title: 'Choose location',
							onClick: () => setShowMap(true),
						},
					]
				: [],
		},
		{
			id: 'noOfHectars',
			label: 'No Of Hectars',
			type: 'number',
			customValidator: validateNoOfHectors,
			onChange: useCallback(
				(e) => onChange('noOfHectars', e.target.value),
				[onChange]
			),
		},
		{
			id: 'icon',
			label: 'Project Globe Icon',
			type: 'file',
			skipValidation: true,
			mimeType: 'image/jpg,image/jpeg,image/png',
			onChange: useCallback(
				(e) => onChange('icon', e.target.files[0]),
				[onChange]
			),
		},
		{
			id: 'about_project_pdf_url',
			label: 'About Project(HTML)',
			type: 'file',
			skipValidation: true,
			mimeType: 'text/html',
			onChange: useCallback(
				(e) => onChange('about_project_pdf_url', e.target.files[0]),
				[onChange]
			),
		},
		{
			id: 'approver_emails',
			label: 'Approvers email',
			type: 'string',
			helperText: 'Enter multiple emails, separated by commas',
			placeholder: 'user1@example.com,user2@example.com',
			onChange: useCallback(
				(e) => onChange('approver_emails', e.target.value),
				[onChange]
			),
		},
		{
			id: 'agent_emails',
			label: 'Agents email',
			type: 'string',
			helperText: 'Enter multiple emails, separated by commas',
			placeholder: 'user1@example.com,user2@example.com',
			onChange: useCallback(
				(e) => onChange('agent_emails', e.target.value),
				[onChange]
			),
		},
		{
			id: 'support_emails',
			label: 'Support email',
			type: 'string',
			helperText: 'Enter multiple emails, separated by commas',
			placeholder: 'user1@example.com,user2@example.com',
			onChange: useCallback(
				(e) => onChange('support_emails', e.target.value),
				[onChange]
			),
		},
		{
			id: 'project_icon_path',
			label: 'Project Display Icon',
			type: 'file',
			skipValidation: true,
			mimeType: 'image/jpg,image/jpeg,image/png',
			onChange: useCallback(
				(e) => onChange('project_icon_path', e.target.files[0]),
				[onChange]
			),
		},
		{
			id: 'project_map_icon',
			label: 'Project Map Image',
			type: 'file',
			skipValidation: true,
			mimeType: 'image/jpg,image/jpeg,image/png',
			onChange: useCallback(
				(e) => onChange('project_map_icon', e.target.files[0]),
				[onChange]
			),
		},
	];

	function splitEmails(emailString) {
		let emailArray = [];
		emailString.split(',').forEach((email) => {
			emailArray.push(email.trim());
		});
		return emailArray;
	}

	const handleSubmit = async (isEdit) => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;
		setIsLoading(true);
		setError('');
		const { emailId, clientId } =
			props.loginedInUserData?.data?.getLoggedInUserData;
		try {
			let fileName = '';
			const file = formData.icon?.value;

			if (file) {
				fileName = `forest_pictures/${file.name}`;
			}

			let about_forest_pdf_path = '';
			const pdf_file = formData.about_project_pdf_url?.value;
			if (pdf_file) {
				about_forest_pdf_path = `about_project/${pdf_file.name}`;
			}

			let project_icon_display_path = '';
			const projectIcon_file = formData.project_icon_path?.value;
			if (projectIcon_file) {
				project_icon_display_path = `project_display_Icons/${projectIcon_file.name}`;
			}

			let project_map_icon_path = '';
			const projectMap_file = formData.project_map_icon?.value;

			if (projectMap_file) {
				project_map_icon_path = `project_map_Images/${projectMap_file.name}`;
			}

			//Changed below code to handle both edit and creation as array
			const data = {
				...reducedData,
				agent_emails: reducedData?.agent_emails
					? splitEmails(reducedData?.agent_emails)
					: newState.agent_emails.value,
				support_emails: reducedData?.support_emails
					? splitEmails(reducedData?.support_emails)
					: newState.support_emails.value,
				approver_emails: reducedData?.approver_emails
					? splitEmails(reducedData?.approver_emails)
					: newState.approver_emails.value,
			};

			if (!isEdit) {
				data.Status = true;
				data.createdBy = emailId;
			} else {
				data.id = newState.id.value;
			}

			if (file?.name) {
				data.icon = fileName;
			}
			if (pdf_file?.name) {
				data.about_project_pdf_url = about_forest_pdf_path;
			}

			if (projectIcon_file?.name) {
				data.project_icon_path = project_icon_display_path;
			}

			if (projectMap_file?.name) {
				data.project_map_icon = project_map_icon_path;
			}

			data.audit_client_msg = `${clientId}@@${emailId}`;

			// Validation code start//
			if (!isEdit) {
				const validation_result = await makeGraphQLRequest({
					query: queries.duplicateCheck,
					variables: {
						tableName: 'ForestMainTable',
						columns: ['ForestName'],
						enteredvalues: [data.ForestName],
					},
				});
				if (!validation_result) return;
			}
			// Validation code end//

			const result = await makeGraphQLRequest({
				query: isEdit
					? mutations.updateForestMainTable
					: mutations.createForestMainTable,
				variables: { input: data },
			});

			if (result && data?.icon) {
				const resizedImage = await resizeFile(file);
				await Storage.put(fileName, resizedImage, {
					contentType: file.type,
				});
			}

			if (result && data?.about_project_pdf_url) {
				await Storage.put(about_forest_pdf_path, pdf_file, {
					contentType: pdf_file.type,
				});
			}

			if (result && data?.project_icon_path) {
				const resizedIcon = await resizeIcon(projectIcon_file);
				await Storage.put(project_icon_display_path, resizedIcon, {
					contentType: projectIcon_file.type,
				});
			}

			if (result && data?.project_map_icon) {
				await Storage.put(project_map_icon_path, projectMap_file, {
					contentType: projectMap_file.type,
				});
			}

			setFormStatus(true);
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCloseModal = () => {
		reset();
		setShowMap(false);
		props.showModal({ value: false });
	};

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				100, // max width
				100, // max height
				'PNG', // output format
				100, // quality
				0, // rotation
				(uri) => {
					resolve(uri);
				},
				'blob' // output type
			);
		});

	const resizeIcon = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				100, // max width
				100, // max height
				'PNG', // output format
				100, // quality
				0, // rotation
				(uri) => {
					resolve(uri);
				},
				'blob' // output type
			);
		});

	const handleChoosePlace = (location) => {
		onChange('lat', location.lat);
		onChange('lon', location.lng);
		onChange('ForestCountry', location?.country || '');
		onChange('ForestName', location?.placeName || '');
		setShowMap(false);
	};

	if (!props.modalStatus.value) return null;

	return (
		<FormModal
			onCancel={handleCloseModal}
			onSubmit={() => handleSubmit(!!selectedItem)}
			isLoading={isLoading}
			title={`${selectedItem ? 'Edit' : 'Add'} Forest`}
			error={error}
			formStatus={formStatus}
			formFields={formFields}
			formData={formData}
			hideFooter={showMap}
			hideFields={showMap}
			isEdit={!!selectedItem}>
			{showMap && (
				<MapComponent
					center={
						formData.lat?.value
							? {
									lat: formData.lat?.value,
									lng: formData.lon?.value,
								}
							: undefined
					}
					onLocationSelect={handleChoosePlace}
					onLocationCancel={() => setShowMap(false)}
				/>
			)}
		</FormModal>
	);
}

const mapStateToProps = (state) => ({
	modalStatus: state.modalStatus,
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(ForestForm);
