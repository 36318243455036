import { useEffect, useState } from 'react';
import GreenCubeMiniLogo from '../../../Images/GreenCubeMiniLogo.png';
import SubmitButton from '../../../components/base/SubmitButton';
import CheckoutMain from '../MYVR/CheckoutMain';
import { formatNumber } from '../../../utils/utils';

const categories = [
	{ name: 'Bronze', multiplier: 1 },
	{ name: 'Silver', multiplier: 1.5 },
	{ name: 'Gold', multiplier: 3 },
];
const PurchaseCalculatorConfirm = (props) => {
	const { data, onCancel, onSubmit } = props;
	const [selectedCategory, setSelectedCategory] = useState('Bronze');
	const baseRecommendedCubes = data?.sizeInSqm * 3;
	const [recommendedCubes, setRecommendedCubes] =
		useState(baseRecommendedCubes);

	return (
		<CheckoutMain
			title="Sponsor calculator"
			cancelButtonText="close"
			submitButtonText="update cart"
			onCancel={onCancel}
			onSubmit={() => onSubmit(recommendedCubes)}>
			<div className="flex flex-col w-full gap-4">
				<div className="flex gap-8 justify-center items-center">
					{categories.map((catergory) => (
						<div
							key={catergory.name}
							className="flex flex-col gap-2 justify-center items-center">
							<span
								onClick={() => {
									setSelectedCategory(catergory.name);
									setRecommendedCubes(
										baseRecommendedCubes *
											catergory.multiplier
									);
								}}
								dangerouslySetInnerHTML={{
									__html: `
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <g clip-path="url(#clip0_4992_4029)">
                                            <path d="M13.1989 14.0165L14.1134 12.5324L15.7639 11.9695L15.9468 10.2361L17.1885 9.01359L16.5981 7.37273L17.1885 5.73184L15.9468 4.50938L15.7639 2.77594L14.1135 2.21305L13.1989 0.728945L11.469 0.937812L9.99953 0L8.53004 0.937891L6.8002 0.729023L5.88563 2.21309L4.23516 2.77598L4.0523 4.50941L2.81055 5.73187L3.40094 7.37277L2.81055 9.01367L4.05227 10.2361L4.23512 11.9696L5.88559 12.5325L6.80016 14.0165L8.53004 13.8077L9.99953 14.7455L11.469 13.8077L13.1989 14.0165ZM4.94371 7.37277C4.94371 4.585 7.21176 2.31695 9.99953 2.31695C12.7873 2.31695 15.0554 4.585 15.0554 7.37277C15.0554 10.1605 12.7873 12.4286 9.99953 12.4286C7.21176 12.4286 4.94371 10.1605 4.94371 7.37277Z" fill="#B9BCC1"/>
                                            <path d="M10.0004 3.48975C7.85918 3.48975 6.11719 5.23174 6.11719 7.37295C6.11719 9.51416 7.85918 11.2562 10.0004 11.2562C12.1416 11.2562 13.8836 9.51416 13.8836 7.37295C13.8836 5.23174 12.1416 3.48975 10.0004 3.48975Z" fill="#B9BCC1"/>
                                            <path d="M8.25344 15.0223L6.19563 15.2707L5.1084 13.5064L4.73879 13.3804L3.14453 18.3634L6.01465 18.2056L8.26016 20.0001L9.58156 15.8699L8.25344 15.0223Z" fill="#B9BCC1"/>
                                            <path d="M14.8911 13.5063L13.8039 15.2706L11.7461 15.0222L10.418 15.8698L11.7394 20L13.9849 18.2054L16.855 18.3633L15.2607 13.3802L14.8911 13.5063Z" fill="#B9BCC1"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4992_4029">
                                            <rect width="20" height="20" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                `,
								}}
								className={`${catergory.name === selectedCategory ? 'bg-[#EAF322] border-0' : 'bg-[#ffffff]'} p-2 rounded-full border border-[#888888] box-border w-10 h-10 flex items-center justify-center cursor-pointer`}></span>
							<span
								className={`${catergory.name === selectedCategory ? 'font-bold' : ''}`}>
								{catergory.name}
							</span>
						</div>
					))}
				</div>
				<div className="flex flex-col px-6 gap-3">
					<div className="flex justify-between items-center">
						<span className="font-bold basis-1/3">
							Square meters of office space
						</span>
						<span
							className={`outline-0 border border-[#888888] text-black font-bold rounded-3xl text-center flex items-center justify-center w-48 h-10 box-border`}>
							{formatNumber(data?.sizeInSqm)}
						</span>
						<span className="basis-1/3" />
					</div>
					<div className="flex justify-between items-center gap-3">
						<div className="flex gap-3 items-center font-bold basis-1/3">
							<span>Recommend number of Green Cubes</span>
						</div>
						<span
							className={`outline-0 text-[#77D721] font-bold rounded-3xl text-center shrink-0 basis-1/3 flex items-center gap-5`}>
							<img
								src={GreenCubeMiniLogo}
								alt=""
								className="w-10 h-10 object-contain"
							/>
							{formatNumber(recommendedCubes)}
						</span>
						<span className="basis-1/3" />
					</div>
				</div>
			</div>
		</CheckoutMain>
	);
};

export default PurchaseCalculatorConfirm;
