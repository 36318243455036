import { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip, CircularProgress } from "@digitalreality/ui";
import { Document, Page, pdfjs } from "react-pdf";
import { DownloadIcon, MenuAltIcon, NextOutlineIcon, PreviousOutlineIcon } from "@digitalreality/ui-icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PDFViewer = ({ url }) => {
    const [ numPages, setNumPages ] = useState(null);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ pdfWidth, setPdfWidth ] = useState(0);
    const [ thumbnails, setThumbnails ] = useState(true);
    const containerRef = useRef(null);
    const itemsRef = useRef([]);

    useEffect(() => {
        const updateContainerWidth = () => {
            if (containerRef.current) {
                setPdfWidth(containerRef.current.offsetWidth);
            }
        };
        updateContainerWidth();
        window.addEventListener('resize', updateContainerWidth);
        return () => window.removeEventListener('resize', updateContainerWidth);
    }, [ thumbnails ]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowUp':
                case 'ArrowLeft':
                    if (pageNumber !== 1) {
                        handlePageChange(pageNumber - 1);
                    }
                    break;
                case 'ArrowDown':
                case 'ArrowRight':
                    if (pageNumber !== numPages) {
                        handlePageChange(pageNumber + 1);
                    }
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [ numPages, pageNumber ]);

    useEffect(() => {
        if (itemsRef.current[pageNumber]) {
            itemsRef.current[pageNumber].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [ pageNumber ]);

    const handlePageChange = (pageNumber) => {
        setPageNumber(pageNumber);
    };

    const handleDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    };

    const handleDownload = async () => {
        const parts = url.split('/');
        const fileName = parts[parts.length - 1].split('?')[0];
    
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
    
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <div className="flex flex-col overflow-hidden h-[80vh]">
            <div className="flex overflow-hidden flex-1">
                <div style={ { display: thumbnails ? 'flex' : 'none' } } className="flex justify-center overflow-hidden">
                    <Document
                        className="p-8 overflow-y-auto"
                        file={ url }
                        onLoadSuccess={ handleDocumentLoadSuccess }>
                        { Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <div
                                    ref={(el) => (itemsRef.current[index + 1] = el)}
                                    key={`thumbnail_${index + 1}`}
                                    onClick={ () => handlePageChange(index + 1) }
                                    className={ `cursor-pointer mb-4 ${pageNumber === index + 1 ? 'outline outline-offset-2 outline-4' : ''} outline-green-500 p-1` }>
                                    <Page
                                        renderAnnotationLayer={ false }
                                        renderTextLayer={ false }
                                        pageNumber={ index + 1 }
                                        width={ 112 } />
                                </div>
                            ),
                        ) }
                    </Document>
                </div>
                <div ref={ containerRef } className='flex flex-col w-full overflow-y-auto overflow-hidden flex-1 pdf-container-greencubes'>
                    <Document
                        loading={
                            <div className="flex flex-col justify-center items-center flex-1 gap-2 overflow-hidden h-[75vh] text-black bg-white">
                                <span>Loading report. Please wait...</span>
                                <CircularProgress color="success" />
                            </div>
                        }
                        file={ url }>
                        { Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <div className={ pageNumber === index + 1 ? 'flex' : 'hidden' } key={`page_${index + 1}`}>
                                    <Page
                                        renderAnnotationLayer={ false }
                                        renderTextLayer={ false }
                                        pageNumber={ index + 1 }
                                        width={ pdfWidth } />
                                </div>
                            ),
                        ) }
                    </Document>
                </div>
            </div>
            <div className="flex justify-between items-center px-4 py-2 bg-[#666]">
                <Tooltip title={ `${thumbnails ? 'Hide' : 'Show'} Thumbnails` }>
                        <IconButton
                            onClick={ () => setThumbnails(!thumbnails) }>
                            <MenuAltIcon />
                        </IconButton>
                </Tooltip>
                <span>Page { pageNumber } of { numPages }</span>
                <div className="flex gap-3">
                    <Tooltip title="Previous page">
                        <span>
                            <IconButton
                                disabled={ pageNumber === 1 }
                                onClick={ () => handlePageChange(pageNumber - 1) }>
                                <PreviousOutlineIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Next page">
                        <span>
                            <IconButton
                                disabled={ pageNumber === numPages }
                                onClick={ () => handlePageChange(pageNumber + 1) }>
                                <NextOutlineIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Download PDF">
                        <span>
                            <IconButton
                                onClick={ handleDownload }>
                                <DownloadIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
};

export default PDFViewer;
