import React, { useState } from "react";
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { BasicPasswordField, FormControl, InputLabel, OutlinedInput, Text } from '@digitalreality/ui';
import { switchCurrentLoginSection } from "../../Pages/ReduxStore/Actions/LoginPageActions";
import SubmitButton from "../base/SubmitButton";

const ForgotPasswordForm = (props) => {
    const [ error, setError ] = useState('');
    /**
     * values for step state
     * 0 - Enter email
     * 1 - Enter code and new password
     * 2 - successful reset
     */
    const [ step, setStep ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);

    const [ values, setValues ] = useState({
        email: '',
        verficationCode: '',
        newPassword: '',
    });

    const handleChange = (event, prop) => {
        setError('');
        setValues({
            ...values,
            [ prop ]: event.target.value
        });
    };

    const handleEnter = (event) => {
		if (event.key === "Enter") {
            handleForgotPassword();
		}
	};

    const handleForgotPassword = async () => {
        if (!values.email) {
            setError("Please enter your valid email ID");
        } else {
            setIsLoading(true);
            try {
                const mailSent = await Auth.forgotPassword(values.email);
                if (mailSent) {
                    setStep(1);
                }
            } catch (error) {
                setError(error.message);
            }
            finally {
                setIsLoading(false);
            }
        }

    };

    const handleCancelForgotPassword = () => {
        setError('');
        props.switchCurrentLoginSection({ page: 'LOGIN_FORM' });
    };

    const handleNewPassword = async () => {
        try {
            const confirmationMsg = await Auth.forgotPasswordSubmit(values.email, values.verficationCode, values.newPassword);
            if (confirmationMsg) {
                setStep(2);
            }
        } catch (error) {
            let errorMsg = error.message;

			if (!errorMsg) {
				errorMsg = error?.errors?.[0]?.message;
			}
            setError(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleSignin = () => {
        props.switchCurrentLoginSection({ page: 'LOGIN_FORM' });
    };

    return (
        <div className="animate-fadeIn flex flex-col w-3/5 h-full items-center justify-center gap-3">
            { (step >= 0 && step < 2) && (
                <>
                    <div className="flex flex-col gap-2 w-full">
                        <span className="text-3xl text-[#9BE938] text-start">Forgot Password?</span>
                        <span className="text-sm text-[#ffffff]">We have sent an email with instructions to recover your password</span>
                    </div>
                    <Text className="text-sm text-red-400 w-full">{ error }</Text>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email">Email*</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-email"
                            value={ values.email }
                            onChange={ (e) => handleChange(e,'email') }
                            onKeyDown={ handleEnter }
                            label="Email"
                        />
                    </FormControl>
                    { step === 1 && (
                        <div className="flex flex-col w-full gap-3">
                            <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-verficationCode">Verification Code</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-verficationCode"
                                value={ values.verficationCode }
                                onChange={ (e) => handleChange(e,'verficationCode') }
                                onKeyDown={ handleEnter }
                                label="Verification Code"
                            />
                            </FormControl>
                            <BasicPasswordField
                                fullWidth
                                label="New Password"
                                value={ values.newPassword }
                                onChange={ (e) => handleChange(e,'newPassword') }
                                onKeyDown={ handleEnter }
                            />
                        </div>
                    )}
                    <div className="flex flex-row w-full justify-between mt-3">
                        <SubmitButton disabled={ isLoading } onClick={ handleCancelForgotPassword } text="Cancel" variant="secondary" />
                        <SubmitButton isLoading={ isLoading } onClick={ step === 1 ? handleNewPassword : handleForgotPassword } text={ step === 1 ? "Change Password" : "Submit" } />
                    </div> 
                </>  
            )}
            { step === 2 && (
                <div className="flex flex-col w-full gap-3">
                    <span className="text-1xl text-[#9BE938] text-start">Password has been reset successfully</span>
                    <SubmitButton onClick={ handleSignin } text="Login" />
                </div>
            )}
        </div>
    );
};

export default connect(null, { switchCurrentLoginSection })(ForgotPasswordForm);
