import InvoiceList from "./InvoiceList";

const Invoice = () => {
    return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<InvoiceList />
		</div>
    );
};

export default Invoice;
