import { API } from 'aws-amplify';
import { store } from '../Pages/ReduxStore/Stores/Store';
import { setSessionExpired } from '../Pages/ReduxStore/Actions/CommonActions';

const makeGraphQLRequest = async (payload) => {
	let response;
	try {
		response = await API.graphql(payload);
		return response;
	} catch (e) {
		//we might get issue in future(loophole)
		if (e.message === 'No current user') {
			store.dispatch(setSessionExpired(true));
		} else {
			throw e;
		}
	}
};

export { makeGraphQLRequest };
