import {
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	CircularProgress,
} from '@digitalreality/ui';
import { memo } from 'react';

const DropdownField = ({
	onChange,
	value,
	error,
	label,
	id,
	items,
	disabled,
}) => {
	return (
		<FormControl
			error={!!error}
			fullWidth>
			<InputLabel id={id}>{label}</InputLabel>
			<Select
				labelId={id}
				label={label}
				onChange={onChange}
				disabled={disabled}
				value={value ?? ''}>
				{items.length === 0 ? (
					<MenuItem disabled>
						<CircularProgress size={24} />
						<span style={{ marginLeft: '10px' }}>Loading...</span>
					</MenuItem>
				) : (
					items.map((item, index) => (
						<MenuItem
							key={index}
							value={item}>
							{item}
						</MenuItem>
					))
				)}
			</Select>
			{!!error && (
				<span className="text-[12px] text-[#F85263] w-full mx-3.5 mt-[3px] mb-0 font-light">
					{error}
				</span>
			)}
		</FormControl>
	);
};

export default memo(DropdownField);
