import InvitationsList from "./InvitationsList";
import InvitationForm from "./InvitationForm";

const Invitation = () => {
    return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<InvitationForm />
			<InvitationsList />
		</div>
    );
};

export default Invitation;
