import { useState } from 'react';
import {
	CameraAltOutlined,
	InfoOutlined,
	TravelExplore,
} from '@mui/icons-material';
import { ClickAwayListener, Popper } from '@mui/material';

const SponsorForestExplore = (props) => {
	const {
		selectedForest,
		callbackHandler,
		polygons,
		onPolygonSelect,
		onAboutClose,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [currentPopoverId, setCurrentPopoverId] = useState(null);

	const handlePopoverClick = (event, popoverId) => {
		if (currentPopoverId !== popoverId) {
			// Open the new popover
			setAnchorEl(null);
			setCurrentPopoverId(null);
			setAnchorEl(event.currentTarget);
			setCurrentPopoverId(popoverId);
		} else {
			// Close the current popover if the same button is clicked again
			setAnchorEl(null);
			setCurrentPopoverId(null);
		}
	};

	const handlePolygonClick = (polygon) => {
		if (currentPopoverId === 'Explore Sites') {
			onPolygonSelect(polygon);
		} else {
			callbackHandler('showForestBaseline', polygon);
		}
	};

	const selectedPolygons = (polygons || []).filter(
		(polygon) => polygon.ForestID === selectedForest?.id
	);

	const exploreOptions = [
		{
			id: 'Explore Sites',
			onClick: (event) => handlePopoverClick(event, 'Explore Sites'),
			icon: <TravelExplore style={{ fontSize: '16px' }} />,
		},
		{
			id: 'Camera Traps & Sightings',
			onClick: () =>
				callbackHandler('showForestMedia', { filterFolder: 'General' }),
			icon: <CameraAltOutlined style={{ fontSize: '16px' }} />,
		},
		{
			id: 'Baseline reports',
			onClick: (event) => handlePopoverClick(event, 'Baseline reports'),
			icon: <InfoOutlined style={{ fontSize: '16px' }} />,
		},
		{
			id: 'About the Project',
			onClick: () => callbackHandler('showAboutForest'),
			icon: <InfoOutlined style={{ fontSize: '16px' }} />,
		},
	];

	const handleAboutClose = (event) => {
		if (event.target.innerText !== 'About the Project') {
			onAboutClose();
		}
	};

	return (
		<div
			onClick={handleAboutClose}
			className="animate-fadeIn flex flex-col items-center flex-1">
			<span className="text-4xl text-center font-light">
				{selectedForest?.forestName}
			</span>
			<div className="flex flex-col items-center w-full py-8">
				{exploreOptions.map((option) => {
					return (
						<span
							key={option.id}
							className={`w-full hover:bg-[#9aeb2f] hover:opacity-70 border-b-2 hover:text-black p-[10px] cursor-pointer flex items-center gap-3`}
							onClick={option.onClick}>
							{option.id}
						</span>
					);
				})}
				<Popper
					open={!!anchorEl && !!currentPopoverId}
					placement="right-start"
					anchorEl={anchorEl}
					onClose={() => {
						setAnchorEl(null);
						setCurrentPopoverId(null);
					}}>
					<ClickAwayListener
						onClickAway={(event) => {
							if (
								event.target.innerText !== 'Explore Sites' &&
								event.target.innerText !== 'Baseline reports'
							) {
								setAnchorEl(null);
								setCurrentPopoverId(null);
							}
						}}>
						<div className="flex flex-col items-center justify-center bg-white text-black cursor-pointer w-80 p-2 py-4 box-border">
							{selectedPolygons.map((polygon) => (
								<span
									key={polygon.id}
									className="w-full hover:bg-[#d7f4b1] hover:opacity-70 border-b-2 hover:text-black p-2"
									onClick={() => handlePolygonClick(polygon)}>
									{polygon.polygon_name}
								</span>
							))}
						</div>
					</ClickAwayListener>
				</Popper>
			</div>
		</div>
	);
};

export default SponsorForestExplore;
