import React, { useState } from 'react';
import { Tooltip } from '@digitalreality/ui';
import {
	CheckBoxOutlined,
	CancelOutlined,
	DoneOutlined,
	CloseOutlined,
} from '@mui/icons-material';

import * as mutations from '../../../graphql/mutations';
import SubmitButton from '../../../components/base/SubmitButton';
import MaxWidthDialog from '../../../components/base/Dialog';
import TextField from '../../../components/formFields/TextField';
import { connect } from 'react-redux';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const PurchaseStatusActionRenderer = (props) => {
	const { value, onError } = props;
	const [isLoading, setIsLoading] = useState({ status: false, selected: '' });
	const [showModal, setShowModal] = useState(false);
	const [comments, setComments] = useState('');
	const { assignedRole, clientName, emailId } =
		props.loginedInUserData?.data?.getLoggedInUserData;

	const getDateAndTime = () => {
		const now = new Date();
		const year = now.getFullYear();
		const month = (now.getMonth() + 1).toString().padStart(2, '0');
		const day = now.getDate().toString().padStart(2, '0');
		const hours = now.getHours().toString().padStart(2, '0');
		const minutes = now.getMinutes().toString().padStart(2, '0');
		const seconds = now.getSeconds().toString().padStart(2, '0');

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	const {
		purchasedClientName,
		purchasedForestName,
		purchasedForestArea,
		purchasedNoOfCubes,
		purchasedCubePrice,
		purchasedInvoiceCost,
	} = props?.data || {};

	const visibleData = [
		{
			label: 'Client Name',
			value: purchasedClientName,
		},
		{
			label: 'Forest Name',
			value: purchasedForestName,
		},
		{
			label: 'Forest Area',
			value: purchasedForestArea,
		},
	];

	function generateRandomString(length) {
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';
		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			result += characters[randomIndex];
		}
		return result;
	}

	const handleClick = async (purchaseStatus) => {
		const data = {
			id: props?.data?.id,
			purchaseStatus,
			audit_client_msg: `${clientName}@@${emailId}`,
		};

		if (comments) {
			const currentTime = getDateAndTime();
			data.purchaseComments = `${emailId} on ${currentTime} with comments  '${comments}'`;
		}
		setIsLoading({ status: true, selected: purchaseStatus });
		if (purchaseStatus === 'Approved') {
			const invoice_number = generateRandomString(8);
			data.purchasedInvoiceNumber = invoice_number;
		}

		try {
			await makeGraphQLRequest({
				query: mutations.updatePurchaseOrders,
				variables: { input: data },
			});
			onError('');
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading({ status: false, selected: '' });
		}
	};

	const handleCloseModal = () => {
		setComments('');
		setShowModal(false);
	};

	const componentMappers = {
		New: (
			<span
				onClick={() => setShowModal(true)}
				className="px-2 bg-[#9BE938] cursor-pointer rounded-2xl leading-normal">
				Take Action
			</span>
		),
		Approved: (
			<Tooltip title="Purchase Approved">
				<CheckBoxOutlined className="text-green-500" />
			</Tooltip>
		),
		Rejected: (
			<Tooltip title="Purchase Rejected">
				<CancelOutlined className="text-red-500" />
			</Tooltip>
		),
		Cancelled: (
			<Tooltip title="Purchase Cancelled">
				<CancelOutlined className="text-red-500" />
			</Tooltip>
		),
	};

	return (
		<>
			{componentMappers[value]}
			<MaxWidthDialog
				loading={isLoading}
				cancelButtonText="Cancel"
				onCancel={handleCloseModal}
				hideFooter
				removePadding
				open={showModal}
				title="Purchase action">
				<div className="flex flex-col">
					<div className="flex flex-col p-4 mx-16">
						{visibleData.map((item) => (
							<div
								key={item.label}
								className="flex items-center justify-between">
								<span className="font-bold">{item.label}</span>
								<span>{item.value}</span>
							</div>
						))}
						<div className="flex flex-col border-t border-t-slate-700 py-4 mt-4">
							<div className="flex items-center justify-between">
								<span className="font-bold">
									Number of Cubes
								</span>
								<span>{purchasedNoOfCubes}</span>
							</div>
							<div className="flex items-center justify-between">
								<span className="font-bold">Cube Price</span>
								<span>{purchasedCubePrice}</span>
							</div>
						</div>
						<div className="flex items-center justify-between mb-4 border-t border-t-slate-700 py-4">
							<span className="font-bold">Invoice Cost</span>
							<span>{purchasedInvoiceCost}</span>
						</div>
						<div className="flex items-center justify-between">
							<TextField
								label="Comments"
								multiline
								value={comments}
								onChange={(e) => setComments(e.target.value)}
								placeholder="Comments (if any)"
							/>
						</div>
					</div>
					{assignedRole === 'super-admin' && (
						<span className="text-xs m-4">
							Please approve/reject this purchase order
						</span>
					)}
					<div className="flex justify-end items-center gap-3 px-4 py-2 bg-[#666]">
						{assignedRole === 'super-admin' ? (
							<>
								<SubmitButton
									onClick={() => handleClick('Rejected')}
									endIcon={<CloseOutlined />}
									isLoading={
										isLoading.selected === 'Rejected' &&
										isLoading.status
									}
									disabled={isLoading.status}
									variant="danger"
									text="Reject"
								/>
								<SubmitButton
									endIcon={<DoneOutlined />}
									onClick={() => handleClick('Approved')}
									isLoading={
										isLoading.selected === 'Approved' &&
										isLoading.status
									}
									disabled={isLoading.status}
									text="Approve"
								/>
							</>
						) : (
							<SubmitButton
								onClick={() => handleClick('Cancelled')}
								endIcon={<CloseOutlined />}
								isLoading={
									isLoading.selected === 'Cancelled' &&
									isLoading.status
								}
								disabled={isLoading.status}
								variant="danger"
								text="Cancel"
							/>
						)}
					</div>
				</div>
			</MaxWidthDialog>
		</>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps)(PurchaseStatusActionRenderer);
