import { connect } from 'react-redux';
import { useState } from 'react';
import CheckoutMain from './CheckoutMain';
import { CircularProgress } from '@mui/material';

const Checkout = (props) => {
	const { onCancel, onRequestAgent, onSubmit, loginedInUserData, isLoading } =
		props;
	const { value, item } = isLoading || {};
	const { emailId, phoneNumber } =
		loginedInUserData?.data?.getLoggedInUserData || {};
	const [data, setData] = useState({ emailId, phoneNumber });
	const [errors, setErrors] = useState({});

	const fields = [
		{
			name: 'phoneNumber',
			label: 'Phone Number',
			type: 'tel',
		},
		{
			name: 'emailId',
			label: 'Email ID',
			type: 'email',
		},
	];

	const handleSubmit = (e, isRequestAgent) => {
		const newErrors = {};

		fields.forEach((field) => {
			if (!data[field.name]) {
				newErrors[field.name] = `${field.label} Required`;
			} else if (field.type === 'email') {
				const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				const isValidEmail = emailRegex.test(data[field.name]);

				if (!isValidEmail) {
					newErrors[field.name] = 'Invalid email';
				}
			} else if (field.type === 'tel') {
				const telRegex =
					/^(\+?\d{1,4}[\s-]?)?(\(?\d{3}\)?[\s-]?)?[\d\s-]{7,10}$/;
				const isValidTel = telRegex.test(data[field.name]);

				if (!isValidTel) {
					newErrors[field.name] = 'Invalid Phone number';
				}
			}
		});

		setErrors(newErrors);
		const hasError = Object.keys(newErrors).length > 0;
		if (!hasError) {
			if (isRequestAgent) {
				onRequestAgent(data);
			} else {
				onSubmit();
			}
		}
	};

	return (
		<CheckoutMain
			title="Check out"
			cancelButtonText="cancel"
			submitButtonText="check out"
			onCancel={onCancel}
			isLoading={isLoading}
			onSubmit={handleSubmit}>
			<div className="flex flex-col gap-4 w-3/5 pb-6">
				<span className="text-sm font-normal">
					On check out, your cubes will now be escrowed for two weeks,
					and an agent wil be in contact to manage the purchase order
					and payment.
				</span>
				<span className="text-base font-bold text-[#2A2A2A]">
					Preferred means of contact
				</span>
				{fields.map((field) => (
					<div className="flex flex-col">
						<input
							className={`outline-0 border border-[#888888] h-10 w-48 text-black text-sm text-center rounded-3xl px-2 ${errors[field.name] ? 'bg-red-200' : 'bg-white'}`}
							value={data[field.name] || ''}
							placeholder={field.label}
							type={field.type}
							onChange={(e) => {
								setData({
									...data,
									[field.name]: e.target.value,
								});
								setErrors({
									...errors,
									[field.name]: undefined,
								});
							}}
						/>
						{errors[field.name] && (
							<span className="text-red-400 text-[10px] italic">
								{errors[field.name]}
							</span>
						)}
					</div>
				))}
				<span className="text-base font-bold text-[#2A2A2A] w-2/3">
					Have questions? Request a team member to contact you
				</span>
				<button
					onClick={(e) => handleSubmit(e, true)}
					disabled={!!value}
					className={`flex justify-center items-center h-10 w-48 bg-[#B9F46E] rounded-[20px]  ${!!value && item === 'contact' ? 'opacity-60' : ''}`}>
					<span className="uppercase text-sm font-normal flex items-center gap-x-1">
						<span>Contact Me</span>
						{!!value && item === 'contact' && (
							<CircularProgress
								thickness={4}
								style={{
									color: 'inherit',
									marginLeft: '8px',
								}}
								size={20}
							/>
						)}
					</span>
				</button>
			</div>
		</CheckoutMain>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps)(Checkout);
