import React, { useEffect, useState } from 'react';
import { Tooltip } from '@digitalreality/ui';
import MaxWidthDialog from '../../../components/base/Dialog';
import PDFViewer from '../../../components/base/PDFViewer';

import { Visibility } from '@mui/icons-material';
import { IconButton } from '@digitalreality/ui';
import { Storage } from 'aws-amplify';

const InvoiceActionRenderer = (props) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ showModal, setShowModal ] = useState(false);
	const [ s3URL, setS3URL ] = useState('');
    useEffect(() => {
        const getS3URL = async () => {
            const pdfURL = await Storage.get(props.value);
            setS3URL(pdfURL);
        }

        getS3URL();
    }, []);
    
    const handleCloseModal = () => {
        setShowModal(false);
    };
    
    return (
		<>
            <MaxWidthDialog
				loading={ isLoading }
				cancelButtonText="Cancel"
				onCancel={ handleCloseModal }
				hideFooter
				removePadding
				open={ showModal }
				title="Invoice"
                maxWidth="xl">
                <PDFViewer url={ s3URL } />
            </MaxWidthDialog>
			<Tooltip title="View report">
                <IconButton onClick={ () => setShowModal(true) }>
                    <Visibility className="text-black" />
                </IconButton>
            </Tooltip>
		</>
	);
};

export default InvoiceActionRenderer;

