import { useMemo } from 'react';
import { connect } from 'react-redux';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as queries from '../../../graphql/queries';
import Grid from '../../CommonComponents/AGGrid/Grid';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import { useGridState } from '../../../components/hooks/useGridState';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const AuditList = (props) => {
	const getUserRecords = async () => {
		try {
			// const { assignedRole, clientId } = props.loginedInUserData?.data?.getLoggedInUserData;
			let filter_variables = {};
			// if (assignedRole === "super-admin") {
			// 	filter_variables = {};
			// }
			// else if (assignedRole === "client-admin") {
			// 	filter_variables = {
			// 	    filter: { clientName: { eq: clientId } },
			// 	};
			// }
			// else {
			// 	filter_variables = { filter: { clientName: { eq: "NA" } } };
			// }

			const responseObject = await makeGraphQLRequest({
				query: queries.listAuditTrails,
				variables: filter_variables,
			});
			const items_list =
				responseObject?.data?.listAuditTrails?.items || [];
			onRecords(items_list);
		} catch (error) {
			onError(error);
		}
	};

	const {
		error,
		rowData,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreateClientsTable',
		onUpdateTable: 'onUpdateClientsTable',
		fetchRecords: getUserRecords,
	});

	const actions = [];

	const colDefs = useMemo(
		() => [
			{
				field: 'ActionType',
				headerName: 'Action Type',
				pinned: 'left',
				cellStyle: { 'font-weight': 'bold' },
				width: 150,
			},
			{
				field: 'ModuleName',
				headerName: 'Module',
				width: 150,
			},
			{
				field: 'PerformedBy',
				headerName: 'Performed By',
				width: 300,
			},
			{
				field: 'PerformedAt',
				headerName: 'PerformedAt',
				width: 200,
			},
			{
				field: 'Message',
				headerName: 'Audit Message',
				autoHeight: true,
				flex: 1,
				cellStyle: { 'white-space': 'normal', lineHeight: '1.1' },
				width: 630,
			},
		],
		[]
	);

	return (
		<GridLayout
			error={error}
			actions={actions}
			title="Audit Trail">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				onGridReady={onGridReady}
				onSelectionChanged={onSelectionChanged}
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(AuditList);
