import { combineReducers } from 'redux';
import { loginedInUserData, currentLoginSection } from './LoginReducer';
import { letsGoStatus } from './LandingReducer';
import { selectedFooterLegal } from './FooterReducer';
import {
	errorStatus,
	landingPage,
	appLoadingIndicator,
	sessionExpired,
} from './CommonReducer';
import { loading_indicator_reducer_status } from './CommonReducer';
import modalReducer from './ModalReducer';

const RootReducer = combineReducers({
	letsGoStatus: letsGoStatus,
	selectedFooterLegal: selectedFooterLegal,
	errorStatus: errorStatus,
	loginedInUserData: loginedInUserData,
	loading_indicator_reducer_status: loading_indicator_reducer_status,
	modalStatus: modalReducer,
	landingPage: landingPage,
	appLoadingIndicator,
	currentLoginSection: currentLoginSection,
	sessionExpired,
});

export default RootReducer;
