import React, { useState } from 'react';
import {
	ToggleOnOutlined,
	ToggleOffOutlined,
	CheckOutlined,
} from '@mui/icons-material';
import { IconButton, Tooltip, CircularProgress } from '@digitalreality/ui';

import * as mutations from '../../../graphql/mutations';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const InvitationActionRenderer = (props) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleAction = async () => {
		setIsLoading(true);
		const data = {
			id: props?.data?.id,
			invitationCurrentStatus:
				props.value === 'New' && props.value !== 'confirmed'
					? 'Disabled'
					: 'New',
		};
		try {
			await makeGraphQLRequest({
				query: mutations.updateInvitationTable,
				variables: { input: data },
			});
			props.onError('');
		} catch (error) {
			props.onError(error);
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<span className="flex flex-col items-center justify-center h-full">
			{props.value === 'confirmed' ? (
				<Tooltip title="Successfully Signed Up">
					<CheckOutlined className="text-sky-500" />
				</Tooltip>
			) : !isLoading ? (
				<IconButton onClick={handleAction}>
					{props.value === 'New' && (
						<Tooltip title="Disable Invitation">
							<ToggleOnOutlined className="text-sky-500" />
						</Tooltip>
					)}
					{props.value === 'Disabled' && (
						<Tooltip title="Enable Invitation">
							<ToggleOffOutlined className="text-gray-500	" />
						</Tooltip>
					)}
				</IconButton>
			) : (
				<CircularProgress
					thickness={4}
					style={{ color: '#0ea5e9' }}
					size={20}
				/>
			)}
		</span>
	);
};

export default InvitationActionRenderer;
