import { Text } from '@digitalreality/ui';
import SubmitButton from '../base/SubmitButton';
import { connect } from 'react-redux';
import { Auth, Storage } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { switchCurrentLoginSection } from '../../Pages/ReduxStore/Actions/LoginPageActions';
import { useFormState } from '../hooks/useFormState';
import { formValidation } from '../../utils/formValidation';
import { FieldList } from '../base/FieldList';
import { useCallback } from 'react';
import { makeGraphQLRequest } from '../../utils/makeGraphQLRequest';

const SignupForm = (props) => {
	const {
		formData,
		setFormData,
		onChange,
		error,
		setError,
		isLoading,
		setIsLoading,
	} = useFormState({
		email: { value: props.currentLoginSection?.email || '' },
	});

	const formFields = [
		{
			id: 'fName',
			label: 'Name',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('fName', e.target.value),
				[onChange]
			),
		},
		{
			id: 'email',
			label: 'Email',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('email', e.target.value),
				[onChange]
			),
			disabled: !!props.currentLoginSection?.email,
		},
		{
			id: 'password',
			label: 'Password',
			type: 'password',
			onKeyDown: (e) => e.key === 'Enter' && handleSignUp(),
			onChange: useCallback(
				(e) => onChange('password', e.target.value),
				[onChange]
			),
		},
		{
			id: 'profilePicture',
			label: 'Choose Profile Picture',
			type: 'file',
			skipValidation: true,
			mimeType: 'image/jpg,image/jpeg,image/png',
			onChange: useCallback(
				(e) => onChange('profilePicture', e.target.files[0]),
				[onChange]
			),
		},
	];

	const handleCancelSignup = () => {
		props.switchCurrentLoginSection({ page: 'LOGIN_FORM' });
	};

	const handleSignUp = async () => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;

		const email = reducedData.email.toLowerCase();

		const userObjectData = {
			eMail: email.toLowerCase(),
			firstName: reducedData.fName,
		};

		setIsLoading(true);
		setError('');

		try {
			// authMode: 'AWS_IAM'
			// Step 1: Check for valid invitation
			const responseObject = await makeGraphQLRequest({
				query: mutations.userRegistrationvalidation,
				variables: { userObject: userObjectData },
				authMode: 'AWS_IAM',
			});

			// Step 2: After successful invite validation, perform signup
			const file = reducedData.profilePicture;
			let fileName = '';
			if (file) {
				fileName = `profile-pictures/${file.name}`;
			}
			const data = await Auth.signUp({
				username: email.toLowerCase(),
				password: reducedData.password,
				attributes: {
					email: email.toLowerCase(),
					name: reducedData.fName,
					picture: fileName,
				},
			});

			// Step 3: After successful signup, upload user image
			if (data) {
				if (fileName) {
					await Storage.put(fileName, file, {
						contentType: file.type,
					});
				}
				// Create User in User Data table with all required data
				const body = responseObject?.data?.userRegistrationvalidation;

				// Step 4: Save user record to our data base.
				const userObject = {
					emailId: email.toLowerCase(),
					firstName: reducedData.fName,
					clientId: body.clientID,
					clientName: body.clientName,
					assignedRole: body.clientRole,
					profilePicURL: fileName,
					profileStatus: 'Active',
					id: email.toLowerCase(),
					audit_client_msg: `${body.clientName}@@${email}`,
				};

				const response = await makeGraphQLRequest({
					query: mutations.createUserData,
					variables: { input: userObject },
					authMode: 'AWS_IAM',
				});

				if (response) {
					setError('');
					props.switchCurrentLoginSection({
						page: 'SIGNUP_CONFIRMATION_FORM',
						email: email.toLowerCase(),
					});
				}
			}
		} catch (error) {
			console.log('Signup Error---', error);
			let errorMsg = error.message;

			if (!errorMsg) {
				errorMsg = error?.errors?.[0]?.message;
			}
			setError(errorMsg);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="animate-fadeIn flex flex-col w-3/5 h-full items-center justify-center gap-3">
			<div className="flex flex-col gap-2 w-full">
				<span className="text-3xl text-[#9BE938] text-start">
					Register
				</span>
				<span className="text-sm text-[#ffffff]">
					You have been invited to Green Cubes Dashboard
				</span>
				<span className="text-sm text-[#ffffff] font-bold">
					Register Your Account
				</span>
			</div>
			<Text className="text-sm text-red-400 w-full">{error}</Text>
			<FieldList
				fields={formFields}
				data={formData}
			/>
			<div className="flex flex-row w-full justify-between mt-3">
				<SubmitButton
					disabled={isLoading}
					onClick={handleCancelSignup}
					text="Cancel"
					variant="secondary"
				/>
				<SubmitButton
					isLoading={isLoading}
					onClick={handleSignUp}
					text="SignUp"
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loggedInUserData: state.loggedInUserData,
	errorStatus: state.errorStatus,
	currentLoginSection: state.currentLoginSection,
});

export default connect(mapStateToProps, { switchCurrentLoginSection })(
	SignupForm
);
