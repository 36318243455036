import { ForestOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';

const SponsorForestList = (props) => {
	const { loginedInUserData, forests, onForestClick, selectedForest } = props;
	const { firstName } = loginedInUserData?.data?.getLoggedInUserData || {};

	return (
		<div className="animate-fadeIn flex flex-col items-center">
			<span className="text-4xl font-light">
				Welcome{firstName ? `, ${firstName}` : ''}
			</span>
			<div className="flex flex-col w-full py-8">
				{forests?.map((item) => (
					<div
						onClick={() => onForestClick(item)}
						className={`w-full hover:bg-gray-100 hover:opacity-70 border-b-2 hover:text-black p-[10px] cursor-pointer flex justify-between items-center rounded ${
							selectedForest?.id === item.id ? 'bg-[#9aeb2f]' : ''
						}`}
						key={item.id}>
						<div className="flex items-center">
							<span className="ml-2">{`${item.name} - ${item.forestName}`}</span>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps)(SponsorForestList);
