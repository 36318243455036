import { memo } from "react"
import { FieldMapper } from "./FieldMapper"

export const FieldList = memo(({ fields, data, isEdit }) => {
    return (
        fields.map((field) => {
            const { id } = field;
            const value = data[id]?.value;
            const error = data[id]?.error;
            return <FieldMapper key={ field.id } field={ { ...field, value, error } } isEdit={ isEdit } />
        })
    )
})