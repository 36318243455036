import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';
import { showModal } from '../../ReduxStore/Actions/ModalActions';
import { useFormState } from '../../../components/hooks/useFormState';
import FormModal from '../../../components/base/FormModal';
import { formValidation } from '../../../utils/formValidation';
import * as mutations from '../../../graphql/mutations';
import PDFViewer from '../../../components/base/PDFViewer';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const ReportForm = (props) => {
	const { listofinvitationClients } =
		props.loginedInUserData?.data?.getLoggedInUserData || {};
	const { selectedItem } = props.modalStatus || {};

	const {
		formData,
		error,
		setFormData,
		setError,
		reset,
		onChange,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	} = useFormState({}, selectedItem);

	const formFields = [
		{
			id: 'clientID',
			label: 'Client Name',
			type: 'dropdown',
			editable: false,
			items: listofinvitationClients,
			onChange: useCallback(
				(e) => onChange('clientID', e.target.value),
				[onChange]
			),
		},
		{
			id: 'pdfStorageURL',
			label: 'Report',
			type: 'file',
			mimeType: 'application/pdf',
			onChange: useCallback(
				(e) => onChange('pdfStorageURL', e.target.files[0]),
				[onChange]
			),
		},
	];

	const handleCloseModal = () => {
		reset();
		props.showModal({ value: false });
	};

	const handlePDFUpload = async (isEdit) => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;
		setIsLoading(true);
		setError('');
		const { emailId, clientId } =
			props.loginedInUserData?.data?.getLoggedInUserData;
		try {
			let fileName = '';
			const file = formData.pdfStorageURL?.value;

			const data = {
				...reducedData,
			};

			if (file) {
				fileName = `pdf_reports/${file.name}`;
				data.pdfName = file.name;
				data.status = 'Active';
				data.createdBy = emailId;
			}
			if (isEdit) {
				data.id = newState.id.value;
			}
			data.audit_client_msg = `${clientId}@@${emailId}`;

			if (fileName) {
				await Storage.put(fileName, file, {
					contentType: file.type,
				});
				const pdfURL = await Storage.get(fileName);
				if (pdfURL) {
					data.pdfStorageURL = `pdf_reports/${file.name}`;
					await makeGraphQLRequest({
						query: isEdit
							? mutations.updatePDFReports
							: mutations.createPDFReports,
						variables: { input: data },
					});
				}
			}

			setFormStatus(true);
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading(false);
		}
	};

	if (!props.modalStatus.value) return null;

	const getFileName = () => {
		const pdfParts = props.modalStatus.pdfUrl?.split('/');
		const pdfFileName = pdfParts[pdfParts.length - 1];
		return pdfFileName.split('?')[0];
	};
	return (
		<FormModal
			onCancel={handleCloseModal}
			onSubmit={() => handlePDFUpload(!!selectedItem)}
			isLoading={isLoading}
			title={
				props.modalStatus.pdfUrl
					? getFileName()
					: `${selectedItem ? 'Edit' : 'Upload'} Report`
			}
			error={error}
			hideFooter={!!props.modalStatus.pdfUrl}
			hideFields={!!props.modalStatus.pdfUrl}
			formStatus={formStatus}
			isEdit={!!selectedItem}
			formFields={formFields}
			removePadding={!!props.modalStatus.pdfUrl}
			maxWidth={!!props.modalStatus.pdfUrl ? 'xl' : 'md'}
			formData={formData}>
			{!!props.modalStatus.pdfUrl && (
				<PDFViewer url={props.modalStatus.pdfUrl} />
			)}
		</FormModal>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
	modalStatus: state.modalStatus,
});

export default connect(mapStateToProps, { showModal })(ReportForm);
