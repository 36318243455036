import CheckboxField from "../formFields/Checkbox";
import PasswordField from "../formFields/Password";
import TextField from "../formFields/TextField";
import DropdownField from "../formFields/Dropdown";
import FileField from "../formFields/File";
import { Tooltip } from "@digitalreality/ui";
import MultiSelectAuto from "../formFields/MultiSelectAuto";

export const FieldMapper = ({ field, isEdit }) => {
    const { type, onChange, id, label, multiline, value, placeholder, hide, error, helperText, items, onKeyDown, disabled, editable = true, endAdornments = [], mimeType } = field;
    const isDisabled = disabled || isEdit ? !editable : false;
    if (type === 'checkbox') {
        return (
            <CheckboxField
                value={ value }
                onChange={ onChange }
                label={ label } 
                disabled={ isDisabled } />
        );
    }
    if (type === 'password') {
        return (
            <PasswordField
                value={ value }
                onChange={ onChange }
                onKeyDown={ onKeyDown }
                disabled={ isDisabled }
                error={ error } />
        )
    }
    if (type === 'dropdown') {
        return (
            <DropdownField
                id={ id }
                error={ error }
                label={ label }
                onChange={ onChange }
                value={ value }
                items={ items }
                disabled={ isDisabled }
            />
        );
    }
    if (type === 'file') {
        return (
            <FileField
                label={ label }
                onChange={ onChange }
                disabled={ isDisabled }
                mimeType={ mimeType }
                error={ error } />
        );
    }
    if (type === 'multiSelectAuto') {
        return (
            <MultiSelectAuto 
                id={ id }
                error={ error }
                label={ label }
                onChange={ onChange }
                value={ value }
                items={ items }
                placeholder={ placeholder }
                isDisabled={ isDisabled } 
                hide={ hide } />
        );
    }
    return (
        <div className="flex justify-between gap-1 items-center w-full">
            <TextField
                multiline={ multiline }
                value={ value }
                label={ label }
                placeholder={ placeholder }
                onKeyDown={ onKeyDown }
                hide={ hide }
                type={ type }
                error={ error }
                helperText={ helperText }
                onChange={ onChange } 
                disabled={ isDisabled } />
            { endAdornments.map((adornment, index) => (
                <Tooltip
                    key={ index }
                    title={ adornment.title }>
                    <div
                        onClick={ adornment.onClick }
                        className="flex items-center justify-center border rounded-md p-4 cursor-pointer box-border">
                        { adornment.icon }
                    </div>
                </Tooltip>
            ))}
        </div>
    );
}
