import { useMemo } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, PersonAddAltOutlined } from '@mui/icons-material';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as queries from '../../../graphql/queries';
import Grid from '../../CommonComponents/AGGrid/Grid';
import GridLayout from '../../CommonComponents/AGGrid/GridLayout';
import ProfilePic from '../../../components/grid/Profile';
import TextToBool from '../../../components/grid/TextToBool';
import { useGridState } from '../../../components/hooks/useGridState';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const ForestList = (props) => {
	const { assignedRole } =
		props?.loginedInUserData?.data?.getLoggedInUserData;
	const getForestRecords = async () => {
		try {
			let filter_variables = {};

			if (assignedRole !== 'super-admin') {
				filter_variables = {};
			}

			const responseObject = await makeGraphQLRequest({
				query: queries.listForestMainTables,
				variables: filter_variables,
			});
			const items_list =
				responseObject?.data?.listForestMainTables?.items || [];
			onRecords(items_list);
		} catch (error) {
			onError(error);
		}
	};

	const {
		error,
		rowData,
		selectedRows,
		gridRef,
		onSelectionChanged,
		onError,
		onRecords,
		onGridReady,
	} = useGridState({
		onCreateTable: 'onCreateForestMainTable',
		onUpdateTable: 'onUpdateForestMainTable',
		fetchRecords: getForestRecords,
	});

	const actions = [
		{
			id: 'add_forest',
			namedSizeScaleame: 'Add Forest',
			onClick: () => props.showModal({ value: true }),
			icon: <PersonAddAltOutlined />,
			primary: true,
		},
		{
			id: 'edit_forest',
			name: 'Edit Forest',
			onClick: () =>
				props.showModal({ value: true, selectedItem: selectedRows[0] }),
			icon: <EditOutlined />,
			disabled: selectedRows.length !== 1,
		},
	];

	const colDefs = useMemo(
		() => [
			{
				field: 'ForestName',
				headerName: 'Name',
				width: 180,
				checkboxSelection: true,
				headerCheckboxSelection: true,
				pinned: 'left',
				filter: true,
			},
			{
				field: 'icon',
				cellRenderer: ProfilePic,
				width: 140,
			},
			{
				field: 'ForestCountry',
				width: 200,
			},
			{
				field: 'lat',
				width: 180,
			},
			{
				field: 'lon',
				width: 200,
			},
			{
				field: 'noOfHectars',
				width: 150,
			},
			{
				field: 'Status',
				cellRenderer: TextToBool,
				width: 140,
			},
			{
				field: 'createdBy',
				flex: 1,
			},
		],
		[]
	);

	return (
		<GridLayout
			error={error}
			actions={assignedRole === 'super-admin' ? actions : []}
			title="Forests">
			<Grid
				refObject={gridRef}
				rowData={rowData}
				colDefs={colDefs}
				onGridReady={onGridReady}
				onSelectionChanged={onSelectionChanged}
				rowSelection="single"
			/>
		</GridLayout>
	);
};

const mapStateToProps = (state) => ({
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(ForestList);
