import ClientForm from "./ClientForm";
import ClientList from "./ClientList";

const Client = () => {
    return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<ClientForm />
			<ClientList />
		</div>
    );
};

export default Client;
