import AuditList from "./AuditList";



const Audit = () => {
    return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<AuditList/>
		</div>
    );
};

export default Audit;