import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';

import { showModal } from '../../ReduxStore/Actions/ModalActions';
import * as mutations from '../../../graphql/mutations';
import { useFormState } from '../../../components/hooks/useFormState';
import { formValidation } from '../../../utils/formValidation';
import * as queries from '../../../graphql/queries';
import FormModal from '../../../components/base/FormModal';
import { makeGraphQLRequest } from '../../../utils/makeGraphQLRequest';

const formatSelectedItem = (selectedItem) => {
	if (!selectedItem) return selectedItem;
	const newSelectedItem = {
		...selectedItem,
		listOfAccesibleForest:
			selectedItem?.listOfAccesibleForest?.map((forest) => ({
				description: forest,
			})) || [],
	};

	return newSelectedItem;
};

const validatePhoneNumber = (phoneNumber) => {
	const phoneRegex = /^\+?[1-9]\d{9,14}$/;
	if (!phoneRegex.test(Number(phoneNumber))) {
		return 'Invalid phone number';
	}
	return '';
};

function ClientForm(props) {
	const { selectedItem } = props.modalStatus || {};
	const updatedSelectedItem = useMemo(
		() => formatSelectedItem(selectedItem),
		[selectedItem]
	);
	const {
		formData,
		error,
		setFormData,
		setError,
		reset,
		onChange,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	} = useFormState({}, updatedSelectedItem);
	const [forestData, setForestData] = useState([]);

	useEffect(() => {
		const refreshUserObject = async () => {
			const { assignedRole, clientName } =
				props.loginedInUserData?.data?.getLoggedInUserData;
			try {
				const responseObject = await makeGraphQLRequest({
					query: queries.refreshUserData,
					variables: {
						userRole: assignedRole,
						clientName: clientName,
					},
				});
				const { list_forest_data_consolidated } =
					responseObject?.data?.refreshUserData || {};
				setForestData(list_forest_data_consolidated);
			} catch (error) {
				console.log(error);
			}
		};
		if (props.modalStatus.value) {
			refreshUserObject();
		}
	}, [props.modalStatus.value]);

	const formFields = [
		{
			id: 'clientName',
			label: 'Client Name',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('clientName', e.target.value),
				[onChange]
			),
			editable: false,
		},
		{
			id: 'clientContact',
			label: 'Phone Number',
			type: 'string',
			helperText: 'Enter phone number along with country code',
			placeholder: 'eg: +912424242424',
			customValidator: (phoneNumber) => validatePhoneNumber(phoneNumber),
			onChange: useCallback(
				(e) => onChange('clientContact', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clientAddress',
			label: 'Address',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('clientAddress', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clientStreetNumber',
			label: 'Street Number',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('clientStreetNumber', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clientSuitNumber',
			label: 'Suit Number',
			type: 'string',
			skipValidation: true,
			onChange: useCallback(
				(e) => onChange('clientSuitNumber', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clientCity',
			label: 'City',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('clientCity', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clientCountry',
			label: 'Country',
			type: 'string',
			onChange: useCallback(
				(e) => onChange('clientCountry', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clientVAT',
			label: 'Client VAT',
			type: 'string',
			skipValidation: true,
			onChange: useCallback(
				(e) => onChange('clientVAT', e.target.value),
				[onChange]
			),
		},
		{
			id: 'clienttype',
			label: 'Client Type',
			type: 'dropdown',
			items: ['Sponser', 'Visualization', 'Mining'],
			onChange: useCallback(
				(e) => onChange('clienttype', e.target.value),
				[onChange]
			),
			editable: false,
		},
		{
			id: 'listOfAccesibleForest',
			label: 'ForestList',
			type: 'multiSelectAuto',
			hide: formData?.clienttype?.value !== 'Visualization',
			items: forestData.map((forest) => ({
				description: forest.ForestName,
			})),
			onChange: useCallback(
				(newValue) => onChange('listOfAccesibleForest', newValue),
				[onChange]
			),
			editable: false,
		},
		{
			id: 'clientProfilePicURL',
			label: 'Profile Picture',
			type: 'file',
			mimeType: 'image/jpg,image/jpeg,image/png',
			onChange: useCallback(
				(e) => onChange('clientProfilePicURL', e.target.files[0]),
				[onChange]
			),
			skipValidation: true,
		},
	];

	const handleSubmit = async (isEdit) => {
		const { newState, hasError, reducedData } = formValidation(
			formFields,
			formData
		);
		setFormData(newState);
		if (hasError) return;
		setIsLoading(true);
		setError('');
		const { emailId, clientId } =
			props.loginedInUserData?.data?.getLoggedInUserData;
		try {
			let fileName = '';
			const file = formData.clientProfilePicURL?.value;
			if (file) {
				fileName = `client_pictures/${file.name}`;
			}
			const data = {
				...reducedData,
				listOfAccesibleForest:
					reducedData?.listOfAccesibleForest?.length > 0
						? reducedData?.listOfAccesibleForest?.map(
								(forest) => forest.description
							)
						: [],
			};

			if (!isEdit) {
				// data.id = reducedData.clientName;
				data.clientId = reducedData.clientName;
				data.clientSatus = true;
				data.createdBy = emailId;
			} else {
				data.id = newState.id.value;
			}

			if (file?.name) {
				data.clientProfilePicURL = fileName;
			}

			data.audit_client_msg = `${clientId}@@${emailId}`;

			// Validation code start//
			if (!isEdit) {
				const validation_result = await makeGraphQLRequest({
					query: queries.duplicateCheck,
					variables: {
						tableName: 'clientsTable',
						columns: ['clientName', 'clientVAT'],
						enteredvalues: [data.clientName, data.clientVAT],
					},
				});
				if (!validation_result) return;
			}
			// Validation code end//

			const result = await makeGraphQLRequest({
				query: isEdit
					? mutations.updateClientsTable
					: mutations.createClientsTable,
				variables: { input: data },
			});

			if (result && fileName) {
				await Storage.put(fileName, file, {
					contentType: file.type,
				});
			}
			setFormStatus(true);
		} catch (error) {
			onError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleCloseModal = () => {
		reset();
		props.showModal({ value: false });
	};

	if (!props.modalStatus.value) return null;

	return (
		<FormModal
			onCancel={handleCloseModal}
			onSubmit={() => handleSubmit(!!selectedItem)}
			isLoading={isLoading}
			title={`${selectedItem ? 'Update' : 'Add'} Client`}
			error={error}
			formStatus={formStatus}
			formFields={formFields}
			formData={formData}
			isEdit={!!selectedItem}
		/>
	);
}

const mapStateToProps = (state) => ({
	modalStatus: state.modalStatus,
	loginedInUserData: state.loginedInUserData,
});

export default connect(mapStateToProps, { showModal })(ClientForm);
