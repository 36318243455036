import { Text } from "@digitalreality/ui";
import { FieldList } from "./FieldList";
import Modal from "./Modal";
import MaxWidthDialog from "./Dialog";

const MYVRModal = (props) => {
  const {
    isLoading,
    error,
    onCancel,
    title,
    children,
    removePadding,
    maxWidth,
    open,
  } = props;

  return (
    <MaxWidthDialog
      open={open}
      
      onCancel={onCancel}
      loading={isLoading}
      cancelButtonText={"Close"}
      submitButtonDisabled={true}
      onSubmit={() => {}}
      hideFooter={true}
      removePadding={removePadding}
      maxWidth={maxWidth}
      title={title}
    >
      {error && <Text className="text-sm text-red-400 w-full">{error}</Text>}
      {children}
    </MaxWidthDialog>
  );
};

export default MYVRModal;
