export const errorStatus = (state = '', action) => {
	switch (action.type) {
		case 'setError':
			return action.payload;
		case 'clear':
			return '';
		default:
			return state;
	}
};

export const loading_indicator_reducer_status = (state = false, action) => {
	switch (action.type) {
		case 'show_loading_indicator':
			return action.payload;
		case 'clear':
			return false;
		default:
			return state;
	}
};

export const appLoadingIndicator = (state = false, action) => {
	switch (action.type) {
		case 'APP_LOADING_INDICATOR':
			return action.payload;
		default:
			return state;
	}
};

export const landingPage = (state = false, action) => {
	switch (action.type) {
		case 'LANDING_PAGE_VIEWED':
			return true;
		default:
			return state;
	}
};

export const sessionExpired = (state = false, action) => {
	switch (action.type) {
		case 'SESSION_EXPIRED':
			return action.payload;
		default:
			return state;
	}
};
