import MYVRScrollingDiv from './MYVRScrollingDiv';
import ScrollImage from "../../../Images/scroll-img.png";

const MyVRData = ({ displayWeather }) => {
	if (!displayWeather) {
		return null
	}

    return (
		<div className="flex flex-col text-white bg-black rounded-lg w-full h-full overflow-auto">
			<MYVRScrollingDiv>
				<p>
					The La Gamba Field Station is a research and teaching facility of the University of Vienna and is the custodian of 500 hectares of rainforst in the COBIGA corridor.
				</p>
				<img src={ ScrollImage } />
				<p>
					La Gamba biological corridor (COBIGA) is being developed to connect the lowland rainforests of the Golfo Dulce region with the upland rainforests of the Fila Cal, a mountain range north of the "Regenwald der Österreicher". There is currently 500 hectares under management representing 125 Million Green Cubes.
				</p>
			</MYVRScrollingDiv>
		</div>
    );
}

export default MyVRData;
