import ForestAreaForm from "./ForestAreaForm";
import ForestAreaList from "./ForestAreaList";

const ForestArea = () => {
    return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<ForestAreaForm />
			<ForestAreaList />
		</div>
    );
};

export default ForestArea;
