import MediaForm from "./MediaForm";
import MediaList from "./MediaList";

const Media = () => {
    return (
		<div className="flex flex-col grow p-5 gap-5 h-screen">
			<MediaForm />
			<MediaList />
		</div>
    );
};

export default Media;
