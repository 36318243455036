import { useState, useCallback, useEffect } from 'react';
import { formStateGenerator } from '../../utils/formStateGenerator';

export const useFormState = (state = {}, selectedItem) => {
	const [formData, setFormData] = useState(state);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [formStatus, setFormStatus] = useState(false);

	const onChange = useCallback((name, value, dependencies = []) => {
		setError('');
		setFormData((currentState) => {
			const dependencyFields = {};
			dependencies.forEach((dependency) => {
				dependencyFields[dependency] = {
					...currentState[dependency],
					error: '',
				};
			});
			return {
				...currentState,
				[name]: {
					...currentState[name],
					value,
					error: '',
				},
				...dependencyFields,
			};
		});
	}, []);

	const onError = useCallback((error) => {
		let errorMsg = error.message;
		if (!errorMsg) {
			errorMsg = error?.errors?.[0]?.message;
		}
		setError(errorMsg);
		setFormStatus(false);
	}, []);

	const reset = useCallback(() => {
		setFormData({});
		setError('');
		setFormStatus(false);
	}, []);

	useEffect(() => {
		if (selectedItem) {
			setFormData(formStateGenerator(selectedItem));
		}
	}, [selectedItem]);

	return {
		formData,
		setFormData,
		onChange,
		error,
		setError,
		reset,
		isLoading,
		setIsLoading,
		formStatus,
		setFormStatus,
		onError,
	};
};
