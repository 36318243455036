import React, { useState, useEffect, forwardRef } from 'react';
import { generateThumbnail } from './generateThumbnail';
import { LoadingLayout } from '@digitalreality/ui';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const ThumbnailDisplay = forwardRef((props, ref) => {
	const [thumbnailUrl, setThumbnailUrl] = useState(null);
	const [loading, setLoading] = useState(true);
	const { type, icon, id } = props.storageObject;

	console.log(
		'Current selected image????',
		props.storageObject,
		props.currentVideoIndex
	);

	useEffect(() => {
		let isMounted = true; // to prevent state updates on unmounted component

		async function fetchThumbnail() {
			setLoading(true); // Set loading to true at the start of fetching
			try {
				const url = await generateThumbnail(type, icon, 200, 200, 1);
				if (isMounted) {
					setThumbnailUrl(url);

					// Force a slight delay before stopping the loader
					setTimeout(() => {
						setLoading(false);
					}, 1000); // Adjust the delay as needed
				}
			} catch (error) {
				console.error('Error generating thumbnail:', error);
				if (isMounted) setLoading(false); // Stop loading in case of an error
			}
		}
		fetchThumbnail();

		// Cleanup to prevent memory leaks
		return () => {
			isMounted = false;
		};
	}, [icon]);

	return (
		<div
			ref={ref}
			style={{
				display: 'flex',
				width: '150px',
				height: '100px',
				justifyContent: 'center',
				alignItems: 'center',
			}}
			onClick={props.onSelect}>
			{loading ? (
				<LoadingLayout sx={{ height: '100%' }} />
			) : thumbnailUrl ? (
				<img
					src={thumbnailUrl}
					style={{
						objectFit: 'cover',
						width: '100%',
						height: '100%',
					}}
					className={id === props.currentVideoIndex ? 'active' : ''}
					onLoad={() => setLoading(false)}
					onError={() => setLoading(false)}
				/>
			) : (
				<CloseOutlinedIcon sx={{ color: '#ff0000' }} />
			)}
		</div>
	);
});

export default ThumbnailDisplay;
