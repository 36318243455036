import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
	GoogleMap,
	useJsApiLoader,
	Polyline,
	Marker,
} from '@react-google-maps/api';

const mapContainerStyle = {
	width: '383px',
	height: '250px',
};

const roundMarkerIcon = {
	path: 'M 0,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0',
	fillColor: 'red',
	fillOpacity: 1,
	scale: 1,
	strokeColor: 'white',
	strokeWeight: 2,
};

const MYVRMap = ({
	pathCoordinates = [],
	isvideo_Playing = false,
	animation_duration = 30, // Duration in seconds
}) => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyC5YeJhBeopxul68w9jna1iSjJ7rIhTZBM',
	});

	const polygon_coordinates = pathCoordinates[0].map((coord) => ({
		lat: coord[1],
		lng: coord[0],
	}));

	const [positions, setPositions] = useState(
		polygon_coordinates.length > 0
			? polygon_coordinates
			: [
					{ lat: 51.5074, lng: -0.1278 },
					{ lat: 51.5074, lng: -0.12 },
					{ lat: 51.51, lng: -0.12 },
					{ lat: 51.51, lng: -0.13 },
					{ lat: 51.52, lng: -0.13 },
					{ lat: 51.52, lng: -0.12 },
					{ lat: 51.5074, lng: -0.12 },
				]
	);

	const [isvideoPlaying, setisvideoPlaying] = useState(false);
	const center = positions[Math.floor(positions.length / 2)];
	const [markerPosition, setMarkerPosition] = useState(positions[0]);
	const memoizedMarkerPosition = useMemo(
		() => markerPosition,
		[markerPosition]
	);
	const [map, setMap] = useState(null);

	const requestRef = useRef();
	const startTimeRef = useRef(null);
	const segmentDuration = animation_duration / (positions.length - 1);

	const interpolatePosition = (start, end, factor) => ({
		lat: start.lat + (end.lat - start.lat) * factor,
		lng: start.lng + (end.lng - start.lng) * factor,
	});

	const animate = (timestamp) => {
		if (!startTimeRef.current) startTimeRef.current = timestamp;
		const elapsed = (timestamp - startTimeRef.current) / 1000; // Convert to seconds

		const totalProgress = elapsed / animation_duration;
		if (totalProgress >= 1) {
			setMarkerPosition(positions[positions.length - 1]);
			cancelAnimationFrame(requestRef.current);
			return;
		}

		const currentSegment = Math.min(
			Math.floor(totalProgress * (positions.length - 1)),
			positions.length - 2
		);

		const segmentProgress = (elapsed % segmentDuration) / segmentDuration;

		const start = positions[currentSegment];
		const end = positions[currentSegment + 1];

		if (start && end) {
			setMarkerPosition(interpolatePosition(start, end, segmentProgress));
		}

		requestRef.current = requestAnimationFrame(animate);
	};

	useEffect(() => {
		if (isvideoPlaying) {
			startTimeRef.current = null; // Reset the start time
			requestRef.current = requestAnimationFrame(animate);
		} else {
			cancelAnimationFrame(requestRef.current);
		}
		return () => cancelAnimationFrame(requestRef.current);
	}, [isvideoPlaying]);

	useEffect(() => {
		if (map && positions.length > 0) {
			const bounds = new window.google.maps.LatLngBounds();
			positions.forEach((coord) => bounds.extend(coord));
			map.fitBounds(bounds);

			const boundsListener = window.google.maps.event.addListenerOnce(
				map,
				'bounds_changed',
				() => {
					const newZoomLevel = map.getZoom();
					if (newZoomLevel > 16) {
						map.setZoom(16);
					} else {
						map.setZoom(newZoomLevel);
					}
				}
			);

			return () => {
				window.google.maps.event.removeListener(boundsListener);
			};
		}
	}, [map, pathCoordinates]);

	const handlePlayPause = (status) => {
		setisvideoPlaying(status);
		if (status) {
			startTimeRef.current = null; // Reset animation start time
			requestRef.current = requestAnimationFrame(animate);
		} else {
			cancelAnimationFrame(requestRef.current);
		}
	};

	useEffect(() => {
		handlePlayPause(isvideo_Playing);
	}, [isvideo_Playing]);

	if (!isLoaded) return null;

	return (
		<>
			<GoogleMap
				mapContainerStyle={mapContainerStyle}
				center={center}
				options={{
					mapTypeControl: false,
					fullscreenControl: false,
					streetViewControl: false,
					zoomControl: false,
					mapTypeId: 'satellite',
					keyboardShortcuts: false,
					scrollwheel: false,
				}}
				onLoad={(map) => setMap(map)}>
				{positions.length > 1 && (
					<>
						<Polyline
							path={positions}
							options={{
								strokeColor: '#ff0037',
								strokeOpacity: 0.8,
								strokeWeight: 4,
							}}
						/>
						<Marker
							position={memoizedMarkerPosition}
							icon={roundMarkerIcon}
						/>
					</>
				)}
			</GoogleMap>
		</>
	);
};

export default MYVRMap;
