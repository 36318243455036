import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../../components/base/SubmitButton';

const NotFound = () => {
	const navigate = useNavigate();

	const handleGoToLogin = () => {
		navigate('/');
	};

	return (
		<div className="flex flex-col p-5 gap-5 h-screen items-center justify-center bg-gray-100 text-gray-700">
			<h1 className="text-5xl font-bold">404</h1>
			<span className="text-md">
				Oops! The page you're looking for doesn't exist. Click below to
				return to the home page.
			</span>
			<SubmitButton
				onClick={handleGoToLogin}
				text="Home"
			/>
		</div>
	);
};

export default NotFound;
