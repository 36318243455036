import MyVRData from "./MyVRData";
import MyVRWeather from "./MyVRWeather";
import { useState } from "react";
import { IconButton, Tooltip } from "@digitalreality/ui";
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import { HomeIcon } from "@digitalreality/ui-icons";
import LaGamba from "../../../Images/LaGamba.png";

const MyVRWidget = ({ displayWeather, selectedForest, displayJoystick }) => {
    const [ selectedItem, setSelectedItem ] = useState(0);

    const items = [
        {
            id: 'home',
            icon: <HomeIcon />,
            tooltip: 'Home',
            component: <img src={ LaGamba } className="object-cover w-full h-full rounded-lg" />
        },
        {
            id: 'weather',
            icon: <CloudOutlinedIcon />,
            tooltip: 'Live stats',
            component: <MyVRWeather  selectedForest={ selectedForest } />
        },
        {
            id: 'data',
            icon: <ForestOutlinedIcon />,
            tooltip: 'Forest Info',
            component: <MyVRData displayWeather={ displayWeather } />
        },
        {
            id: 'data',
            icon: <PermMediaOutlinedIcon />,
            tooltip: 'Forest Images',
            component: <MyVRData displayWeather={ displayWeather } />
        },
    ];

    const selectedComponet = items[selectedItem].component;

    if(displayJoystick) {
        return null
    }

    if (!displayWeather) {
        return null;
    }
    
    return (
        <div className="flex flex-col w-80 h-72 bg-[#262626] absolute top-20 right-3 rounded-lg p-2 border-white gap-2">
            <div className="flex-1 overflow-hidden">
                { selectedComponet }
            </div>
            <div className="justify-center gap-2 items-center flex bg-black/[.6] rounded-lg">
                { items.map((item, index) => (
                    <IconButton key={ item.id } color={ selectedItem === index ? 'warning' : 'default' } onClick={ () => setSelectedItem(index) }>
                        <Tooltip title={ item.tooltip }>{ item.icon }</Tooltip>
                    </IconButton>
                )) }
            </div>
        </div>
    )
}

export default MyVRWidget;
