import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

function Grid(props) {
	const {
		colDefs,
		rowData,
		onSelectionChanged,
		onGridReady,
		onRowDoubleClicked,
		currentStatus,
		refObject,
		rowSelection = 'multiple',
		suppressRowClickSelection = false,
	} = props;
	const gridRef = useRef();

	const getRowStyle = (params) => {
		return {
			backgroundColor:
				params.node.rowIndex % 2 === 0 ? '#D3D3D3' : '#ffffff', // Correct CSS property names
		};
	};

	return (
		<div
			className={'grid-wrapper ' + 'ag-theme-quartz'}
			style={{ width: '100%', height: '100%' }}>
			<AgGridReact
				ref={refObject} /* This is the reference to the grid */
				columnDefs={colDefs} /* column definations*/
				rowData={rowData} /* Row Data*/
				animateRows={
					true
				} /* This will help us to animate rows on sort and filter */
				style={{ width: '100%' }}
				rowSelection={
					rowSelection
				} /* This will help us to choose between multiple or single*/
				rowMultiSelectWithClick={
					true
				} /* This will persist selected rows when clicking on multiple rows */
				suppressRowClickSelection={suppressRowClickSelection}
				onSelectionChanged={
					onSelectionChanged
				} /* This will be used to get selected rows*/
				// rowStyle={rowStyle}
				getRowStyle={getRowStyle} /* ALternative row colors*/
				onRowDoubleClicked={onRowDoubleClicked}
				pagination={true} /* This will enable and disaible pagination */
				paginationPageSize={
					50
				} /* This will decide page default page size */
				paginationPageSizeSelector={[
					50, 60, 70,
				]} /* this will give options to change page size*/
				onGridReady={
					onGridReady
				} /* This method can be used to get data on grid ready*/
				defaultColDef={{ resizable: true }}
				overlayLoadingTemplate={
					'<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
				}
				overlayNoRowsTemplate={
					'<span className="ag-overlay-loading-center">No data found to display.</span>'
				}
			/>
		</div>
	);
}

Grid.defaultProps = {
	rowData: [],
	colDefs: [],
	onSelectionChanged: () => {},
	onGridReady: () => {},
	onRowDoubleClicked: () => {},
	currentStatus: 'loading',
};

Grid.propTypes = {
	rowData: PropTypes.array,
	colDefs: PropTypes.array,
	onSelectionChanged: PropTypes.func,
	onGridReady: PropTypes.func,
	onRowDoubleClicked: PropTypes.func,
	currentStatus: PropTypes.string,
};

export default Grid;
